<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3"> Book Issue/Return</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-3">
            <!-- <a href="../category/add/" class="content-header-link">Add Book Category</a> -->
        </div>
    </div>
    <div class="col-md-12">
        <div class="panel">
            <div class="panel-header">
                <div class="panel-title">
                    Book Issue/Return
                </div>
            </div>
            <div class="panel-body">
                <form [formGroup]="rObj">
                    <div class="row form-group">
                        <div class="col-md-4">
                            <label>Book Name
                            <small class="req"> *</small></label>   
                            <select2 [data]="allBooksDataOption" formControlName="Book_Name" class="form-select"> </select2>
                            <!-- <select name="class" formControlName="Book_Name" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let book of allBooksData" value="{{book.ID}}">{{book.title}}</option>               
                            </select> -->
                            <div *ngIf="rObj.controls.Book_Name.invalid && (rObj.controls.Book_Name.dirty || rObj.controls.Book_Name.touched)">
                                <p class="input-error-msg">Please select Book Name</p>
                            </div>
                        </div>

                        <div class=" col-md-4">
                            <label>User Type
                            <small class="req"> *</small></label>
                            <select formControlName="User_type" class="form-select" style="margin-bottom: 21px;">
                                <option>Select Type</option>
                                <option value="student">Student</option>
                                <option value="staff">Staff</option>
                            </select>
                        </div>

                        <div class="col-md-4 sibling-search-container">
                            <label>Library Member
                            <small class="req"> *</small></label>
                            <select2 [data]="rObj.controls.User_type.value == 'student' ? memberDataStudentOptions : memberDataStaffOptions" formControlName="Library_member" class="form-select"> </select2>
                            <!-- <select name="class" formControlName="Library_member" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let m of memberData" value="{{m.ID}}">{{m.card_no}}</option>               
                            </select> -->
                            <div *ngIf="rObj.controls.Library_member.invalid && (rObj.controls.Library_member.dirty || rObj.controls.Library_member.touched)">
                                <p class="input-error-msg">Please select Library member</p>
                            </div>
                        </div>
                    </div>

                    <div class="row form-group" *ngIf="rObj.controls.User_type.value == 'staff'" >
                       
                        <div class="col-md-4">
                            <label>Department
                            <small class="req"> *</small></label>
                            <select name="class" formControlName="Department" class="form-control" readonly="">
                                <option value="">Please Select</option>
                                <option *ngFor="let dept of deparmentData" value="{{dept.ID}}">
                                {{dept.name}}
                                </option>         
                            </select>
                            <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                                <p class="input-error-msg">Please select Department</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>Designation
                            <small class="req"> *</small></label>
                            <select name="class" formControlName="Designation" class="form-control" readonly="">
                                <option value="">Please Select</option>
                                <option *ngFor="let des of designationData" value="{{des.ID}}">
                                {{des.name}}
                                </option>         
                            </select>
                            <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                                <p class="input-error-msg">Please select Department</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>Staff Name
                            <small class="req"> *</small></label>
                            <input type="text" formControlName="Staff_name" class="form-control" readonly="">
                            <!-- <select name="class" formControlName="Staff_name" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let staff of StaffSData" value="{{staff.ID}}">
                                {{staff.full_name}}
                                </option>         
                            </select> -->
                            <div *ngIf="rObj.controls.Staff_name.invalid && (rObj.controls.Staff_name.dirty || rObj.controls.Staff_name.touched)">
                                <p class="input-error-msg">Please select Staff name</p>
                            </div>
                        </div>
                    </div>

                    <div class="row form-group" *ngIf="rObj.controls.User_type.value == 'student'">
                        <div class="col-md-4">
                            <label>Class
                            <small class="req"> *</small></label>
                            <select name="class" formControlName="Class" class="form-control" readonly="">
                                <option value="">Please Select</option>
                                <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                            </select>
                            <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                <p class="input-error-msg">Please select class</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>Section
                                <small class="req"> *</small></label>
                                <select name="section" formControlName="Section" class="form-control" readonly="">
                                    <option value="">Please Select</option>
                                    <ng-container *ngFor="let section of classSectionData">
                                        <option [value]="section.id">{{section.name}}</option>
                                    </ng-container>
                                </select>
                                <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                    <p class="input-error-msg">Please select section</p>
                                </div>
                        </div>
                        <div class="col-md-4">
                            <label>Student Name
                            <small class="req"> *</small></label>
                            <input type="text" formControlName="Student_name" class="form-control" readonly="">
                            <!-- <select name="class" formControlName="Student_name" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let s of StudentSData" value="{{s.ID}}">
                                {{s.full_name}}
                                </option>         
                            </select> -->
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-md-6">
                            <label>Book Issue date
                            <small class="req"> *</small></label>
                            <input type="date" formControlName="Issue_date" class="form-control">
                            <div *ngIf="rObj.controls.Issue_date.invalid && (rObj.controls.Issue_date.dirty || rObj.controls.Issue_date.touched)">
                                <p class="input-error-msg">Please select Issue date</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label>Due Date
                            <small class="req"> *</small></label>
                            <input type="date" formControlName="Due_date" (change)="onDateChange()" class="form-control">
                            <div *ngIf="invalidDate">
                                <p class="input-error-msg">Due date must be later than the book issue date.</p>
                            </div>
                        </div>                 
                    </div>
                     
                    <div class=" form-btn-wrapper col-md-2 form-group" style="margin-top: 20px;">
                        <button type="submit" class="btn primary-btn small-btn" [disabled]="!rObj.valid || invalidDate" (click)="search()">Save </button>
                    </div>
                </form>
                
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Book Issue List
                    </div>
                </div>
                <div class="panel-body">
                    <!-- <ng-container *ngIf="issueBookData.length">
                        <div class="action-btn-container" [ngClass]="selectedIds.length ? 'active' : ''">
                            <span class="rows_selected">{{selectedIds.length}} Selected</span>
                            <button data-name="students" class="btn primary-btn extra-small-btn" (click)="delMultiData('students', 'student')">Delete</button>
                        </div>
                    </ng-container> -->
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="issueBookData.length">
                        <thead>
                            <tr>
                                <!-- <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th> -->
                                <th class="align-left">Book Name</th>
                                <th class="align-left">User Type</th>
                                <th class="align-left">Name</th>
                                <th class="align-left">Class/Department</th>
                                <th class="align-left">Section/Designation</th>
                                <th>Issue Date</th>
                                <th>Return Date</th>
                                <th>Status</th>
                                <th>Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let b of issueBookData">
                                <!-- <td>
                                    <input type="checkbox" class="list-checkbox" [checked]="b.isSelected" (change)="onChecked(b.ID, $event)">
                                </td> -->
                                <td class="align-left">{{b.book_title}}</td>
                                <td class="align-left">{{b.user_type}}</td>
                                <td class="align-left">{{b.user_type == 'student' ? b.student_name  : b.staff_name}}</td>
                                <td class="align-left">{{b.user_type == 'student' ? b.class_name    : b.department_name}}</td>
                                <td class="align-left">{{b.user_type == 'student'? b.section_name : b.designation_name}}</td>
                                <td>{{getDate(b.issue_date)}}</td>
                                <td>{{b.return_date ? getDate(b.return_date) : '-'}}</td>
                                <td>{{b.return_status == 0 ? 'Not Return' : 'Returned'}}</td>
                                <td class="action-btn">
                                    <a tooltip="Return" class="edit-btn" (click)="openReturnModal(b.ID)"><i class="fa-solid fa-reply"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table> 
                    <div *ngIf="!issueBookData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>  
                </div>
            </div>
        </div>
    </div> 
</div>