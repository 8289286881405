import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ 
    providedIn: 'root' 
})

export class BehaviourService {
  
  constructor(
    private _http: HttpClient,
  ) { }

  private incident = `${environment.APIURL}/behaviour/incident/`;
  private assignIncident = `${environment.APIURL}/behaviour/assignIncident/`;
  private getAssignedIncident = `${environment.APIURL}/behaviour/getAssignedIncidentsByClassSection/`;
 
  incidentData(data: object): Observable<any> {
    return this._http.post(this.incident, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  assignIncidentData(data: object): Observable<any> {
    return this._http.post(this.assignIncident, data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  getAssignedIncidents(instID: number, academicID: number, classID: any, sectionID: any): Observable<any> {
    return this._http.get(this.getAssignedIncident+'?inst_id='+instID+'&academic_id='+academicID+'&class_id='+classID+'&section_id='+sectionID, {
      headers: { 'Content-Type': 'application/json' }
    })
  }


//   feesGroupData(data: object): Observable<any> {
//     return this._http.post(this.feesGroup, data, {
//       headers: { 'Content-Type': 'application/json' }
//     });
//   }
  
//   feesDiscountData(data: object): Observable<any> {
//     return this._http.post(this.feesDiscount, data, {
//       headers: { 'Content-Type': 'application/json' }
//     });
//   }
  
}