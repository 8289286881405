<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Fees Group</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-3">
            <!--<a href="../../income/add/" class="content-header-link">Add Income</a>-->
        </div>  
    </div>
    
    <div class="row">
        <div class="col-md-4">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                       Add Fees Group
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Name</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Fees_name" >
                            <div *ngIf="rObj.controls.Fees_name.invalid && (rObj.controls.Fees_name.dirty || rObj.controls.Fees_name.touched)">
                                <p class="input-error-msg">Please enter fees name</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Description</label>
                            <textarea type="text" class="form-control" formControlName="Description" ></textarea>
                            <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                                <p class="input-error-msg">Please enter Description</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Due Date</label>
                            <small class="req"> *</small>
                            <input type="date" class="form-control input-date" formControlName="Due_date">
                            <div *ngIf="rObj.controls.Due_date.invalid && (rObj.controls.Due_date.dirty || rObj.controls.Due_date.touched)">
                                <p class="input-error-msg">Please select Due date</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Fine Type</label>
                            <br>
                            <div class="form-check inline" style="margin-right:20px;">
                                <input class="form-check-input" type="radio"  formControlName="Fine_type" value="none" >
                                <label class="form-check-label" style="margin-top:0px;" for="flexCheckDefault">None</label>
                            </div> 
                            <div class="form-check inline">
                                <input class="form-check-input" type="radio" formControlName="Fine_type"  value="percentage">
                                <label class="form-check-label" style="margin-top:0px;" for="flexCheckDefault">Percentage</label>
                            </div> 
                            <div class="form-check inline">
                                <input class="form-check-input" type="radio" formControlName="Fine_type"  value="fix">
                                <label class="form-check-label" style="margin-top:0px;" for="flexCheckDefault">Fix Amount</label>
                            </div>   
                        </div>
                        <div *ngIf="rObj.controls.Fine_type.value != 'none'">
                            <div class="form-group percentage" *ngIf="rObj.controls.Fine_type.value == 'percentage'">
                                <label>Percentage</label>
                                <small class="req percentage"> *</small>
                                <input type="number" class="form-control percentage " formControlName="Percentage" value="" id="percentage">
                            </div>
                            <div class="form-group fineamount" *ngIf="rObj.controls.Fine_type.value == 'fix'" >
                                <label>Fix Amount</label>
                                <small class="req fineamount"> *</small>
                                <input type="number" class="form-control fineamount" formControlName="Fine_amount" value=""  id="fineamount" step="any">
                            </div>
                        </div>
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveData()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Fees Group List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="feesGroupData.length">
                        <thead>
                            <tr>
                                <th class="align-left">FEES GROUP NAME</th>
                                <th class="align-left" style="width: 50px;">DESCRIPTION</th>
                                <th>DUE DATE</th>
                                <th>FINE TYPE</th>
                                <th>FINE AMOUNT</th>
                                <th class="no-sort">MANAGE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let f of feesGroupData">
                                <td class="align-left">{{f.name}}</td>
                                <td><p>{{f.description}}</p></td>
                                <td>{{getDate(f.due_date)}}</td>
                                <td>{{f.fine_type}}</td>
                                <td>{{f.fine_type == 'percentage' ? f.percentage : f.fine_amount }}</td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openFeesGroupEditModal(f.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(f.ID, 'fees_group', 'fees group ')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>   
                    <div *ngIf="!feesGroupData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div> 
                </div>
            </div>
        </div>
    </div>
        
</div>