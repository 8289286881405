import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-student-bulkImport',
  templateUrl: './bulkImport.component.html',
})

export class bulkImportComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;

    public bulkImportData: any[] = [];
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public mediumData: any[] = [];
    fileSrc:any = '';
    status:boolean = false;
  

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: StudentService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
       
    ) {}

    public rObj = this._fBuilder.group({
        Class: ['', Validators.required],
        Section: ['', Validators.required],
        Import_file: ['', Validators.required],
        Medium: [''],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.mediumData = JSON.parse(cRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

       
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.sectionData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })
        


         this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
        this._gService.showLoader(true);
        this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
            // console.log(dataRes)
            if(dataRes.status === true) {
                let result = JSON.parse(dataRes.result)
                // console.log(result)
                let sectionsArray = JSON.parse(result[0].sections)
                let sectionNamesArray = result[0].section_names.split(',')
                this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                    return {'id': section_id, 'name': sectionNamesArray[index]}
                })
                // console.log(this.classSectionData)
            }
        })
        this._gService.showLoader(false);
    })
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.fileSrc = reader.result;          
           }
        }
    }

    importStudent() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                class: rObj.Class,
                section: rObj.Section,
                // medium: rObj.Medium,
                import_file: this.fileSrc
            }
            console.log(mData)
            this._aService.bulkImportData(mData).subscribe(ayRes => {
                console.log(ayRes)
                if (ayRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate(['/student/list/'+rObj.Class+'/'+rObj.Section]);
                    this._gService.triggerSnackbar('Bulk imported  Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}