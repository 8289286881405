<div class="console-content">
        
    <div class="console-content-header row">
        <h1 id="panel_title" class="title col-md-6">Add Member List</h1>
        <div class="link-container col-md-6">
            <!-- <a href="../add/" class="content-header-link">Add New Student</a>
            <a href="../import/" class="content-header-link">Import Students</a> -->
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="filter-options">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>User Type
                            <small class="req"> *</small></label>
                            <select name="class" formControlName="Usertype" class="form-select" >
                                <option value="">Please Select</option>
                                <option value="staff">Staff</option>   
                                <option value="student">Student</option>          
                            </select>
                            <div *ngIf="rObj.controls.Usertype.invalid && (rObj.controls.Usertype.dirty || rObj.controls.Usertype.touched)">
                                <p class="input-error-msg">Please select User Type</p>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="rObj.controls.Usertype.value == 'staff'" >
                            <div class="form-group">
                                <label>Department
                                <small class="req"> *</small></label>
                                <select name="class" formControlName="Department" class="form-select">
                                    <option value="">Please Select</option>
                                    <option *ngFor="let dept of deparmentData" value="{{dept.ID}}">
                                    {{dept.name}}
                                    </option>         
                                </select>
                                <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                                    <p class="input-error-msg">Please select Department</p>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Designation</label>
                                <select name="class" formControlName="Designation" class="form-select" >
                                    <option value="0">Please Select</option>
                                    <option *ngFor="let des of designationData" value="{{des.ID}}">
                                    {{des.name}}
                                    </option>         
                                </select>
                                <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                                    <p class="input-error-msg">Please select Department</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="rObj.controls.Usertype.value == 'student'">
                            <div class="form-group">
                                <label>Class
                                <small class="req"> *</small></label>
                                <select name="class" formControlName="Class" class="form-select" >
                                    <option value="">Please Select</option>
                                    <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                </select>
                                <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                    <p class="input-error-msg">Please select class</p>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Section</label>
                                    <select name="section" formControlName="Section" class="form-select" >
                                        <option value="0">Please Select</option>
                                        <ng-container *ngFor="let section of classSectionData">
                                            <option [value]="section.id">{{section.name}}</option>
                                        </ng-container>
                                    </select>
                                    <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                        <p class="input-error-msg">Please select section</p>
                                    </div>
                            </div>
                        </div>
                        <div class="btn-container">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="gotoSearch()">Search</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-body">
                    <div class="panel-body" *ngIf="rObj.controls.Usertype.value == 'staff' ">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="staffListData.length">
                            <thead>
                                <tr>
                                    <!-- <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th> -->
                                    <th class="align-left">Staff Id</th>
                                    <th class="align-left">Library Card No.</th>
                                    <th class="align-left">Staff Name</th>
                                    <th class="align-left">Mobile</th>
                                    <th class="align-left">Email </th>
                                    <th class="align-left">Department</th>
                                    <th class="align-left">Designation</th>
                                    <th class="no-sort">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                <tr *ngFor="let s of staffListData">
                                    <!-- <td class="align-left">
                                        <input type="checkbox" class="list-checkbox" [checked]="s.isSelected" (change)="onChecked(s.ID, $event)">
                                    </td> -->
                                    <td class="align-left">{{s.staff_id}}</td>
                                    <td class="align-left">
                                        {{getCardNumber(s.ID).length ? getCardNumber(s.ID) : '-'}}
                                    </td>
                                    <td class="align-left">{{s.full_name}}</td>
                                    <td class="align-left">{{s.mobile}}</td>
                                    <td class="align-left">{{s.email}}</td>
                                    <td class="align-left">
                                        <ng-container *ngFor="let d of deparmentData">
                                            <ng-container *ngIf="d.ID == s.department">
                                                {{d.name}}
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td class="align-left">                                       
                                        <ng-container *ngFor="let d of designationData">
                                            <ng-container *ngIf="d.ID == s.designation">
                                                {{d.name}}
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td class="action-btn">
                                        <!-- <ng-container *ngFor="let member of memberData">
                                            <ng-container *ngIf="member.user_type == 'staff' && member.user_id == s.ID" >
                                                <a tooltip="{{member.ID ? 'Surrender Membership' : 'Add as Member'}}" class="surrender-btn" (click)="addMember(s.ID)"><i class="fa-solid {{member.ID ? 'fa-rotate-left' : 'fa-plus'}}"></i></a>
                                            </ng-container>
                                        </ng-container> -->
                                        <ng-container *ngIf="staffMembers.includes(s.ID)">
                                            <a tooltip="Surrender Membership" class="surrender-btn" (click)="deletemember(getMemberId(s.ID),'library_members', 'membership')"><i class="fa-solid fa-rotate-left"></i></a>
                                        </ng-container>
                                        <ng-container *ngIf="!staffMembers.includes(s.ID)">
                                            <a tooltip="Add as Member" class="add-btn" (click)="addMember(s.ID)"><i class="fa-solid fa-plus"></i></a>
                                        </ng-container>
                                    </td>
                                </tr>
                            </tbody>
                        </table>   
                    </div>
                    <div class="panel-body" *ngIf="rObj.controls.Usertype.value == 'student'">

                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="studentListData.length">
                            <thead>
                                <tr>
                                    <!-- <th class="no-sort"><input type="checkbox" id="select_all" (change)="checkUncheckAll($event)"></th> -->
                                     <th class="align-left">Admission/GR number</th>
                                    <th class="align-left">Student Name</th>
                                    <th>Class</th>
                                    <th>Roll Number</th>
                                    <th>Date of Birth</th>
                                    <th>Library Card No.</th>
                                    <th>Blood Group</th>
                                    <th class="no-sort">Action</th>
                                </tr>
                            </thead>



                            <tbody>
                                <tr *ngFor="let s of studentListData">
                                    <!-- <td class="align-left">
                                        <input type="checkbox" class="list-checkbox" [checked]="s.isSelected" (change)="onChecked(s.ID, $event)">
                                    </td> -->
                                     <td class="align-left">{{s.admission_number}}</td>
                                    <td>{{s.full_name}}</td>
                                    <td>{{s.class_name}} ({{s.section_name}})</td>
                                    <td>{{s.roll_no}}</td>
                                    <td>{{s.dob}}</td>
                                    <td>
                                        {{getCardNumber(s.ID).length ? getCardNumber(s.ID) : '-'}}
                                    </td>
                                    <td>{{s.blood_group}}</td>
                                    <td class="action-btn">
                                        <ng-container *ngIf="studentMembers.includes(s.ID)">
                                            <a tooltip="Surrender Membership" class="surrender-btn" (click)="deletemember(getMemberId(s.ID),'library_members', 'membership')"><i class="fa-solid fa-rotate-left"></i></a>                                       
                                        </ng-container>
                                        <ng-container *ngIf="!studentMembers.includes(s.ID)">
                                            <a tooltip="Add as Member" class="add-btn" (click)="addMember(s.ID)"><i class="fa-solid fa-plus"></i></a>
                                        </ng-container>            
                                    </td>
                                </tr>
                            </tbody>
                        </table>   

                    </div>
                    <ng-container *ngIf="rObj.controls.Usertype.value == 'student'">
                        <div *ngIf="!studentListData.length" >
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="rObj.controls.Usertype.value == 'staff'">
                        <div *ngIf="!staffListData.length" >
                                <div class="noDataWrapper">
                                    <img src="/assets/img/No-data-found.png">
                                </div>
                                <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </ng-container>
                </div>            
        
                            
            </div>
        </div>
    </div>
</div>