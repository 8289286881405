import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { Validators, FormBuilder, FormControl, FormArray, FormGroup } from '@angular/forms';
import { ExaminationService } from 'src/app/services/examination.service';
import { StaffService } from 'src/app/services/staff.service';
import { Router,RouteReuseStrategy, ActivatedRoute} from '@angular/router';
import { Subject } from 'rxjs';
import { StudentService } from 'src/app/services/student.service';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-examination-examTimetable',
  templateUrl: './examTimetable.component.html'
})

export class ExamTimeTableComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public examtimetableData: any[] = [];
    public examData: any[] = [];
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public subjectsData: any[] = [];
    public teacherData: any[] = [];
    public teachersData: any[] = [];
    // public designationId: any;
    public mediumData: any[] = [];
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;
    
    public classId : any;
    public sectionId : any;
    public examId : any;
    public mediumId = this.userData.activeMedium;
    public day: any = 1;
    openpanel:boolean = false;
    dtOptions: any = {};

    public etDataArray: any[] = [];
    public etDeleteIds: any[] = [];
    public ids: any[] = [];

    public etPatchDataArray: FormGroup;

    constructor(
        private _gService: GlobalService,
        private _aService: ExaminationService,
        private _staffService: StaffService,
        private _studentService: StudentService,
        private _nService: NotificationService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute
    ) {}

   public rObj = this._fBuilder.group({
        Exam:['',Validators.required],
        Class:['',Validators.required],
        Section:['',Validators.required],
        Medium:[''],
        etData: this._fBuilder.array([]),
    })

        etData(): FormArray {
            return this.rObj.get("etData") as FormArray
        }

        newETData(): FormGroup {
            return this._fBuilder.group({
                Id: '',
                Subject: '', 
                Date : '',
                Start_time : '',
                End_time : '',
                Room_no : '',
                Full_marks : '',
                Passing_marks : ''
            })
        }

        addETData(){
            this.etData().push(this.newETData())
        }
        removeETData(i:number, Id:any) {
            this.etData().removeAt(i);
            this.etDeleteIds.push(Id)
            // alert(this.etDeleteIds)
        }


    ngOnInit(): void {

        this.classId = this._aRoute.snapshot.paramMap.get('class');
        this.sectionId = this._aRoute.snapshot.paramMap.get('section');
        this.examId = this._aRoute.snapshot.paramMap.get('exam')
        if(this.classId && this.sectionId && this.examId) {
            this.openpanel = true
            this._gService.getDataByID(this.classId, 'class').subscribe(dataRes => {  
                console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    console.log(result)
                    let sectionsArray = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    console.log(sectionsArray, sectionNamesArray)

                    this.classSectionData = JSON.parse(result[0].sections)

                    this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                        return {'id': section_id, 'name': sectionNamesArray[index]}
                    })
                    console.log(this.classSectionData)
                }
            })
            this.rObj.patchValue({
                Class : this.classId,
                Section : this.sectionId,
                Exam :this.examId,
            })
            this.gotoSearch()
        }

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.mediumData = JSON.parse(mRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'exam').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.examData = JSON.parse(cRes.result);
                console.log(this.examData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'subjects').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.subjectsData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._staffService.getTeachersData(this.instID, this.activeAcademicID).subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.teacherData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

         this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
        this._gService.showLoader(true);
        this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
            // console.log(dataRes)
            if(dataRes.status === true) {
                let result = JSON.parse(dataRes.result)
                // console.log(result)
                let sectionsArray = JSON.parse(result[0].sections)
                let sectionNamesArray = result[0].section_names.split(',')
                this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                    return {'id': section_id, 'name': sectionNamesArray[index]}
                })
                // console.log(this.classSectionData)
            }
        })
        this._gService.showLoader(false);
    })
    
        this.dtOptions = {
                pagingType: 'numbers',
                order: [],
                columnDefs: [{
                    targets: 'no-sort',
                    orderable: false,
                }],
                dom: 'fBrtip',
                buttons: [
                    'copy', 'excel', 'csv', 'pdf', 'print',
                ],
                language: { search: '', searchPlaceholder: "Search..." },
            };
    }
    gotoSearch() {
        // e.preventDefault(); e : Event
        this.openpanel = true;
        let robj = this.rObj.value;
        // this._router.navigate(['/examination/exam/'+robj.Class+'/'+robj.Section+'/'+robj.Exam]);

        if (robj.Class && robj.Section && robj.Exam) {
            this._aService.timetableByExamClassSectionData(this.instID, this.activeAcademicID, robj.Exam, robj.Class, robj.Section).subscribe(res => {
                console.log(res.status)
                if (res.status === true) {
                    let eData = JSON.parse(res.result)
                    console.log(eData)
                    let etarray = <FormArray>this.rObj.controls.etData;
                    let count = eData.length;
                    eData.forEach((e : any, index : any) => {
                    
                        this.etPatchDataArray = this._fBuilder.group({
                            Id: e.ID,
                            Subject: e.subject, 
                            Date: e.date,
                            Start_time: e.start_time,
                            End_time: e.end_time,
                            Room_no: e.room_no,
                            Full_marks: e.full_marks,
                            Passing_marks: e.passing_marks,
                        })
                        console.log(this.etPatchDataArray)

                        if (this.etPatchDataArray) {
                            this.etData().push(this.etPatchDataArray)
                        }
                        console.log(this.etData())
                    
                    });

                    if (!this.etPatchDataArray) {
                        this.addETData()
                    }

                } else {
                    // this.etData().push([])
                    // this.addETData()
                }
            })
        }
       
    }
    navigate() {
        let robj = this.rObj.value;
        // alert('yes1')
        this._router.navigate(['/examination/exam/'+robj.Class+'/'+robj.Section+'/'+robj.Exam]);
    }

    saveExamtimeTable(){
        let rObj = this.rObj.value;
        let examData = this.rObj.controls.etData.controls;
        for (let i = 0; i < examData.length; i++) {
            this.etDataArray.push(examData[i].value)
        }
        let mData = {
            action: 'save',
            inst_id: this.instID,
            academic_id: this.activeAcademicID,
            exam: this.examId,
            medium: this.mediumId ? this.mediumId : 0,
            class : this.classId, 
            section : this.sectionId,
            examData : this.etDataArray,
            del_ids : this.etDeleteIds
        }
        console.log(mData)
        this._aService.examTimeTableData(mData).subscribe(ayRes => {
            console.log(ayRes)
            if (ayRes.status === true) {
                this._reRoute.shouldReuseRoute = () => false;
                this._router.navigate([this._router.url]);
                this._gService.triggerSnackbar('Exam Time Table Saved Successfully', 'success', true);
                this._studentService.studentListData(this.instID, this.activeAcademicID,this.classId,this.sectionId).subscribe(sRes => {
                    console.log(sRes)
                    if (sRes.status === true) {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url]);
                        let studentListData = JSON.parse(sRes.result)
                        studentListData.forEach(i => {
                            this.ids.push(JSON.parse(i.user_id))
                            console.log((JSON.stringify(this.ids)))
                        })
                        let ndata = {
                            inst_id: this.instID,
                            academic_id: this.activeAcademicID,
                            user_role: '4',
                            user_ids: JSON.stringify(this.ids),
                            title: 'Exam Timetable Added for '+ this.examData.filter(i => i.ID == this.examId).map(i => i.name),
                            msg:  'optional' ,
                            link: '/homework/list',
                            screen: 'Homeworks'
                        }
                        console.log(ndata)
                        this._nService.sendNotificationData(ndata).subscribe(res => {
                            console.log(res)
                            if (res.status === true) {
                                this._reRoute.shouldReuseRoute = () => false;
                                this._router.navigate([this._router.url]);
                                this._gService.triggerSnackbar('Notification send Successfully', 'success', true);
                            }
                        })                                              
                    }
                })

            } else {
                this._gService.triggerSnackbar('Something went wrong', 'error', true);
            }
        }, err => {
            console.log(err);
            this._gService.triggerSnackbar('Server Error!', 'error', true);
        })
        
    }
    
    
}