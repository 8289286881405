import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder, FormControl, FormArray, FormGroup } from '@angular/forms';
import { StaffService } from 'src/app/services/staff.service';
import { AcademicService } from 'src/app/services/academic.service';
  
@Component({
    selector: 'app-assign-teachermodal',
    templateUrl: './assign-teachermodal.component.html',
    styleUrls: ['./assign-teachermodal.component.css'],
})

export class AssignteacherModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public assignTeacherID: number;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public teacherData: any[] = [];
    public assignteacherData: any[] = [];
    public mediumData: any[] = [];

   
    public sectionValue: any[] = [];
    public subjectValue: any[] = [];
    constructor(
        private _gService: GlobalService,
        private _aService: AcademicService,
        private _sService: StaffService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy
    ) {}

    public rObj : any = this._fBuilder.group({
        Class: ['', Validators.required],
        Section: ['',Validators.required],
        Teacher: ['',Validators.required], 
        Medium: ['']

    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.mediumData = JSON.parse(cRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.sectionData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'assign_teacher').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.assignteacherData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })
        this._sService.getTeachersData(this.instID, this.activeAcademicID).subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.teacherData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
                // console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    console.log(result)
                    let sectionsArray = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                        return {'id': section_id, 'name': sectionNamesArray[index]}
                    })
                    console.log(this.classSectionData)
                }
            })
            this._gService.showLoader(false);
        })
       
        this._gService.getDataByID(this.assignTeacherID, 'assign_teacher').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let asData = JSON.parse(dataRes.result)[0];
                this.rObj.patchValue({
                    Medium: asData.medium,
                    Class: asData.class,
                    Section: asData.section,
                    Teacher: asData.class_teacher,
                });

            }
            this._gService.showLoader(false);
        })
    }


    updateAssignTeacher() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let atData = {
                action: 'update',
                id: this.assignTeacherID,
                class: rObj.Class,
                section: rObj.Section,
                teacher: rObj.Teacher,
                medium: rObj.Medium
            }
            this._aService.assingteacherData(atData).subscribe(atRes => {
                console.log(atRes)
                this.modalRef.hide();
                if (atRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Class Teacher Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}