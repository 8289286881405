import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { BehaviourService } from 'src/app/services/behaviour.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-assignincidentmodal',
    templateUrl: './assignincidentmodal.component.html',
    styleUrls: ['./assignincidentmodal.component.css']
})

export class AssignIncidentModal implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public assignID: any;
    public studentID: number;
    public classID: number;
    public sectionID: number;
    public pointsTotal: any;
    public incidentsData: any[] = [];
    public incidentsValue: any[] = [];

    dtOptions: any = {};

    constructor(
        private _gService: GlobalService,
        private _bService: BehaviourService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) { }

    public rObj = this._fBuilder.group({
        Incidents: this._fBuilder.array([], Validators.required),
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this.pointsTotal = this.pointsTotal.length ? this.pointsTotal.map(x => parseInt(x))[0] : 0;
        console.log(this.pointsTotal)

        if (this.assignID.length) {
            this._gService.getDataByID(this.assignID, 'assign_incident').subscribe(dataRes => {
                console.log(dataRes);
                if (dataRes.status === true) {
                    let incData = JSON.parse(dataRes.result)[0];
                    console.log(incData)
                    this.incidentsValue = JSON.parse(incData.incidents)
                    console.log(this.incidentsValue)
                }
                this._gService.showLoader(false);
            })
        }

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'incidents').subscribe(incRes => {
            console.log(incRes)
            if (incRes.status === true) {
                this.incidentsData = JSON.parse(incRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.showLoader(false);
    }

    stringToArray(data: any) {
        return JSON.parse(data);
    }

    onIncidentSelect(e: any) {
        const selectedIncidents = <FormArray>this.rObj.controls.Incidents;

        selectedIncidents?.markAllAsTouched();

        let selectedPoint = e.target.dataset.point

        if (e.target.checked) {
            this.incidentsValue.push(e.target.value);
            console.log(this.incidentsValue)
            this.pointsTotal = parseInt(this.pointsTotal) + parseInt(selectedPoint) 
        } else {
            let index = this.incidentsValue.findIndex(x => x == e.target.value)
            this.incidentsValue.splice(index, 1);
            this.pointsTotal = parseInt(this.pointsTotal) - parseInt(selectedPoint) 
        }
        console.log(this.pointsTotal)
    }



    saveUpdateAssignedIncident() {
        // if (this.incidentsValue.length) {
            if (this.assignID.length) {
                let incData = {
                    action: 'update',
                    id: this.assignID.map(x => parseInt(x))[0],
                    incidents: JSON.stringify(this.incidentsValue),
                    total_points: this.pointsTotal
                }
                console.log(incData)
                this._bService.assignIncidentData(incData).subscribe(incRes => {
                    console.log(incRes)
                    this.modalRef.hide();
                    if (incRes.status === true) {
                        this.modalRef.onHidden?.subscribe(() => {
                            this._reRoute.shouldReuseRoute = () => false;
                            this._router.navigate([this._router.url]);
                            this._gService.triggerSnackbar('Incident updated Successfully', 'success', true);
                        });
                    } else {
                        this._gService.triggerSnackbar('Something went wrong', 'error', true);
                    }
                }, err => {
                    console.log(err);
                    this._gService.triggerSnackbar('Server Error!', 'error', true);
                })
            } else {
                let incData = {
                    action: 'save',
                    inst_id: this.instID,
                    academic_id: this.activeAcademicID,
                    student_id: this.studentID,
                    class_id: this.classID,
                    section_id: this.sectionID,
                    incidents: JSON.stringify(this.incidentsValue),
                    total_points: this.pointsTotal,
                    assigned_by: this.userID
                }
                console.log(incData)
                this._bService.assignIncidentData(incData).subscribe(incRes => {
                    console.log(incRes)
                    this.modalRef.hide();
                    if (incRes.status === true) {
                        this.modalRef.onHidden?.subscribe(() => {
                            this._reRoute.shouldReuseRoute = () => false;
                            this._router.navigate([this._router.url]);
                            this._gService.triggerSnackbar('Incident Saved Successfully', 'success', true);
                        });
                    } else {
                        this._gService.triggerSnackbar('Something went wrong', 'error', true);
                    }
                }, err => {
                    console.log(err);
                    this._gService.triggerSnackbar('Server Error!', 'error', true);
                })
            }

        // }
    }

}