<div class="modal-header">
    <h5 class="modal-title">Assign Incident</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <ul>
                <li class="incident-list" *ngFor="let incident of incidentsData.slice().reverse(); let i = index"
                    formArrayName="Incidents">
                    <h6 class="incident-title">{{incident.title}} <span>Point: {{incident.is_negative == 1 ? '-' +
                            incident.point : incident.point }} <input type="checkbox" [attr.data-point]="incident.is_negative == 1 ? '-' +
                            incident.point : incident.point" [value]="incident.ID"
                                [checked]="incidentsValue.includes(incident.ID)"
                                (change)="onIncidentSelect($event)"></span></h6>
                    <p>{{incident.description}}</p>
                </li>
            </ul>
        </form>
    </div>
</div>
<div class="modal-footer">
    <div class="modal-btn-wrapper">
        <!-- <button class="btn primary-btn small-btn" [disabled]="!rObj.valid || !rObj.dirty" (click)="saveAssignedIncident()">Save</button> -->
        <button class="btn primary-btn small-btn" [disabled]="!assignID.length && !incidentsValue.length" (click)="saveUpdateAssignedIncident()">{{assignID.length ? 'Update' : 'Save'}}</button>
    </div>
</div>