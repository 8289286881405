import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StaffService } from 'src/app/services/staff.service';
import { AssignteacherModalComponent } from 'src/app/components/modal/assign-teachermodal/assign-teachermodal.component';

@Component({
    selector: 'app-academic-assign-teacher',
    templateUrl: './assign-teacher.component.html',
    styles: [
        '.section-list label {width: 100%; margin: 5px 0 !important; cursor: pointer;}',
        '.section-list label input {margin-right: 5px;}',
        '.td-section-list {margin-bottom: 5px;}'
    ]
})

export class assignTeacherComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;
    public medium = this.userData.activeMedium;

    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public teachersAssignDataArray: any[] = [];
    public teacherData: any[] = [];
    public teacherDataDD: any[] = [];
    public assignteacherData: any[] = [];
    public teacherAssignData: any[] = [];
    public mediumData: any[] = [];


    public classDataSelect: any[] = [];
    public assignSubData: any[] = [];
    public AssignClassSubjIds: any[] = [];
    public AssignClassSectionSubjIds: any[] = [];
    public AssignteacherSubjIds: any[] = [];

    public class_array: any[] = [];
    public section_array: any[] = [];
    public _array: any[] = [];


    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: AcademicService,
        private _sService: StaffService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) { }

    public rObj = this._fBuilder.group({
        Class: ['', Validators.required],
        Section: ['', Validators.required],
        Teacher: ['', Validators.required],
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.mediumData = JSON.parse(cRes.result);
            }
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.sectionData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })

        // this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'assign_teacher').subscribe(sRes => {
        //     console.log(sRes)
        //     if (sRes.status === true) {
        //         this.assignteacherData = JSON.parse(sRes.result);
        //         this.teachersAssignDataArray = JSON.parse(sRes.result);
        //         console.log(this.assignteacherData)
        //     }
        //     this._gService.showLoader(false);
        // })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
                this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'assign_teacher').subscribe(sRes => {
                    if (sRes.status === true) {
                        let teachersData = JSON.parse(sRes.result)
                        this._sService.getTeachersData(this.instID, this.activeAcademicID).subscribe(sRes => {
                            if (sRes.status === true) {
                                let teacherData = JSON.parse(sRes.result);
                                teachersData.forEach(i => {
                                    this.AssignteacherSubjIds.push(i.class_teacher)
                                })
                                this.teacherDataDD = teacherData.filter(c => !this.AssignteacherSubjIds.includes(c.ID))
                                this.teacherData = teacherData;
                                this.assignteacherData = teachersData;
                                this.teachersAssignDataArray = teachersData;
                                this.AssignClassSubjIds = teachersData.map(i => i.class)
                                this.classDataSelect = this.classData.filter(c => JSON.parse(c.sections).length != this.getOccurrence(this.AssignClassSubjIds, c.ID))
                            }
                        })
                    }
                    this._gService.showLoader(false);
                })
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        console.log(this.classDataSelect)
        // this._sService.getTeachersData(this.instID, this.activeAcademicID).subscribe(sRes => {
        //     console.log(sRes)
        //     if (sRes.status === true) {
        //         let teacherData = JSON.parse(sRes.result);
        //         this.assignteacherData.forEach(i => {
        //             this.AssignteacherSubjIds.push(i.class_teacher)
        //         })
        //         console.log(this.AssignteacherSubjIds)
        //         console.log(teacherData)
        //         this.teacherDataDD = teacherData.filter(c => !this.AssignteacherSubjIds.includes(c.ID))
        //         this.teacherData = teacherData;
        //         console.log(this.teacherData)
        //     }
        //     this._gService.showLoader(false);
        // })

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {
                if (dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    console.log(result)
                    this.AssignClassSectionSubjIds = this.assignteacherData.map(s => s.section)
                    let teacherAssignData = this.teachersAssignDataArray
                    console.log(this.AssignClassSectionSubjIds)
                    this.AssignClassSectionSubjIds = teacherAssignData.reduce((res: any, item: any) => {
                        const key = item.class
                        console.log(key)
                        console.log(item)
                        console.log(res)
                        if (!res[key]) {
                            res[key] = []
                        }
                        res[key].push(item.section)
                        return res
                    }, {})
                    console.log(this.AssignClassSectionSubjIds)

                    let sectionsArray = JSON.parse(result[0].sections)
                    console.log(sectionsArray)
                    let sectionNamesArray = sectionsArray.map((s, index) => {
                        return { 'id': s, 'name': result[0].section_names.split(',')[index] }
                    })
                    console.log(sectionNamesArray)
                    let arrayByClassId: any = Object.keys(this.AssignClassSectionSubjIds).filter(key => {
                        return key == value
                    }
                    ).map(sec => {
                        return this.AssignClassSectionSubjIds[sec]
                    })
                    console.log(arrayByClassId)

                    if (arrayByClassId.length) {
                        this.classSectionData = sectionsArray.filter((s: any) => {
                            return !JSON.parse(JSON.stringify(arrayByClassId[0])).includes(s)
                        }).map((section_id: any, index: any) => {
                            let i = sectionNamesArray.findIndex(sm => sm.id == section_id);
                            return { 'id': section_id, 'name': sectionNamesArray[i]['name'] }
                        })
                    } else {
                        this.classSectionData = sectionsArray.map((section_id: any, index: any) => {
                            let i = sectionNamesArray.findIndex(sm => sm.id == section_id);
                            return { 'id': section_id, 'name': sectionNamesArray[i]['name'] }
                        })
                    }
                    console.log(this.classSectionData)

                }
            })
            this._gService.showLoader(false);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    getOccurrence(array: any, value: any) {
        return array.filter((v) => (v == value)).length;
    }

    getSectionName(sectionID: number) {
        this._gService.getDataByID(sectionID, 'section').subscribe(sRes => {
            if (sRes.status === true) {
                let sData = JSON.parse(sRes.result)[0];
                return sData
            }
        })
    }

    openAssignTeacherEditModal(assignTeacherID: number) {
        this.modalRef = this.modalService.show(AssignteacherModalComponent, {
            class: 'modal-dialog-centered assign_teacher-edit-modal-dialog form-modal-dialog',
            backdrop: 'static',
            keyboard: false,
            initialState: {
                assignTeacherID: assignTeacherID
            }
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    saveAssignTeacher() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let atData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                medium: this.isVernacular == 1 ? this.medium : 0,
                class: rObj.Class,
                section: rObj.Section,
                teacher: rObj.Teacher,
            }
            console.log(atData)
            this._aService.assingteacherData(atData).subscribe(atRes => {
                console.log(atRes)
                if (atRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Class Teacher Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}