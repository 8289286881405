import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { BehaviourService } from 'src/app/services/behaviour.service';
import { Router,RouteReuseStrategy, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AssignIncidentModal } from 'src/app/components/modal/assignIncidentmodal/assignIncidentmodal.component';

@Component({
  selector: 'app-behaviour-assignIncident',
  templateUrl: './assignIncidents.component.html'
})

export class AssignIncidentsComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public studentListData: any[] = [];
    public incidentsData: any[] = [];
    public selectedIds : any[] = [];
    public mediumData: any[] = [];

    public isVernacular = this.userData.isVernacular;
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public classId : any;
    public sectionId : any;
    dtOptions: any = {};
    modalRef: BsModalRef;
    
    constructor(
        private _gService: GlobalService,
        private _sService: StudentService,
        private _bService: BehaviourService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
        private _aRoute: ActivatedRoute
    ) {}

    public rObj = this._fBuilder.group({
        Class: ['', Validators.required], 
        Section: [0],
        Medium: [0],
    })

    ngOnInit(): void {

        this.classId = this._aRoute.snapshot.paramMap.get('class');
        this.sectionId = this._aRoute.snapshot.paramMap.get('section');

        if(this.classId && this.sectionId){
            this._gService.getDataByID(this.classId, 'class').subscribe(dataRes => {  
                console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    let sectionsArray = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                        return {'id': section_id, 'name': sectionNamesArray[index]}
                    })
                    // this.classSectionData = JSON.parse(result[0].sections)
                }
            })
            this.rObj.patchValue({
                Class : this.classId,
                Section : this.sectionId,
                // Medium : this.mediumId,
            })
            this.search()
        }

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.mediumData = JSON.parse(mRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
        this._gService.showLoader(true);
        this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
            // console.log(dataRes)
            if(dataRes.status === true) {
                let result = JSON.parse(dataRes.result)
                // console.log(result)
                let sectionsArray = JSON.parse(result[0].sections)
                let sectionNamesArray = result[0].section_names.split(',')
                this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                    return {'id': section_id, 'name': sectionNamesArray[index]}
                })
                // console.log(this.classSectionData)
            }
        })
        this._gService.showLoader(false);
    })
        
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
    }

    checkUncheckAll(evt: Event) {
        this.selectedIds.splice(0, this.selectedIds.length);
        this.studentListData.forEach((s) => {
            s.isSelected = (<HTMLInputElement>evt.target).checked
            if((<HTMLInputElement>evt.target).checked) {
                this.selectedIds.push(s.ID);
            } else {
                let index = this.selectedIds.indexOf(s.ID);
                this.selectedIds.splice(index,1);
            }
        })
        console.log(this.selectedIds)
    }

    openIncidentAssignModal(studentID: number, total_points: any ) {
        let assignID = this.incidentsData.filter(inc => inc.student_id == studentID && inc.class_id == this.classId && inc.section_id == this.sectionId).map(inc => inc.ID)
        this.modalRef = this.modalService.show(AssignIncidentModal, {
            class: 'modal-dialog-centered section-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                assignID: assignID.length ? assignID : 0,
                studentID: studentID,
                classID: this.classId,
                sectionID: this.sectionId,
                pointsTotal: total_points.length ? total_points : 0
            } 
        });
    }


    onChecked(rowId : number, e: Event) {
        if((<HTMLInputElement>e.target).checked) {
            this.selectedIds.push(rowId);
        } else {
            let index = this.selectedIds.indexOf(rowId);
            this.selectedIds.splice(index,1);
        }
        console.log(this.selectedIds)
        if (this.studentListData.length != this.selectedIds.length) {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = false
        } else {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = true
        }
    }

    delMultiData(moduleName: string, moduleTitle: string) {
        this._gService.openDeleteMultiModal(this.selectedIds, this.selectedIds.length, moduleName, moduleTitle)
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    getTotalPoints(studentID: number) {
        // console.log(studentID,examID)
        // console.log(this.StudentListData.filter(s => s.student_id == studentID && s.exam_id == examID).map(s => s.marks))   
        return this.incidentsData.filter(inc => inc.student_id == studentID).map(inc => inc.total_points)
    }

    gotoSearch() {
        let robj = this.rObj.value;
        this._reRoute.shouldReuseRoute = () => false;
        this._router.navigate(['/behaviour/assign-incident/'+robj.Class+'/'+robj.Section]);
    }

    search() {

        if(this.rObj.valid){
            let robj = this.rObj.value;
            // let sData = {
            //     action: 'save',
            //     inst_id: this.instID,
            //     academic_id: this.activeAcademicID,
            //     class: robj.Class,
            //     section: robj.Section,
            //     medium: robj.Medium
            // }
            // console.log(sData)
            this._sService.studentListData(this.instID, this.activeAcademicID, this.rObj.value.Class, this.rObj.value.Section).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
                    this.studentListData = JSON.parse(sRes.result)
                    console.log(this.studentListData)
                    this._bService.getAssignedIncidents(this.instID, this.activeAcademicID, this.rObj.value.Class, this.rObj.value.Section).subscribe(iRes => {
                        console.log(iRes)
                        if (iRes.status === true) {
                           this.incidentsData = JSON.parse(iRes.result)
                           console.log(this.incidentsData) 
                        }
                    })
                } else {
                    this.studentListData = [];
                    this._gService.triggerSnackbar('No Incidents Found!', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
       
    }
}