import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { BehaviourService } from 'src/app/services/behaviour.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Title } from 'chart.js';
import { EditIncidentModal } from 'src/app/components/modal/editIncidentmodal/editIncidentmodal.component';

@Component({
    selector: 'app-behaviour-incident',
    templateUrl: './incidents.component.html',
    styles: [
        '.section-list label {width: 100%; margin: 5px 0 !important; cursor: pointer;}', 
        '.section-list label input {margin-right: 5px;}',
        '.td-section-list {margin-bottom: 5px;}'
    ]
})

export class IncidentsComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public incidentsData: any[] = [];

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _bService: BehaviourService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Title: ['', Validators.required], 
        Description: ['', Validators.required],
        Points: ['', Validators.required],
        isNegative: [false],
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'incidents').subscribe(incRes => {
            console.log(incRes)
            if (incRes.status === true) {
                this.incidentsData = JSON.parse(incRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    stringToArray(data: any) {
        return JSON.parse(data);
    }

    openIncidentEditModal(incidentID: number) {
        this.modalRef = this.modalService.show(EditIncidentModal, {
            class: 'modal-dialog-centered section-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                incidentID: incidentID,
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }


    saveIncident() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let incData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                title: rObj.Title,
                description: rObj.Description,
                point: rObj.Points,
                is_negative: rObj.isNegative === false ? 0 : 1,
            }
            console.log(incData)
            this._bService.incidentData(incData).subscribe(incRes => {
                console.log(incRes)
                if (incRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Incident Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}