import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy, ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, CheckboxControlValueAccessor } from '@angular/forms';
import { PayrollService } from 'src/app/services/payroll.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FeesService } from 'src/app/services/fees.service';
import { FeesTypemodalComponent } from 'src/app/components/modal/feesTypemodal/feesTypemodal.component';
import { PayModalComponent } from 'src/app/components/modal/paymodal/paymodal.component';

@Component({
  selector: 'app-fees-dueFee',
  templateUrl: './dueFee.component.html',
})

export class DueFeeComponent implements OnInit {
// stringToArray(arg0: any): any {
// throw new Error('Method not implemented.');
// }
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;

    public duefeeData: any[] = [];
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public feesTypeData: any[] = [];
    public mediumData: any[] = [];

    public classId : any;
    public sectionId : any;
    public feesType : any;

    isChecked: boolean = true;
    dtOptions: any = {};
    viewId :any;

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: FeesService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
        private _aRoute: ActivatedRoute
    ) {}

    public rObj = this._fBuilder.group({
        Class: ['', Validators.required],
        Section: [0],
        Medium: [0],
        Fees_type: ['', Validators.required],
    })

    ngOnInit(): void {

        this.classId = this._aRoute.snapshot.paramMap.get('class');
        this.sectionId = this._aRoute.snapshot.paramMap.get('section');
        this.feesType = this._aRoute.snapshot.paramMap.get('type');

        if(this.classId && this.sectionId){
            this._gService.getDataByID(this.classId, 'class').subscribe(dataRes => {  
                console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    let sectionsArray = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                        return {'id': section_id, 'name': sectionNamesArray[index]}
                    })
                    // this.classSectionData = JSON.parse(result[0].sections)
                }
            })
            this.rObj.patchValue({
                Class : this.classId,
                Section : this.sectionId,
                // Medium : this.mediumId,
                Fees_type: this.feesType
            })
            this.search()
        }


        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.classData = JSON.parse(pRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.sectionData = JSON.parse(pRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'fees_type').subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.feesTypeData = JSON.parse(pRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.mediumData = JSON.parse(pRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
        this._gService.showLoader(true);
        this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
            // console.log(dataRes)
            if(dataRes.status === true) {
                let result = JSON.parse(dataRes.result)
                // console.log(result)
                let sectionsArray = JSON.parse(result[0].sections)
                let sectionNamesArray = result[0].section_names.split(',')
                this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                    return {'id': section_id, 'name': sectionNamesArray[index]}
                })
                // console.log(this.classSectionData)
            }
        })
        this._gService.showLoader(false);
    })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    gotoPayLink(dataID: number){
        this._router.navigate(['/fees/fees-collection/collectFee/'+dataID])
    }
    

    openPayModal(fmIds: number, totalAmount: any, amount: any, fine_type: any, fine_amount: any,) {
        this.modalRef = this.modalService.show(PayModalComponent, {
            class: 'modal-dialog-centered pay-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                fmIds: fmIds,
                totalAmount : totalAmount,
                studentId : this.viewId,
                amount : amount,
                fine_type : fine_type,
                fine_amount :fine_amount,
            } 
        });
    }
    totalAmount(amt : any, f_amt :any){  
        return parseInt(amt)+parseInt(f_amt);
    }


    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    gotoSearch() {
        let robj = this.rObj.value;
        this._reRoute.shouldReuseRoute = () => false;
        this._router.navigate(['/fees/due-fees/list/'+robj.Fees_type+'/'+robj.Class+'/'+robj.Section]);
    }

    search() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            // let mData = {
            //     action: 'save',
            //     inst_id: this.instID,
            //     academic_id: this.activeAcademicID,
            //     class: rObj.Class,
            //     section: rObj.Section,
            //     fees_type: rObj.Fees_type,
            // }
            // console.log(mData)
            console.log(rObj.Fees_type,rObj.Class,rObj.Section)
            this._aService.getDueFeesData(this.instID,this.activeAcademicID,rObj.Fees_type,rObj.Class,rObj.Section,rObj.Medium).subscribe(phRes => {
                console.log(phRes)
                if (phRes.status === true) {
                    this.duefeeData = JSON.parse(phRes.result)
                    // console.log(this.duefeeData)
                    // this._reRoute.shouldReuseRoute = () => false;
                    // this._router.navigate([this._router.url]);
                    console.log(this.duefeeData)
                } else {
                    this.duefeeData = [];
                    this._gService.triggerSnackbar('No Due Fees Found!', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}