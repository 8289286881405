<div class="modal-header">
    <h5 class="modal-title">Approve Leave</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            
            <div class="form-group">
                <label>Role</label>
                <small class="req"> *</small>
                <!-- <input type="text" formControlName="Role" class="form-control" > -->
                <select formControlName="Role" class="form-control" >
                    <option value="">Please Select</option>
                    <option *ngFor="let type of userRoleData" value="{{type.ID}}">{{type.name}}</option>                           
                </select>
                  <div *ngIf="rObj.controls.Role.invalid && (rObj.controls.Role.dirty || rObj.controls.Role.touched)">
                    <p class="input-error-msg">Please select Role</p>
                </div>
            </div>
            <div class="form-group">
                <label>Name</label>
                <small class="req"> *</small>
                <select formControlName="Name" class="form-control" >
                    <option value="">Please Select</option>
                    <option *ngFor="let type of staffListData" value="{{type.staff_id}}">{{type.full_name}}</option>                           
                </select>
                <!-- <input type="text" formControlName="Name" class="form-control" > -->
                <!-- <select formControlName="Name" class="form-control" >
                    <option value="">Please Select</option>
                    <option *ngFor="let n of staffListData" value="{{n.ID}}">{{n.full_name}}</option>  
                </select> -->
                  <div *ngIf="rObj.controls.Name.invalid && (rObj.controls.Name.dirty || rObj.controls.Name.touched)">
                    <p class="input-error-msg">Please select Name</p>
                </div>
            </div>
            
            <div class="form-group">
                <label>Apply Date</label>
                <small class="req"> *</small>
                <input type="date" class="form-control date-input" formControlName="Apply_date">
                <div *ngIf="rObj.controls.Apply_date.invalid && (rObj.controls.Apply_date.dirty || rObj.controls.Apply_date.touched)">
                    <p class="input-error-msg">Please select Apply_date</p>
                </div>
            </div>

            <div class="form-group">
                <label>Leave Type</label>
                <small class="req"> *</small>
                <!-- <input type="text" class="form-control date-input" formControlName="Leave_type" > -->
                <select class="form-control" formControlName="Leave_type" >
                    <option value="">Select Leave Type</option>
                    <option *ngFor="let type of leaveData" value="{{type.ID}}">{{type.name}}</option>                           
                 </select>
                 <div *ngIf="rObj.controls.Leave_type.invalid && (rObj.controls.Leave_type.dirty || rObj.controls.Leave_type.touched)">
                    <p class="input-error-msg">Please select Leave_type</p>
                </div>
                </div>
            <div class="form-group">
                <label>Leave From Date</label>
                <small class="req"> *</small>
                <input type="date" class="form-control date-input" formControlName="Leave_from_date" >
                <div *ngIf="rObj.controls.Leave_from_date.invalid && (rObj.controls.Leave_from_date.dirty || rObj.controls.Leave_from_date.touched)">
                    <p class="input-error-msg">Please select leave_from_date</p>
                </div>
            </div>
            <div class="form-group">
                <label>Leave To Date</label>
                <small class="req"> *</small>
                <input type="date" class="form-control date-input" formControlName="Leave_to_date" >
                <div *ngIf="rObj.controls.Leave_to_date.invalid && (rObj.controls.Leave_to_date.dirty || rObj.controls.Leave_to_date.touched)">
                    <p class="input-error-msg">Please select leave_to_date</p>
                </div>
            </div> 
            <div class="form-group">
                <label>Reason</label>
                <small class="req"> *</small>
                <textarea class="form-control" formControlName="Reason" value="" rows="3"></textarea>
                <div *ngIf="rObj.controls.Reason.invalid && (rObj.controls.Reason.dirty || rObj.controls.Reason.touched)">
                    <p class="input-error-msg">Please enter reason</p>
                </div>
            </div>
            <div class="form-group">
                <label>Note</label>
                <textarea class="form-control" formControlName="Note" value="" rows="3"></textarea>
                <div *ngIf="rObj.controls.Note.invalid && (rObj.controls.Note.dirty || rObj.controls.Note.touched)">
                    <p class="input-error-msg">Please enter note</p>
                </div>
            </div>
            <div class="form-group">
                <label>Attach Document</label>
                <a href="{{imageSrc}}" target="_blank" style="display: block; margin-bottom: 5px;" *ngIf="imageSrc">View Document</a>
                <!-- <input type="file" class="form-control" formControlName="Leave_document"> -->
                <div class="imgUpload-wrapper">
                    <div class="removeImgOverlay" (click)="removeImg()" *ngIf="imageSrc">Remove Document</div>  
                    <input type="file" formControlName="Leave_document" class="form-control" (change)="onFileChange($event)">
                </div>
                <!-- <div *ngIf="rObj.controls.Leave_document.invalid && (rObj.controls.Leave_document.dirty || rObj.controls.Leave_document.touched)">
                    <p class="input-error-msg">Please enter document</p>
                </div> -->
            </div>
            <div class="form-group">
                <label>Status</label>
                <small class="req"> *</small>
                <div class="radio required">
                    <label class="radio-inline" style="margin-right: 18px;">
                        <input class="form-check-input" type="radio" formControlName="Status" value="1"> Approve
                    </label>
                    <label class="radio-inline">
                        <input class="form-check-input" type="radio" formControlName="Status" value="0"> Reject
                    </label>

                    <div *ngIf="rObj.controls.Status.invalid && (rObj.controls.Status.dirty || rObj.controls.Status.touched)">
                        <p class="input-error-msg">Please select status</p>
                    </div>
                </div>   
            </div>
            <div class="modal-btn-wrapper">
                <button class="btn primary-btn submit-btn" [disabled]="!rObj.valid"  (click)="action == 'add' ? saveLeave() : approve()">{{action == 'add' ? 'Save' : 'Update'}}</button>
            </div>
        </form> 
    </div>
</div>
