<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3"> Inventory Issue</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-3">
            <a href="..//store-management/inventory-issue/add/" class="content-header-link">Inventory Purchase</a>
        </div>

    </div>
    
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                   Inventory Issue
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="row form-group" style="margin-bottom: 18px;">
                            <div class="col-md-2">
                                <label>User Type<small class="req"> *</small></label>
                                <select formControlName="User_type" class="form-select" data-next="getSection" data-type="list" required="">
                                    <option value="student">Student</option>
                                    <option value="staff">Staff</option>
                                </select>    
                                <div *ngIf="rObj.controls.User_type.invalid && (rObj.controls.User_type.dirty || rObj.controls.User_type.touched)">
                                    <p class="input-error-msg">Please select user type</p>
                                </div>           
                            </div>

                            <div class="row col-md-10" *ngIf="rObj.controls.User_type.value == 'staff'" >
                                <div class="col-md-4" >
                                    <label>Department
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Department" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let dept of deparmentData" value="{{dept.ID}}">
                                        {{dept.name}}
                                        </option>         
                                    </select>
                                    <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                                        <p class="input-error-msg">Please select Department</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>Designation
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Designation" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let des of designationData" value="{{des.ID}}">
                                        {{des.name}}
                                        </option>         
                                    </select>
                                    <div *ngIf="rObj.controls.Department.invalid && (rObj.controls.Department.dirty || rObj.controls.Department.touched)">
                                        <p class="input-error-msg">Please select Department</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>Issue To</label>
                                    <small class="req"> *</small>
                                        <select name="class" formControlName="Issue_to" class="form-select">
                                            <option value="">Please Select</option>
                                            <option *ngFor="let s of staffListData" value="{{s.ID}}">
                                                {{s.full_name}}
                                            </option>         
                                        </select>
                                        <div *ngIf="rObj.controls.Issue_to.invalid && (rObj.controls.Issue_to.dirty || rObj.controls.Issue_to.touched)">
                                            <p class="input-error-msg">Please enter issue to</p>
                                        </div>
                                </div>
                            </div>

                            <div class="row col-md-10" *ngIf="rObj.controls.User_type.value == 'student'">
                                <div class="col-md-4">
                                    <label>Class
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Class" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                        <p class="input-error-msg">Please select class</p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>Section
                                        <small class="req"> *</small></label>
                                        <select name="section" formControlName="Section" class="form-select" >
                                            <option value="">Please Select</option>
                                            <ng-container *ngFor="let section of classSectionData" >
                                                <ng-container *ngFor="let s of sectionData">
                                                    <ng-container *ngIf="s.ID == section">
                                                        <option [value]="section">{{s.name}}</option>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </select>
                                        <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                            <p class="input-error-msg">Please select section</p>
                                        </div>
                                </div>
                                <div class="col-md-4">
                                    <label>Issue To</label>
                                    <small class="req"> *</small>
                                        <select name="class" formControlName="Issue_to" class="form-select" >
                                            <option value="">Please Select</option>
                                            <option *ngFor="let s of studentListData" value="{{s.ID}}">
                                            {{s.full_name}}
                                            </option>         
                                        </select>
                                        <div *ngIf="rObj.controls.Issue_to.invalid && (rObj.controls.Issue_to.dirty || rObj.controls.Issue_to.touched)">
                                            <p class="input-error-msg">Please enter issue to</p>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="form-group" *ngIf="rObj.controls.User_type.value == 'staff'">
                           
                        </div> -->
                        <!-- <div class="form-group" *ngIf="rObj.controls.User_type.value == 'student'">
                            
                        </div> -->
                        <div class="row form-group" style="margin-bottom: 18px;">
                            <div class="col-md-4">
                                <label>Issue By</label> 
                                <small class="req"> *</small>
                                <!-- <input type="text" formControlName="Issue_by" class="form-control number-input" autocomplete="off" value="" required=""> -->
                                <select formControlName="Issue_by" class="form-select" >
                                    <option value="">Please Select</option>
                                    <option *ngFor="let staff of staffData" value="{{staff.ID}}">
                                    {{staff.full_name}}
                                    </option>         
                                </select>
                                <div *ngIf="rObj.controls.Issue_by.invalid && (rObj.controls.Issue_by.dirty || rObj.controls.Issue_by.touched)">
                                    <p class="input-error-msg">Please enter issue by</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Issue Date</label>
                                <small class="req"> *</small>
                                <input type="date" formControlName="Date" class="form-control date-input" required="">
                                <div *ngIf="rObj.controls.Date.invalid && (rObj.controls.Date.dirty || rObj.controls.Date.touched)">
                                    <p class="input-error-msg">Please enter issue date</p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Return Date </label>
                                <small class="req"> *</small>
                                <input type="date" formControlName="Return_date" (change)="onDateChange()" class="form-control date-input" required="">
                                <div *ngIf="invalidDate">
                                    <p class="input-error-msg">Return date must be later than the issue date.</p>
                                </div>
                            </div> 
                        </div>
                        <div class="row form-group" style="margin-bottom: 18px;">
                            <div class="col-md-4">
                                <label>Item Name</label>
                                    <small class="req"> *</small>
                                    <select formControlName="Item_name" class="form-control" data-next="getSection" data-type="list" required="">
                                    <option value="">Select</option>
                                    <option *ngFor="let i of inventoryItemData" value="{{i.ID}}">
                                        {{i.item_name}}
                                        </option>     
                                    </select>
                                    <div *ngIf="rObj.controls.Item_name.invalid && (rObj.controls.Item_name.dirty || rObj.controls.Item_name.touched)">
                                        <p class="input-error-msg">Please enter iten name</p>
                                    </div>
                            </div>
                            <div class="col-md-4">
                                <label>Quantity </label>
                                <small class="req"> *</small>
                                <input type="text" formControlName="Qty" class="form-control" required="">
                                <div *ngIf="rObj.controls.Qty.invalid && (rObj.controls.Qty.dirty || rObj.controls.Qty.touched)">
                                    <p class="input-error-msg">Please enter qty</p>
                                </div>
                                
                            </div>
                            <div class="col-md-4">
                                <label>Note</label>
                                <textarea formControlName="Note" class="form-control" required=""></textarea>
                                <div *ngIf="rObj.controls.Note.invalid && (rObj.controls.Note.dirty || rObj.controls.Note.touched)">
                                    <p class="input-error-msg">Please enter note</p>
                                </div>
                            </div>
                        </div>
                        
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid || invalidDate" (click)="saveIssue()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Inventory Issue List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="inventoryIssueData.length">
                        <thead>
                            <tr>
                                <th class="align-left">User Type</th>
                                <th class="align-left">Issue to</th>
                                <th class="align-left">Issue by</th>
                                <th>Issue Date</th>
                                <th>Return Date</th>
                                <th class="align-left">Item Name</th>
                                <th>Quantity</th>
                                <!-- <th>Item Category</th> -->
                                <th>Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let p of inventoryIssueData">
                                <td class="align-left">{{p.user_type}}</td>
                                <td class="align-left">
                                    <ng-container *ngIf="p.user_type == 'staff'">
                                        <ng-container *ngFor="let s of staffData">
                                            <ng-container *ngIf="s.ID == p.issue_to">
                                                {{s.full_name}}
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="p.user_type == 'student'">
                                        <ng-container *ngFor="let s of studentData">
                                            <ng-container *ngIf="s.ID == p.issue_to">
                                                {{s.full_name}}
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td class="align-left">
                                    <ng-container *ngFor="let s of staffData">
                                        <ng-container *ngIf="s.ID == p.issue_by">
                                            {{s.full_name}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td>{{getDate(p.date)}}</td>
                                <td>{{getDate(p.return_date)}}</td>
                                <td class="align-left">
                                    <ng-container *ngFor="let s of inventoryItemData">
                                        <ng-container *ngIf="s.ID == p.item_id">
                                            {{s.item_name}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td>{{p.qty}}</td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openIssueEditModal(p.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(p.ID, 'inventory_issue', 'inventory issue')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>   
                    <div *ngIf="!inventoryIssueData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>   
                </div>
            </div>
        </div>
    </div>
        
</div>