import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder, FormControl, FormArray, FormGroup } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { BehaviourService } from 'src/app/services/behaviour.service';
import { Title } from 'chart.js';

@Component({
    selector: 'app-editincidentmodal',
    templateUrl: './editincidentmodal.component.html',
    styleUrls: [],
})

export class EditIncidentModal {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public incidentID: number;

    constructor(
        private _gService: GlobalService,
        private _bService: BehaviourService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy
    ) { }

    public rObj: any = this._fBuilder.group({
        Title: ['', Validators.required],
        Description: ['', Validators.required],
        Points: ['', Validators.required],
        isNegative: [false],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getDataByID(this.incidentID, 'incidents').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let incData = JSON.parse(dataRes.result)[0];
                console.log(incData)
                this.rObj.patchValue({
                    Title: incData.title,
                    Description: incData.description,
                    Points: incData.point,
                    isNegative: incData.is_negative == 1 ? true : false
                });
            }
            this._gService.showLoader(false);
        })
    }

    updateIncident() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let incData = {
                action: 'update',
                id: this.incidentID,
                title: rObj.Title,
                description: rObj.Description,
                point: rObj.Points,
                is_negative: rObj.isNegative === false ? 0 : 1,
            }
            console.log(incData)
            this._bService.incidentData(incData).subscribe(incRes => {
                console.log(incRes)
                this.modalRef.hide();
                if (incRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url]);
                        this._gService.triggerSnackbar('Incident updated Successfully', 'success', true);
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}