import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { Router,RouteReuseStrategy , ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-students-promoteStudent',
  templateUrl: './promoteStudent.component.html'
})

export class PromoteStudentComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public academicYearsData: any[] = [];
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public class2SectionData: any[] = [];   
    public studentListData: any[] = [];
    public studentByName: any[] = [];
    public classId : any;
    public sectionId : any;
    public mediumId : any;
    public selectedIds : any[] = [];
    public studentInfoArray: any[] = []
    public mediumData: any[] = [];
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    imageSrc: any;
    public imgURL = this._gService.getImgPath();

    dtOptions: any = {};

    constructor(
        private _gService: GlobalService,
        private _aService: StudentService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute,
    ) {}

    public rObj = this._fBuilder.group({
        Class: ['', Validators.required], 
        // Medium : ['',Validators.required],
        Section: ['', Validators.required],
        // currentStatus : ['pass'],
        // nextStatus : ['continue']


    })

    public sObj = this._fBuilder.group({
        Academic_year : ['',Validators.required],
        Class2 : ['',Validators.required],
        Section2 : ['',Validators.required],
        studentInfo : this._fBuilder.array([])
    })

    ngOnInit(): void {

        this.classId = this._aRoute.snapshot.paramMap.get('classId');
        this.sectionId = this._aRoute.snapshot.paramMap.get('sectionId');
        // this.mediumId = this._aRoute.snapshot.paramMap.get('mediumId');

        if(this.classId && this.sectionId ){
            this._gService.getDataByID(this.classId, 'class').subscribe(dataRes => {  
                console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    this.classSectionData = JSON.parse(result[0].sections)
                }
            })
            this.rObj.patchValue({
                Class : this.classId,
                Section : this.sectionId,  
                // Medium : this.mediumId,  
            })
            this.search()
        }

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.mediumData = JSON.parse(cRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAcademicYearsDataByInstID(this.instID).subscribe(ayRes => {
            console.log(ayRes);
            if (ayRes.status === true) {
                this.academicYearsData = JSON.parse(ayRes.result);
                console.log(this.academicYearsData)
            } else {
                console.log(ayRes.msg);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })


         this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
        this._gService.showLoader(true);
        this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
            // console.log(dataRes)
            if(dataRes.status === true) {
                let result = JSON.parse(dataRes.result)
                // console.log(result)
                let sectionsArray = JSON.parse(result[0].sections)
                let sectionNamesArray = result[0].section_names.split(',')
                this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                    return {'id': section_id, 'name': sectionNamesArray[index]}
                })
                // console.log(this.classSectionData)
            }
        })
        this._gService.showLoader(false);
    })

        this.sObj.controls['Class2'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
                console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    let sectionsArray = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    this.class2SectionData = sectionsArray.map((section_id : any, index : any) => {
                        return {'id': section_id, 'name': sectionNamesArray[index]}
                    }) 
                }
            })
            this._gService.showLoader(false);
        })

       
        
        // this.dtOptions = {
        //     pagingType: 'numbers',
        //     order: [],
        //     columnDefs: [{
        //         targets: 'no-sort',
        //         orderable: false,
        //     }],
        //     dom: 'fBrtip',
        //     buttons: [
        //         'copy', 'excel', 'csv', 'pdf', 'print',
        //     ],
        //     language: { search: '', searchPlaceholder: "Search..." },
        //     // select : { 
        //     //     selector : 'td:first-child input[type="checkbox"]'
        //     // }
            
        // };

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'ftip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
    }

    checkUncheckAll(evt: Event) {
        this.selectedIds.splice(0, this.selectedIds.length);
        this.studentListData.forEach((s) => {
            s.isSelected = (<HTMLInputElement>evt.target).checked
            if((<HTMLInputElement>evt.target).checked) {
                this.selectedIds.push(s.ID);
            } else {
                let index = this.selectedIds.indexOf(s.ID);
                this.selectedIds.splice(index,1);
            }
        })
        console.log(this.selectedIds)
    }

    onChecked(rowId : number, e: Event) {
        if((<HTMLInputElement>e.target).checked) {
            this.selectedIds.push(rowId);
        } else {
            let index = this.selectedIds.indexOf(rowId);
            this.selectedIds.splice(index,1);
        }
        console.log(this.selectedIds)
        if (this.studentListData.length != this.selectedIds.length) {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = false
        } else {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = true
        }
    }

    delMultiData(moduleName: string, moduleTitle: string) {
        this._gService.openDeleteMultiModal(this.selectedIds, this.selectedIds.length, moduleName, moduleTitle)
    }

    gotoViewLink(dataID: number){
        this._router.navigate(['/student/view/'+dataID])
    }

    gotoEditLink(dataID: number){
        this._router.navigate(['/student/edit/'+dataID])
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    navigate() {
        if(this.rObj.valid) {
            // let rObj = this.rObj.value;
            // alert('yes1')
            this._router.navigate(['/student/promote/add/'+this.rObj.value.Class+'/'+this.rObj.value.Section]);
        }
    }

    search() {

        if(this.rObj.valid){
            let robj = this.rObj.value;
          
            this._aService.getStudentToPromote(this.instID, this.activeAcademicID, robj.Class, robj.Section).subscribe(cRes => {
                console.log(cRes)
                if (cRes.status === true) {
                // this._router.navigate(['/student/promote/add/'+this.rObj.value.Class+'/'+this.rObj.value.Section])
                    this.studentListData = JSON.parse(cRes.result);
                    let studentInfoArray = <FormArray> this.sObj.controls.studentInfo;
                    studentInfoArray.controls = this.studentListData.map(s => this._fBuilder.group({
                        studentID: [s.ID],
                        // currentResult: [s.current_result],
                        // sessionStatus: [s.session_status]
                        currentResult: ['pass'],
                        sessionStatus: ['continue']
                    }))

                    console.log(studentInfoArray.controls)
                    console.log(this.studentListData)
                } else {
                    if (cRes.status === false && cRes.msg == 'promoted') {
                        this._gService.triggerSnackbar('Students Already Promoted', 'success', true);
                        this.studentListData = [];
                    } else {
                        this._gService.triggerSnackbar('No Data Found!', 'error', true);
                    }
                }
            })
            // this._gService.triggerSnackbar('Class Subject Updated Successfully', 'success', true) 
        
           
        }
       
    }
    promote(){
        let sobj = this.sObj.value;
        let sObj = this.sObj.controls.studentInfo.controls;
        for (let i = 0; i < sObj.length; i++) {
         this.studentInfoArray.push(sObj[i].value)
       }
        let pData = {
            inst_id : this.instID,
            academic_id : this.activeAcademicID,
            class_id : this.classId,
            section_id : this.sectionId,
            next_session_id : sobj.Academic_year,
            next_class_id : sobj.Class2,
            next_section_id : sobj.Section2,
            students : this.studentInfoArray
        }
        console.log(pData)
        this._aService.promoteStudentData(pData).subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this._reRoute.shouldReuseRoute = () => false;
                this._router.navigate([this._router.url]);
                this._gService.triggerSnackbar('Students Promoted Successfully', 'success', true);            
            } else {
                this._gService.triggerSnackbar('Something went wrong', 'error', true);
            }
        }, err => {
            console.log(err);
            this._gService.triggerSnackbar('Server Error!', 'error', true);
        })
       
    }
    
}