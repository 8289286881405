<div class="console-content">
    <form [formGroup]="rObj">
        <div class="console-content-header row">
            <h1 class="title col-md-6">Exam Result </h1>
            <!-- <div class="header-msg col-md-4">
            </div> -->
            <div class="link-container col-md-6">
                <!-- <a href="./examination/exam-schedule/list" class="content-header-link"> Mark Register</a> -->
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="panel">
                    <div class="filter-options">
                            <!-- <div class="row"> -->
                                <div class="form-group">
                                    <label>Exam
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Exam" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let exam of examData" value="{{exam.ID}}">{{exam.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Exam.invalid && (rObj.controls.Exam.dirty || rObj.controls.Exam.touched)">
                                        <p class="input-error-msg">Please select exam </p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Class
                                    <small class="req"> *</small></label>
                                    <select name="class" formControlName="Class" class="form-select" >
                                        <option value="">Please Select</option>
                                        <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                                    </select>
                                    <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                        <p class="input-error-msg">Please select class</p>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Section
                                        <small class="req"> *</small></label>
                                        <select name="section" formControlName="Section" class="form-select" >
                                            <option value="">Please Select</option>
                                            <ng-container *ngFor="let section of classSectionData">
                                                <option [value]="section.id">{{section.name}}</option>
                                            </ng-container>
                                        </select>
                                        <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                            <p class="input-error-msg">Please select section</p>
                                        </div>
                                </div> 
                            <!-- </div>  -->
                            <div class="form-group btn-container" style="margin-top: 22px;">
                                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="gotoSearch()">Proceed</button>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-body">
                        <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="StudentListData.length">
                            <thead>
                                <tr>
                                    <th>Admission No</th>
                                    <th>Roll No</th>
                                    <th class="align-left">Student Name</th>
                                    <th  *ngFor="let e of ExaminationData">{{e.subject_name}} ({{e.passing_marks}}/{{e.full_marks}})</th>
                                    <th>Grand Total </th>
                                    <th>Percentage(%)</th>
                                    <th>Rank</th>
                                    <th class="no-sort">Result</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- <ng-container *ngFor="let e of ExaminationData"> -->
                                <tr *ngFor="let s of StudentListData | unique: 'student_id'" >
                                     <td class="align-left">{{s.admission_number}}</td>
                                    <td>{{s.roll_no}}</td>
                                    <td>{{s.full_name}}</td>
                                    <ng-container *ngFor="let e of ExaminationData">
                                        <td>{{getMarks(s.student_id, e.ID)}}</td>
                                        <!-- <td *ngIf="StudentListData[0].exam_id == e.ID">{{s.marks}}-{{e.ID}}</td> -->
                                    </ng-container>
                                    <td>{{getTotalMarks(s.student_id)}}/{{totalFullMarks}}</td>
                                    <td>{{getPercentage(getTotalMarks(s.student_id))}}% </td>   
                                    <td>{{getRank(getPercentage(getTotalMarks(s.student_id)), s.student_id)}} </td>
                                    <td class="action-btn">
                                        <span [ngClass]="getResults(s.student_id) ? 'result-btn-pass' : 'result-btn-fail'">{{getResults(s.student_id) ? 'Passed' : 'Failed' }}</span>
                                    </td>
                                </tr>
                                <!-- </ng-container> -->
                            </tbody>
                        </table>   
                        <div *ngIf="!StudentListData.length">
                            <div class="noDataWrapper">
                                <img src="/assets/img/No-data-found.png">
                            </div>
                            <p style="text-align: center;">Data Not Found</p>
                        </div>
                    </div>            
                                
                </div>
            </div>
        </div>
    </form>
</div>