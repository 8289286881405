import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { Validators, FormBuilder, FormControl, FormArray, FormGroup } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { StaffService } from 'src/app/services/staff.service';
import { Router,RouteReuseStrategy, ActivatedRoute} from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-academic-createTimeTable',
  templateUrl: './createTimeTable.component.html'
})

export class CreateTimeTableComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public createtimetableData: any[] = [];

    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public subjectsData: any[] = [];
    public teacherData: any[] = [];
    public teachersData: any[] = [];
    public mediumData: any[] = [];

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;

    public classId : any;
    public sectionId : any;
    public mediumId = this.userData.activeMedium;
    public day: any = 1;
    openpanel:boolean = false;
    dtOptions: any = {};

    public mondayTab: boolean = true;
    public tuesdayTab: boolean = false;
    public wednesdayTab: boolean = false;
    public thursdayTab: boolean = false;
    public fridayTab: boolean = false;
    public saturdayTab: boolean = false;
    public sundayTab: boolean = false;

    public ttDataArray: any[] = [];
    public ttDeleteIds: any[] = [];

    public ttMondayPatchDataArray: FormGroup;
    public ttTuesdayPatchDataArray: FormGroup;
    public ttWednesdayPatchDataArray: FormGroup;
    public ttThurdayPatchDataArray: FormGroup;
    public ttFridayPatchDataArray: FormGroup;
    public ttSaturdayPatchDataArray: FormGroup;
    public ttSundayPatchDataArray: FormGroup;

    public weekDays : any[] = [{id : 1, day : 'Monday'}, {id : 2, day : 'Tuesday'}, {id : 3, day : 'Wednesday'}, {id : 4, day : 'Thursday'}, {id : 5, day : 'Friday'}, {id : 6, day : 'Saturday'}, {id : 7, day : 'Sunday'}]

    constructor(
        private _gService: GlobalService,
        private _aService: AcademicService,
        private _sService: StaffService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute
    ) {}

   public rObj = this._fBuilder.group({
        Class:['',Validators.required],
        Section:['',Validators.required],
        Medium:[''],
        ttDataMonday: this._fBuilder.array([]),
        ttDataTuesday: this._fBuilder.array([]),
        ttDataWednesday: this._fBuilder.array([]),
        ttDataThursday: this._fBuilder.array([]),
        ttDataFriday: this._fBuilder.array([]),
        ttDataSaturday: this._fBuilder.array([]),
        ttDataSunday: this._fBuilder.array([]),
    })

        ttData(dayName: string): FormArray {
            return this.rObj.get("ttData"+dayName) as FormArray
        }

        newTTData(): FormGroup {
            return this._fBuilder.group({
                Id: '',
                Subject: '', 
                Teacher : '',
                Time_from : moment().format("HH:mm"),
                Time_to : moment().format("HH:mm"),
                Room_no : ''
            })
        }

        addTTData(dayName: any) {
            this.ttData(dayName).push(this.newTTData())
            console.log(this.ttData(dayName))
        }

        removeTTData(dayName: string, i: number, Id: any) {
            this.ttData(dayName).removeAt(i);
            this.ttDeleteIds.push(Id)
            // this.ttData(dayName).controls.value[i]
            // alert(this.ttDeleteIds)
        }

        getWeekDay(day: any) {
            return this.weekDays.filter(d => d.id == day).map(d => d.day)
        }

        arrayToString(array: any) {
            return JSON.stringify(array)
        }


    ngOnInit(): void {

        // console.log(this.ttData('Tuesday').controls[0].value)

        this.classId = this._aRoute.snapshot.paramMap.get('class');
        this.sectionId = this._aRoute.snapshot.paramMap.get('section');
        // this.mediumId = this._aRoute.snapshot.paramMap.get('medium');
       console.log(this.classId,this.sectionId)
        if (this.classId && this.sectionId) {
            // this.addTTData('Monday')
            // this.addTTData('Tuesday')
            // this.addTTData('Wednesday')
            // this.addTTData('Thursday')
            // this.addTTData('Friday')
            // this.addTTData('Saturday')
            // this.addTTData('Sunday')
            // if (!this.ttData().controls.length) {
                // this.addTTData('Monday')
            // }
            this.openpanel = true
            this._gService.getDataByID(this.classId, 'class').subscribe(dataRes => {  
                console.log(dataRes)
                if (dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    this.classSectionData = JSON.parse(result[0].sections)
                }
            })
            this.rObj.patchValue({
                Class : this.classId,
                Section : this.sectionId,
                Medium : this.mediumId
            }) 
            this.gotoSearch()
        }


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.mediumData = JSON.parse(cRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'subjects').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.subjectsData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._sService.getTeachersData(this.instID, this.activeAcademicID).subscribe(sRes => {
            console.log(sRes)
            if (sRes.status === true) {
                this.teacherData = JSON.parse(sRes.result);
            }
            this._gService.showLoader(false);
        })
        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  

                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    this.classSectionData = JSON.parse(result[0].sections)

                }
            })
            this._gService.showLoader(false);
        })
        
        this.dtOptions = {
                pagingType: 'numbers',
                order: [],
                columnDefs: [{
                    targets: 'no-sort',
                    orderable: false,
                }],
                dom: 'fBrtip',
                buttons: [
                    'copy', 'excel', 'csv', 'pdf', 'print',
                ],
                language: { search: '', searchPlaceholder: "Search..." },
            };
    }
    gotoSearch() {
        // this.addTTData;
        // e.preventDefault();
        this.openpanel = true;
        // alert(this.openpanel)
        let robj = this.rObj.value;
        // this._reRoute.shouldReuseRoute = () => false;
        // this._router.navigate(['/academic/createTimeTable/'+robj.Class+'/'+robj.Section]);
       
        if (robj.Class && robj.Section) {
            console.log(this.instID)
            console.log(this.activeAcademicID)
            console.log(robj.Class)
            console.log(robj.Section)
            this._aService.timetableByClassData(this.instID, this.activeAcademicID, robj.Class, robj.Section, this.isVernacular == 1 ? robj.Medium : 0).subscribe(res => {
            console.log(res)
            if (res.status === true) {
                let tData = JSON.parse(res.result)
                console.log(tData)
                let tDataMonday = tData.filter((t: any) => t.day == 1)
                console.log(tDataMonday)
                tDataMonday.forEach((e: any, index : any) => {
                    this.ttMondayPatchDataArray = this._fBuilder.group({
                        Id: e.ID,
                        Subject : e.subject,
                        Teacher : e.teacher,
                        Time_from : e.time_from,
                        Time_to : e.time_to,
                        Room_no : e.room_no
                    })
                    console.log(this.ttMondayPatchDataArray)

                    if (this.ttMondayPatchDataArray) {
                        this.ttData('Monday').push(this.ttMondayPatchDataArray)
                    }
                    console.log(this.ttData('Monday'))
                });

                if (!this.ttMondayPatchDataArray) {
                    this.addTTData('Monday')
                }

                let tDataTuesday = tData.filter((t: any) => t.day == 2)
                console.log(tDataTuesday)
                tDataTuesday.forEach((e: any, index : any) => {
                    this.ttTuesdayPatchDataArray = this._fBuilder.group({
                        Id: e.ID,
                        Subject : e.subject,
                        Teacher : e.teacher,
                        Time_from : e.time_from,
                        Time_to : e.time_to,
                        Room_no : e.room_no
                    })
                    console.log(this.ttData('Tuesday').length)

                    if(this.ttTuesdayPatchDataArray) {
                        this.ttData('Tuesday').push(this.ttTuesdayPatchDataArray)
                    }
                    console.log(this.ttData('Tuesday'))
                })

                if (!this.ttTuesdayPatchDataArray) {
                    this.addTTData('Tuesday')
                }


                let tDataWednesday = tData.filter((t: any) => t.day == 3)
                tDataWednesday.forEach((e: any, index : any) => {
                    this.ttWednesdayPatchDataArray = this._fBuilder.group({
                        Id: e.ID,
                        Subject : e.subject,
                        Teacher : e.teacher,
                        Time_from : e.time_from,
                        Time_to : e.time_to,
                        Room_no : e.room_no
                    })
                    console.log(this.ttData('Wednesday').length)

                    if(this.ttWednesdayPatchDataArray) {
                        this.ttData('Wednesday').push(this.ttWednesdayPatchDataArray)
                    }
                    console.log(this.ttData('Wednesday'))
                })
                if (!this.ttWednesdayPatchDataArray) {
                    this.addTTData('Wednesday')
                }

                let tDataThursday = tData.filter((t: any) => t.day == 4)
                tDataThursday.forEach((e: any, index : any) => {
                    this.ttThurdayPatchDataArray = this._fBuilder.group({
                        Id: e.ID,
                        Subject : e.subject,
                        Teacher : e.teacher,
                        Time_from : e.time_from,
                        Time_to : e.time_to,
                        Room_no : e.room_no
                    })
                    console.log(this.ttData('Thursday').length)

                    if(this.ttThurdayPatchDataArray) {
                        this.ttData('Thursday').push(this.ttThurdayPatchDataArray)
                    }
                    console.log(this.ttData('Thursday'))
                })
                if (!this.ttThurdayPatchDataArray) {
                    this.addTTData('Thursday')
                }

                let tDataFriday = tData.filter((t: any) => t.day == 5)
                tDataFriday.forEach((e: any, index : any) => {
                    this.ttFridayPatchDataArray = this._fBuilder.group({
                        Id: e.ID,
                        Subject : e.subject,
                        Teacher : e.teacher,
                        Time_from : e.time_from,
                        Time_to : e.time_to,
                        Room_no : e.room_no
                    })
                    console.log(this.ttData('Friday').length)

                    if(this.ttFridayPatchDataArray) {
                        this.ttData('Friday').push(this.ttFridayPatchDataArray)
                    }
                    console.log(this.ttData('Friday'))
                })
                if (!this.ttFridayPatchDataArray) {
                    this.addTTData('Friday')
                }

                let tDataSaturday = tData.filter((t: any) => t.day == 6)
                tDataSaturday.forEach((e: any, index : any) => {
                    this.ttSaturdayPatchDataArray = this._fBuilder.group({
                        Id: e.ID,
                        Subject : e.subject,
                        Teacher : e.teacher,
                        Time_from : e.time_from,
                        Time_to : e.time_to,
                        Room_no : e.room_no
                    })
                    console.log(this.ttData('Saturday').length)

                    if(this.ttSaturdayPatchDataArray) {
                        this.ttData('Saturday').push(this.ttSaturdayPatchDataArray)
                    }
                    console.log(this.ttData('Saturday'))
                })
                if (!this.ttSaturdayPatchDataArray) {
                    this.addTTData('Saturday')
                }

                let tDataSunday = tData.filter((t: any) => t.day == 7)
                tDataSunday.forEach((e: any, index : any) => {
                    this.ttSundayPatchDataArray = this._fBuilder.group({
                        Id: e.ID,
                        Subject : e.subject,
                        Teacher : e.teacher,
                        Time_from : e.time_from,
                        Time_to : e.time_to,
                        Room_no : e.room_no
                    })
                    console.log(this.ttData('Sunday').length)

                    if(this.ttSundayPatchDataArray) {
                        this.ttData('Sunday').push(this.ttSundayPatchDataArray)
                    }
                    console.log(this.ttData('Sunday'))
                })
                if (!this.ttSundayPatchDataArray) {
                    this.addTTData('Sunday')
                }
                // this.ttData().push(patchTTData)
                // ttarray.controls = tData.map((r :any) => this._fBuilder.group({
                //  Subject : new FormControl (r.subject),
                //  Teacher : new FormControl (r.teacher),
                //  Time_from : new FormControl (r.time_from),
                //  Time_to : new FormControl  (r.time_to),
                //  Room_no : new FormControl (r.room_no)
            }else{

            }
        })   
    }
    }
    navigate() {
        let robj = this.rObj.value;
        // alert('yes1')
        this._router.navigate(['/academic/createTimeTable/'+robj.Class+'/'+robj.Section]);
    }

    setTabActive(tabName: string) {
        if (tabName == 'mondayTab') {
            this.mondayTab = true;
            this.day = 1
        } else {
            this.mondayTab = false
        }
        if (tabName == 'tuesdayTab') {
            this.tuesdayTab = true
            this.day = 2
        } else {
            this.tuesdayTab = false
        }
        if (tabName == 'wednesdayTab') {
            this.wednesdayTab = true
            this.day = 3
        } else {
            this.wednesdayTab = false
        }
        if (tabName == 'thursdayTab') {
            this.thursdayTab = true
            this.day = 4
        } else {
            this.thursdayTab = false
        }
        if (tabName == 'fridayTab') {
            this.fridayTab = true
            this.day = 5
        } else {
            this.fridayTab = false
        }
        if (tabName == 'saturdayTab') {
            this.saturdayTab = true
            this.day = 6
        } else {
            this.saturdayTab = false
        }
        if (tabName == 'sundayTab') {
            this.sundayTab = true
            this.day = 7
        } else {
            this.sundayTab = false
        }
    }

    savetimeTable(){
        if (this.day == 1) {
            let mondayData = this.rObj.controls.ttDataMonday.controls;
            for (let i = 0; i < mondayData.length; i++) {
                this.ttDataArray.push(mondayData[i].value)
            }    
        }   
        if (this.day == 2) {
            let tuesdayData = this.rObj.controls.ttDataTuesday.controls;
            for (let i = 0; i < tuesdayData.length; i++) {
                this.ttDataArray.push(tuesdayData[i].value)
            }    
        } 
        if (this.day == 3) {
            let wednesdayData = this.rObj.controls.ttDataWednesday.controls;
            for (let i = 0; i < wednesdayData.length; i++) {
                this.ttDataArray.push(wednesdayData[i].value)
            }    
        }
        if (this.day == 4) {
            let thursdayData = this.rObj.controls.ttDataThursday.controls;
            for (let i = 0; i < thursdayData.length; i++) {
                this.ttDataArray.push(thursdayData[i].value)
            }    
        }
        if (this.day == 5) {
            let fridayData = this.rObj.controls.ttDataFriday.controls;
            for (let i = 0; i < fridayData.length; i++) {
                this.ttDataArray.push(fridayData[i].value)
            }    
        }
        if (this.day == 6) {
            let saturdayData = this.rObj.controls.ttDataSaturday.controls;
            for (let i = 0; i < saturdayData.length; i++) {
                this.ttDataArray.push(saturdayData[i].value)
            }    
        }
        if (this.day == 7) {
            let sundayData = this.rObj.controls.ttDataSunday.controls;
            for (let i = 0; i < sundayData.length; i++) {
                this.ttDataArray.push(sundayData[i].value)
            }    
        }
        let mData = {
            action: 'save',
            inst_id: this.instID,
            academic_id: this.activeAcademicID,
            class : this.classId, 
            section : this.sectionId,
            medium : this.isVernacular == 1 ? this.mediumId : 0,
            day : this.day,
            ttData : this.ttDataArray,
            del_ids : this.ttDeleteIds
        }
        console.log(mData)
        this._aService.DataByDay(mData).subscribe(ayRes => {
            console.log(ayRes)
            if (ayRes.status === true) {
                this._reRoute.shouldReuseRoute = () => false;
                this._router.navigate([this._router.url]);
                this._gService.triggerSnackbar('Time Table Saved Successfully', 'success', true);
            } else {
                this._gService.triggerSnackbar('Something went wrong', 'error', true);
            }
        }, err => {
            console.log(err);
            this._gService.triggerSnackbar('Server Error!', 'error', true);
        })
        
    }
    
    
}