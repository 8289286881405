import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppPageRoutes } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SnackbarComponent } from './components/shared/snackbar/snackbar.component';
import { LoaderComponent } from './components/shared/loader/loader.component';

import { UserLoginModule } from './pages/user-login/userlogin.module';
import { UserPanelModule } from './pages/user-panel/userpanel.module';
import { WebsiteModule } from './pages/website/website.module';

import { AdminLoginModule } from './pages/admin-login/adminlogin.module';
import { AdminPanelModule } from './pages/admin-panel/adminpanel.module';


@NgModule({
  declarations: [
    AppComponent,
    AppPageRoutes,
    SnackbarComponent,
    LoaderComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    UserLoginModule,
    UserPanelModule,
    WebsiteModule,
    AdminLoginModule,
    AdminPanelModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }