import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { GlobalService } from 'src/app/services/global.service';
import { InstService } from 'src/app/services/inst.service';
import { SettingsService } from 'src/app/services/settings.service';
//import MD5 from "crypto-js/md5";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public showPasswordText: boolean = false;
  public user = JSON.parse(localStorage.getItem('currentUser')!);

  public instID: number;
  public instSlug: string;
  public instName: string;
  public instIcon: string;
  public instBranch: string;
  public userRoleData: any[] = [];
  public showError = false;
  public imgURL = this._gService.getImgPath();

  constructor(
    private _router: Router,
    private _gService: GlobalService,
    private _userService: UserService,
    private _instService: InstService,
    private _sService: SettingsService
  ) {}
  
  UserName = new FormControl('', [Validators.required]);
  Password = new FormControl('', [Validators.required]);
  UserRole = new FormControl('1', [Validators.required]);

  ngOnInit() {
    if (this.user) {
      this._router.navigate(['/dashboard']);
      return false;
    }

    this._gService.showLoader(true);

    let current_url = window.location.href;

    if (current_url.indexOf('localhost') > -1) {
      this.instSlug = 'demo-school';
    } else {
      this.instSlug = this.getSubdomain(current_url) == 'app' || this.getSubdomain(current_url) == 'appuat' ? 'demo-school' : this.getSubdomain(current_url);
    }

    // this.instSlug = 'demo-school';

    this._instService.getInstListBySlug(this.instSlug).subscribe(dataRes => {
      console.log(dataRes);
      if (dataRes.status === true) {
          let instData = JSON.parse(dataRes.result);
          // this.instID = instData[0].ID;
          this.instID = 1; 
          this.instName = instData[0].inst_name;
          //this.instIcon = instData[0].icon_path;
          this.instIcon = instData[0].icon_path ? this.imgURL+instData[0].icon_path : '/assets/img/default-institution-icon.png';
          this.instBranch = instData[0].inst_branch;
      } else {
        this._gService.triggerSnackbar('Invalid URL, Redirecting to Main Website', 'error', true)
        setTimeout(() => {
          window.location.href = 'https://www.edunationpro.com';
        }, 3000); 
      }
      this._gService.showLoader(false);
    })

    this._userService.getAllUserROleData().subscribe(sRes => {
      console.log(sRes)
      if (sRes.status === true) {
          this.userRoleData = JSON.parse(sRes.result);
      }
      this._gService.showLoader(false);
  })


  }

  getSubdomain(url: any) {
    let domain = url;
    if (url.includes("://")) {
        domain = url.split('://')[1];
    }
    const subdomain = domain.split('.')[0];
    return subdomain;
};

  showPassword() {
    this.showPasswordText = !this.showPasswordText;
  }

  submit() {
    this._gService.showLoader(true);
    if ((this.UserName.valid || this.Password.valid)) {
      let loginData = {
          inst_id: this.instID,
          username: this.UserName.value,
          //password: MD5(this.Password.value!).toString()
          password: this.Password.value,
          user_role: this.UserRole.value
      }
      console.log(this.instID)
      console.log(loginData)
      this.showError = false;
      this._userService.userLoginCheck(loginData).subscribe(res => {
        console.log(res.status)
        if (res.status === true) {
          console.log(res.result)
          let user = JSON.parse(res.result)
          console.log(user)
          console.log(user[0])
          console.log(user[0].instID)
          this._sService.getMultipleSettingsData(user[0].instID, 'activeSession').subscribe(settingRes => {
            console.log(settingRes)
            if (settingRes.status === true) {
              console.log(settingRes.result)

              this._instService.getMultiInstDataByID(user[0].instID, 'isMultiBranch,isVernacular').subscribe(settingsRes => {
                console.log(settingsRes)
                  let Data = JSON.parse(settingsRes.result)[0]
                  let isVernacular = Data.isVernacular, isMultiBranch = Data.isMultiBranch;
                  console.log(isVernacular, isMultiBranch);
                  let SettingData = JSON.parse(settingRes.result)[0]
                  let UserID = user[0].ID, AcademicID = SettingData.activeSession, InstID = user[0].instID, UserRole = user[0].userRole, activeMedium = SettingData.medium, activeLanguage = SettingData.inst_language;
                  console.log(UserID, InstID, UserRole);
                  localStorage.setItem('currentUser', JSON.stringify({ UserID, InstID, AcademicID, UserRole, isMultiBranch, isVernacular, activeMedium, activeLanguage}));
                  this._router.navigate(['/dashboard/']);
                  this._gService.triggerSnackbar('Login Succesful!', 'success', true); 
            })
            } else {
              this._gService.triggerSnackbar('Something went wrong', 'error', true); 
            }
          })
        } else {
          console.log(res);
          this.showError = true;
        }
      })
      this._gService.showLoader(false);
    }
  }

}
