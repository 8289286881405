import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserPanelComponent } from './userpanel.component';
import { HeaderComponent } from 'src/app/components/common/header/header.component';
import { SidebarComponent } from 'src/app/components/common/sidebar/sidebar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StudentListComponent } from './students/studentList/list.component';
import { AcademicYearsComponent } from './settings/academic-years/add/add.component';
import { AcademicYearsEditComponent } from './settings/academic-years/edit/edit.component';
import { BranchModalComponent } from 'src/app/components/modal/branchmodal/branchmodal.component';
import { AcademicYearModalComponent } from 'src/app/components/modal/academicyearmodal/academicyearmodal.component';
import { sectionComponent } from './academic/section/section.component';
import { SectionModalComponent } from 'src/app/components/modal/sectionmodal/sectionmodal.component';
import { classComponent } from './academic/class/class.component';
import { ClassModalComponent } from 'src/app/components/modal/classmodal/classmodal.component';
import { mediumComponent } from './academic/medium/medium.component';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';
import { subjectsComponent } from './academic/subjects/subjects.component';
import { SubjectModalComponent } from 'src/app/components/modal/subjectmodal/subjectmodal.component';
import { assignComponent } from './academic/assign-subject/assign-subject.component';
import { AssignsubjectModalComponent } from 'src/app/components/modal/assign-subjectmodal/assign-subjectmodal.component';
import { assignTeacherComponent } from './academic/assign-teacher/assign-teacher.component';
import { AssignteacherModalComponent } from 'src/app/components/modal/assign-teachermodal/assign-teachermodal.component';
import { departmentComponent } from './staff/department/department.component';
import { DepartmentModalComponent } from 'src/app/components/modal/departmentmodal/departmentmodal.component';
import { designationComponent } from './staff/designation/designation.component';
import { DesignationModalComponent } from 'src/app/components/modal/designationmodal/designationmodal.component';
import { leaveComponent } from './staff/leave/leave.component';
import { LeaveModalComponent } from 'src/app/components/modal/leavemodal/leavemodal.component';
import { hostelDetailsComponent } from './hostel/hosteldetails/hostelDetails.component';
import { HostelDetailsmodalComponent } from 'src/app/components/modal/hostelDetailsmodal/hostelDetailsmodal.component';
import { hostelRoomsComponent } from './hostel/hostelRooms/hostelRooms.component';
import { HostelRoomsmodalComponent } from 'src/app/components/modal/hostelRoomsmodal/hostelRoomsmodal.component';
import { hostelRoomTypeComponent } from './hostel/hostelRoomType/hostelRoomType.component';
import { HostelRoomTypeModalComponent } from 'src/app/components/modal/hostelRoomTypemodal/hostelRoomTypemodal.component';
import { vehicleComponent } from './transport/vehicle/vehicle.component';
import { VehiclemodalComponent } from 'src/app/components/modal/vehiclemodal/vehiclemodal.component';
import { driverComponent } from './transport/driver/driver.component';
import { DrivermodalComponent } from 'src/app/components/modal/drivermodal/drivermodal.component';
import { vehicleTypeComponent } from './transport/vehicleType/vehicleType.component';
import { VehicleTypeModalComponent } from 'src/app/components/modal/vehicleTypemodal/vehicletypemodal.component';
import { routeComponent } from './transport/route/route.component';
import { RoutemodalComponent } from 'src/app/components/modal/routemodal/routemodal.component';
import { destinationComponent } from './transport/destination/destination.component';
import { DestinationModalComponent } from 'src/app/components/modal/destinationmodal/destinationmodal.component';
import { transportAllocationComponent } from './transport/transportAllocation/transportAllocation.component';
import { TransportAllocationmodalComponent } from 'src/app/components/modal/transportAllocationmodal/transportAllocationmodal.component';
import { addStudentComponent } from './students/addStudent/addStudent.component';
import { editStudentComponent } from './students/studentedit/editStudent.component';
import { addStaffComponent } from './staff/addStaff/addStaff.component';
import { StaffListComponent } from './staff/staffList/staffList.component';
import { editStaffComponent } from './staff/editStaff/editStaff.component';
import { payHeadComponent } from './payroll/payHead/payHead.component';
import { PayHeadModalComponent } from 'src/app/components/modal/payHeadmodal/payHeadmodal.component';
import { feesTypeComponent } from './fees/feesType/feesType.component';
import { FeesTypemodalComponent } from 'src/app/components/modal/feesTypemodal/feesTypemodal.component';
import { feesCollectionComponent } from './fees/feesCollection/feesCollection.component';
import { feesDiscountComponent } from './fees/feesDiscount/feesDiscount.component';
import { FeesDiscountmodalComponent } from 'src/app/components/modal/feesDiscountmodal/feesDiscountmodal.component';
import { bookCategoryComponent } from './library/bookCategory/bookCategory.component';
import { BookCategorymodalComponent } from 'src/app/components/modal/bookCategorymodal/bookCategorymodal.component';
import { addbookComponent } from './library/addBook/addBook.component';
import { AddbookmodalComponent } from 'src/app/components/modal/addBookmodal/addBookmodal.component';
import { addMemberComponent } from './library/addMember/addMember.component';
import { AddMemberModalComponent } from 'src/app/components/modal/addMembermodal/addMembermodal.component';
import { issueBookComponent } from './library/issueBook/issueBook.component';
import { applyLeaveComponent } from './staff/applyLeave/applyLeave.component';
import { ApplyLeavemodalModalComponent } from 'src/app/components/modal/applyLeavemodal/applyLeavemodal.component';
import { approveLeaveComponent } from './staff/approveLeave/approveLeave.component';
import { AddLeavemodalModalComponent } from 'src/app/components/modal/addLeavemodal/addLeavemodal.component';
import { ViewStudentComponent } from './students/viewStudent/viewStudent.component';
import { ViewStaffComponent } from './staff/viewStaff/viewStaff.component';
import { InventoryCategoryComponent } from './vendors/inventoryCategory/inventoryCategory.component';
import { InventoryItemComponent } from './vendors/inventoryItem/inventoryItem.component';
import { InventoryPurchaseComponent } from './vendors/inventoryPurchase/inventoryPurchase.component';
import { InventoryIssueComponent } from './vendors/inventoryIssue/inventoryIssue.component';
import { InventoryCategoryModalComponent } from 'src/app/components/modal/inventoryCategorymodal/inventoryCategorymodal.component';
import { InventoryIssueModalComponent } from 'src/app/components/modal/inventoryIssuemodal/inventoryIssuemodal.component';
import { InventoryItemModalComponent } from 'src/app/components/modal/inventoryItemmodal/inventoryItemmodal.component';
import { InventoryPurchaseModalComponent } from 'src/app/components/modal/inventoryPurchasemodal/inventoryPurchasemodal.component';
import { AddVendorsComponent } from './vendors/addVendor/addVendors.component';
import { VendorComponent } from './vendors/vendor/vendor.component';
import { EditVendorsComponent } from './vendors/editVendor/editvendor.component';
import { AddTaskComponent } from './taskManagement/addTask/addTask.component';
import { TaskListComponent } from './taskManagement/taskList/taskList.component';
import { EditTaskComponent } from './taskManagement/editTask/editTask.component';
import { BirthdayComponent } from './students/birthday/birthday.component';
import { bulkImportComponent } from './students/bulkImport/bulkImport.component';
import { StaffbulkImportComponent } from './staff/bulkImport/bulkImport.component';
import { StudentAttendanceComponent } from './attendance/studentAttendance/studentAttendance.component';
import { StaffAttendanceComponent } from './attendance/staffAttendance/staffAttendance.component';
import { CertificateTemplateComponent } from './certificates/addCertificate/addCertificate.component';
import { ExamComponent } from './examination/exam/exam.component';
import { ExamModalComponent } from 'src/app/components/modal/exammodal/exammodal.component';
import { MarksGradeComponent } from './examination/marksGrade/marksGrade.component';
import { MarksGradeModalComponent } from 'src/app/components/modal/marksGrademodal/marksGrademodal.component';
import { IncomeHeadComponent } from './finance/incomeHead/incomeHead.component';
import { CertificateListComponent } from './certificates/certificateList/certificateList.component';
import { EditCertificateComponent } from './certificates/editCertificate/editCertificate.component';
import { IncomeHeadModalComponent } from 'src/app/components/modal/incomeHeadmodal/incomeHeadmodal.component';
import { IncomeComponent } from './finance/income/income.component';
import { IncomeModalComponent } from 'src/app/components/modal/incomemodal/incomemodal.component';
import { ExpenseHeadComponent } from './finance/expenseHead/expenseHead.component';
import { ExpenseHeadModalComponent } from 'src/app/components/modal/expenseHeadmodal/expenseHeadmodal.component';
import { ExpenseComponent } from './finance/expense/expense.component';
import { ExpenseModalComponent } from 'src/app/components/modal/expensemodal/expensemodal.component';
import { IdCardTemplateComponent } from './students/idCard/idCardTemplate/idCardTemplate.component';
import { StaffIdCardTemplateComponent } from './staff/idCard/StaffidCardTemplate/StaffidCardTemplate.component';
import { EditIdCardComponent } from './students/idCard/editIdCard/editIdCard.component';
import { AssignmentModalComponent } from 'src/app/components/modal/assignmentmodal/assignmentmodal.component';
import { PagesComponent } from './enpcms/pages/pages.component';
import { PageListComponent } from './enpcms/pageList/pageList.component';
import { EditPageComponent } from './enpcms/editPage/editPage.componet';
import { SelectModalComponent } from 'src/app/components/modal/selectmodal/selectmodal.component';
import { MediaComponent } from './enpcms/media/media.component';
import { MediaModalComponent } from 'src/app/components/modal/mediamodal/mediamodal.component';
import { HolidayComponent } from './holiday/holiday.component';
import { HolidayModalComponent } from 'src/app/components/modal/holidaymodal/holidaymodal.component';
import { ClassTimeTableComponent } from './academic/classTimeTable/classTimetable.component';
import { CreateTimeTableComponent } from './academic/createTimeTable/createTimetable.component';
import { teachertimetableComponent } from './academic/teachertimetable/teachertimetable.component';
import { CollectFeeComponent } from './fees/collectFee/collectFee.component';
import { PayModalComponent } from 'src/app/components/modal/paymodal/paymodal.component';
import { FeeReminderComponent } from './fees/feesReminder/feesReminder.component';
import { DueFeeComponent } from './fees/dueFee/dueFee.component';
import { SearchFeePayComponent } from './fees/searchFeePay/searchFeePay.component';
import { EventComponent } from './event/eventType/event.component';
import { EventModalComponent } from 'src/app/components/modal/eventmodal/eventmodal.component';
import { CircularComponent } from './communication/circular/addcircular/circular.component';
import { CircularmodalComponent } from 'src/app/components/modal/circularmodal/circularmodal.component';
import { PrintReceiptComponent } from './fees/printReceipt/printReceipt.component';
import { feesMasterComponent } from './fees/feesMaster/feesMaster.component';
import { FeesMastermodalComponent } from 'src/app/components/modal/feesMastermodal/feesMastermodal.component';
import { feeGroupComponent } from './fees/feeGroup/feeGroup.component';
import { FeeGroupmodalModalComponent } from 'src/app/components/modal/feeGroupmodal/feeGroupmodal.component';
import { AcademicyearsModalComponent } from 'src/app/components/modal/academicyearsmodal/academicyearsmodal.component';
import { modulesAccessComponent } from './settings/modules-access/modules-access.component';
import { ExamTimeTableComponent } from './examination/examTimetable/examTimetable.component';
import { ExamScheduleComponent } from './examination/examSchedule/examSchedule.component';
import { printReceiptModalComponent } from 'src/app/components/modal/printReceiptmodal/printReceiptmodal.component';
import { GenerateComponent } from './payroll/generate/generate.component';
import { GeneratePageComponent } from './payroll/generatePage/generatePage.component';
import { EditGeneratePageComponent } from './payroll/editgeneratePage/editgeneratePage.component';
import { PaymentModalComponent } from 'src/app/components/modal/paymentmodal/paymentmodal.component';
import { PrintPaymodalModalComponent } from 'src/app/components/modal/printPaymodal/printPaymodal.component';
import { studentReportComponent } from './reports/studentReport/studentReport.component';
import { financeReportComponent } from './reports/financeReport/financeReport.component';
import { dailyCollectionModalComponent } from 'src/app/components/modal/dailyCollectionModal/dailyCollectionModal.component';
import { AttendanceReportComponent } from './reports/attendanceReport/attendanceReport.component';
import { SettingsComponent } from './enpcms/settings/settings.component';
import { StaffReportComponent } from './reports/staffReport/staffReport.component';
import { InventoryReportComponent } from './reports/inventoryReport/inventoryReport.component';
import { LibraryReportComponent } from './reports/libraryReport/libraryReport.component';
import { TransportReportComponent } from './reports/transportReport/transportReport.component';
import { HostelReportsComponent } from './reports/hostelReport/hostelReport.component';
import { UserLogReportComponent } from './reports/userLogReport/userLogReport.component';
import { ActivityLogReportComponent } from './reports/activityLogReport/activityLogReport.component';
import { ReturnbookmodalModalComponent } from 'src/app/components/modal/returnbookmodal/returnbookmodal.component';
import { AddEventComponent } from './event/addEvent/addEvent.component';
import { AddEventModalComponent } from 'src/app/components/modal/addEventmodal/addEventmodal.component';
import { PromoteStudentComponent } from './students/promoteStudent/promoteStudent.component';
import { GeneralComponent } from './settings/general/general.component';
import { PrintHeaderFooterComponent } from './settings/printHeaderFooter/printHeaderFooter.component';
import { MenuComponent } from './enpcms/menu/menu.component';
import { MenuModalComponent } from 'src/app/components/modal/menumodal/menumodal.component';
import { MenuItemsComponent } from './enpcms/menu/menuItems/menuItems.component';
import { MenuItemModalComponent } from 'src/app/components/modal/menuItemmodal/menuItemmodal.component';
import { OnlineAdmissionSettingsComponent } from './online-admission/settings/settings.component';
import { PickUpPointComponent } from './transport/pick-up-point/pick-up-point.component';
import { PickUpModalComponent } from 'src/app/components/modal/pick-upmodal/pick-upmodal.component';
import { PickUpRouteListComponent } from './transport/pick-up-route/pick-up-routeList.component';
import { AddRouteModalComponent } from 'src/app/components/modal/addRoutemodal/addRoutemodal.component';
import { ApplicationsComponent } from './online-admission/applications/applications.component';
import { EnrollPageComponent } from './online-admission/enrollPage/enrollPage.component';
import { EmailComponent } from './communication/email/email.component';
import { GenerateCertificateComponent } from './certificates/generateCertificate/generateCertificate.component';
import { CertificateModalComponent } from 'src/app/components/modal/certificatemodal/certificatemodal.component';
import { ViewCertificatemodalModalComponent } from 'src/app/components/modal/viewCertificatemodal/viewCertificatemodal.component';
import { GenerateIdComponent } from './students/idCard/generateId/generateId.component';
import { IdModalComponent } from 'src/app/components/modal/idmodal/idmodal.component';
import { IdListComponent } from './students/idCard/idList/idList.component';
import { ViewIdModalComponent } from 'src/app/components/modal/viewIdmodal/viewIdmodal.component';
import { StaffIdListComponent } from './staff/idCard/staffidList/staffidList.component';
import { EditStaffIdCardComponent } from './staff/idCard/editStaffIdCard/editStaffIdCard.component';
import { GenerateStaffIdComponent } from './staff/idCard/generateStaffId/generateStaffId.component';
import { BranchesListComponent } from './branches/list/list.component';
import { BranchesAddComponent } from './branches/add/add.component';
import { BranchesEditComponent } from './branches/edit/edit.component';
import { HomeworkComponent } from './homework/addhomework/homework.component';
import { UserProfileComponent } from './user/userProfile/userProfile.component';
import { HomeworkListComponent } from './homework/homeworkList/homeworkList.component';
import { CircularListComponent } from './communication/circular/circularList/circularList.component';
import { ChangePasswordModalComponent } from 'src/app/components/modal/changePasswordmodal/changePassword.component';
import { EditCircularComponent } from './communication/circular/editcircular/editcircular.component';
import { EditHomeworkComponent } from './homework/edithomework/edithomework.component';
import { MarkRegisterComponent } from './examination/markregister/markregister.component';
import { markRegistermodalComponent } from 'src/app/components/modal/markRegistermodal/markRegistermodal.component';
import { ExamResultComponent } from './examination/examResult/examResult.component';
import { SyllabusComponent } from './syllabus/syllabus/syllabus.component';
import { EditSyllabusModalComponent } from 'src/app/components/modal/editSyllabusmodal/editSyllabusmodal.component';
import { AddSyllabusModalComponent } from 'src/app/components/modal/addSyllabusmodal/addSyllabusmodal.component';
import { SyllabusStatusComponent } from './syllabus/syllabusStatus/syllabusStatus.component';
import { SyllabusStatusDateModalComponent } from 'src/app/components/modal/syllabusStatusDatemodal/syllabusStatusDatemodal.component';
import { MultiplePayModalComponent } from 'src/app/components/modal/multiplePaymodal/multiplePaymodal.component';
import { MessageModalComponent } from 'src/app/components/modal/messagemodal/messagemodal.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { IncidentsComponent } from './behaviour/incidents/incidents.component';
import { EditIncidentModal } from 'src/app/components/modal/editIncidentmodal/editIncidentmodal.component';
import { AssignIncidentsComponent } from './behaviour/assignIncidents/assignIncidents.component';
import { AssignIncidentModal } from 'src/app/components/modal/assignIncidentmodal/assignIncidentmodal.component';

const routes: Routes = [
    {
        path: '', component: UserPanelComponent, title: 'EduNationPro',
        children: [
            { path: 'dashboard', component: DashboardComponent, title: 'Dashboard - EduNationPro' },
            { path: 'branches/list', component: BranchesListComponent, title: 'Institutions List - EduNationPro'},
            { path: 'branches/add', component: BranchesAddComponent, title: 'Add Institution - EduNationPro'},
            { path: 'branches/edit/:instId', component: BranchesEditComponent, title: 'Edit Institution - EduNationPro'},
            { path: 'academic/class', component: classComponent, title: 'Class | Academic - EduNationPro'},
            { path: 'academic/section', component: sectionComponent, title: 'Section | Academic - EduNationPro'},
            { path: 'academic/medium', component: mediumComponent, title: 'Medium | Academic - EduNationPro'},
            { path: 'academic/subjects', component: subjectsComponent, title: 'Subjects | Academics - EduNationPro'},
            { path: 'academic/assign-subject', component: assignComponent, title: 'Assign-Subject | Academics - EduNationPro'},
            { path: 'academic/assign-teacher', component: assignTeacherComponent, title: 'Assign-Teacher | Academics - EduNationPro'},
            { path: 'academic/class-timetable', component: ClassTimeTableComponent, title: 'Time-Table | Academics - EduNationPro'},
            { path: 'academic/class-timetable/:class/:section', component: ClassTimeTableComponent, title: 'Time-Table | Academics - EduNationPro'},
            { path: 'academic/createTimeTable/:class/:section', component: CreateTimeTableComponent, title: 'Time-Table | Academics - EduNationPro'},
            { path: 'academic/createTimeTable', component: CreateTimeTableComponent, title: 'Time-Table | Academics - EduNationPro'},
            { path: 'academic/teacher-timetable', component: teachertimetableComponent, title: 'Time-Table | Academics - EduNationPro'},
            { path: 'academic/teacher-timetable/:teacher', component: teachertimetableComponent, title: 'Time-Table | Academics - EduNationPro'},
            { path: 'syllabus/add', component: SyllabusComponent, title: 'Syllabus | Syllabus - EduNationPro'},
            { path: 'syllabus/status', component: SyllabusStatusComponent, title: 'Syllabus Status| Syllabus - EduNationPro'},
            { path: 'syllabus/status/:class/:section/:subject', component: SyllabusStatusComponent, title: 'Syllabus Status| Syllabus - EduNationPro'},  
            { path: 'settings/academic-years', component: AcademicYearsComponent, title: 'Academic Years | Settings - EduNationPro'},
            { path: 'settings/modules-access', component: modulesAccessComponent, title: 'Modules Access | Settings - EduNationPro'},
            { path: 'settings/modules-access/:userRole', component: modulesAccessComponent, title: 'Modules Access | Settings - EduNationPro'},
            { path: 'settings/general', component: GeneralComponent, title: 'General Settings | Settings - EduNationPro'},
            { path: 'settings/print-header-footer', component: PrintHeaderFooterComponent, title: 'PrintHeaderFooter Settings | Settings - EduNationPro'},
            { path: 'staff/department/add', component: departmentComponent, title: 'Department | Staff - EduNationPro'},
            { path: 'staff/designation/add', component: designationComponent, title: 'Designation | Staff - EduNationPro'},
            { path: 'staff/leave-type/add', component: leaveComponent, title: 'Leave-Type | Staff - EduNationPro'},
            { path: 'staff/import', component: StaffbulkImportComponent, title: 'Staff Import| Staff - EduNationPro'},
            { path: 'hostel/hostel-details/add', component: hostelDetailsComponent, title: 'Hostel-Details | Hostel - EduNationPro'},
            { path: 'hostel/hostel-rooms/add', component: hostelRoomsComponent, title: 'HostelRoom-Details | Hostel - EduNationPro'},
            { path: 'hostel/hostel-room-type/add', component: hostelRoomTypeComponent, title: 'HostelRoomType-Details | Hostel - EduNationPro'},
            { path: 'transport/vehicles/add', component: vehicleComponent, title: 'Vehicle-Details | Transport - EduNationPro'},
            { path: 'transport/driver/add', component: driverComponent, title: 'Driver-Details | Transport - EduNationPro'},
            { path: 'transport/vehicle-type/add', component: vehicleTypeComponent, title: 'VehicleType-Details | Transport - EduNationPro'},
            { path: 'transport/route/add', component: routeComponent, title: 'Route-Details | Transport - EduNationPro'},
            { path: 'transport/tracking', component: transportAllocationComponent, title: 'TransportAllocation-Details | Transport - EduNationPro'},
            { path: 'transport/pickup-point', component: PickUpPointComponent, title: 'Pick-Up Details | Transport - EduNationPro'},
            { path: 'transport/route-pickup-point', component: PickUpRouteListComponent, title: 'Pick-Up-Route Details | Transport - EduNationPro'},
            // { path: 'transport/route-pickup-point/:id', component: PickUpRouteComponent, title: 'Pick-Up-Route Details | Transport - EduNationPro'},
            { path: 'student/add', component: addStudentComponent, title: 'Add Student | Students - EduNationPro'},
            { path: 'student/list', component: StudentListComponent, title: 'Students List - EduNationPro' },
            { path: 'student/list/:class/:section', component: StudentListComponent, title: 'Students List - EduNationPro' },
            { path: 'student/edit/:id', component: editStudentComponent, title: 'Edit Student | Students - EduNationPro'},
            { path: 'student/view/:id', component: ViewStudentComponent, title: 'View Student | Students - EduNationPro'},
            { path: 'student/birthday/list', component: BirthdayComponent, title: 'Student Birthday | Students - EduNationPro'},
            { path: 'student/promote/add', component: PromoteStudentComponent, title: 'Promote Student | Students - EduNationPro' },
            { path: 'student/promote/add/:classId/:sectionId', component: PromoteStudentComponent, title: 'Promote Student | Students - EduNationPro' },
            { path: 'student/import', component: bulkImportComponent, title: 'Student Import| Students - EduNationPro'},
            { path: 'student/generate-id/list', component: IdListComponent, title: 'Student IdCard| Students - EduNationPro'},
            { path: 'id-card/:id', component: EditIdCardComponent, title: 'Student IdCard| Students - EduNationPro'},
            { path: 'student/id/list', component: IdCardTemplateComponent, title: 'Student IdCard| Students - EduNationPro'},
            { path: 'student/generate-id/:id', component: IdCardTemplateComponent, title: 'Student IdCard| Students - EduNationPro'},
            { path: 'staff/add', component: addStaffComponent, title: 'Add Staff | Staffs - EduNationPro'},
            { path: 'staff/list', component: StaffListComponent, title: 'Staff List | Staffs - EduNationPro'},
            { path: 'staff/id/list', component: StaffIdCardTemplateComponent, title: 'Staff IdCard| Staffs - EduNationPro'},
            { path: 'staff/generate-id/list', component: StaffIdListComponent, title: 'Staff IdCards | Staffs - EduNationPro'},
            { path: 'staffid-card/:id', component: EditStaffIdCardComponent, title: 'Staff IdCard| Staffs - EduNationPro'},
            { path: 'staff/generate-id/:id', component: StaffIdCardTemplateComponent, title: 'Staff IdCards | Staffs - EduNationPro'},
            { path: 'staff/list/:department/:designation', component: StaffListComponent, title: 'Staff List | Staffs - EduNationPro'},
            { path: 'staff/edit/:id', component: editStaffComponent, title: 'Edit Staff | Staffs - EduNationPro'},
            { path: 'staff/leave/apply/add', component: applyLeaveComponent, title: 'Apply Leave | Staffs - EduNationPro'},
            { path: 'staff/leave/approve/add', component: approveLeaveComponent, title: 'Approve Leave | Staffs - EduNationPro'},
            { path: 'staff/view/:id', component: ViewStaffComponent, title: 'View Staff | Staffs - EduNationPro'},
            { path: 'payroll/payhead/add', component: payHeadComponent, title: 'Pay Head | Payroll - EduNationPro'},
            { path: 'payroll/list', component: GenerateComponent, title: 'Generate Payroll | Payroll - EduNationPro'},
            { path: 'payroll/list/:dept/:des/:month/:year', component: GenerateComponent, title: 'Generate Payroll | Payroll - EduNationPro'},
            { path: 'payroll/generatePage/:id/:month/:year', component: GeneratePageComponent, title: 'GeneratePage Payroll | Payroll - EduNationPro'},
            { path: 'payroll/editgeneratePage/:generateId', component: EditGeneratePageComponent, title: 'Edit GeneratePage Payroll | Payroll - EduNationPro'},
            { path: 'fees/fees-type/add', component: feesTypeComponent, title: 'Fees Type | Fees - EduNationPro'},
            { path: 'fees/fees-master/add', component: feesMasterComponent, title: 'Fees Group | Fees - EduNationPro'},
            { path: 'fees/fees-collection/list', component: feesCollectionComponent, title: 'Fees Collection | Fees - EduNationPro'},
            { path: 'fees/fees-collection/list/:class/:section', component: feesCollectionComponent, title: 'Fees Collection | Fees - EduNationPro'},
            { path: 'fees/fees-collection/collectFee/:id', component: CollectFeeComponent, title: 'Fees Collection | Fees - EduNationPro'},
            { path: 'fees/fees-discount/add', component: feesDiscountComponent, title: 'Fees Discount | Fees - EduNationPro'},
            { path: 'fees/fees-reminder/add', component: FeeReminderComponent, title: 'Fees Reminder | Fees - EduNationPro'},
            { path: 'fees/due-fees/list', component: DueFeeComponent, title: 'Fees Due | Fees - EduNationPro'},
            { path: 'fees/due-fees/list/:type/:class/:section', component: DueFeeComponent, title: 'Fees Due | Fees - EduNationPro'},
            { path: 'fees/fees-payment/list', component: SearchFeePayComponent, title: 'Fees Pay | Fees - EduNationPro'},
            // { path: 'fees/printReceipt/:id', component: PrintReceiptComponent, title: 'Fees Receipt | Fees - EduNationPro'},
            { path: 'fees/fees-group/add', component: feeGroupComponent, title: 'Fees Group | Fees - EduNationPro'},
            { path: 'library/category/add', component: bookCategoryComponent, title: 'Book Category | Library - EduNationPro'},
            { path: 'library/add', component: addbookComponent, title: 'Add Book | Library - EduNationPro'},
            { path: 'library/member/add', component: addMemberComponent, title: 'Add Member | Library - EduNationPro'},
            { path: 'library/member/addStudent/:usertype/:class/:section', component: addMemberComponent, title: 'Add Member | Library - EduNationPro'},
            { path: 'library/member/addStaff/:usertype/:dept/:des', component: addMemberComponent, title: 'Add Member | Library - EduNationPro'},
            { path: 'library/issue-book/add', component: issueBookComponent, title: 'Issue Book | Library - EduNationPro'},  
            { path: 'library/issue-book/staff/:dept/:des', component: issueBookComponent, title: 'Issue Book | Library - EduNationPro'},    
            { path: 'library/issue-book/student/:class/:section', component: issueBookComponent, title: 'Issue Book | Library - EduNationPro'},  
            { path: 'store-management/inventory-category/add', component: InventoryCategoryComponent, title: ' Inventory Category | Store Management - EduNationPro'},       
            { path: 'store-management/inventory-item/add', component: InventoryItemComponent, title: 'Inventory Item | Store Management - EduNationPro'}, 
            { path: 'store-management/inventory-purchase/add', component: InventoryPurchaseComponent, title: 'Inventory Purchase | Store Management - EduNationPro'}, 
            { path: 'store-management/inventory-issue/add', component: InventoryIssueComponent, title: 'Inventory Issue | Store Management - EduNationPro'}, 
            { path: 'store-management/vendor/list', component: VendorComponent, title: 'Vendor List | Store Management - EduNationPro'}, 
            { path: 'store-management/vendor/add', component: AddVendorsComponent, title: 'Add Vendor | Store Management - EduNationPro'}, 
            { path: 'vendor/edit/:id', component: EditVendorsComponent, title: 'Edit Vendor | Store Management - EduNationPro'}, 
            { path: 'task-management/add', component: AddTaskComponent, title: 'Add Task | Task Management - EduNationPro'}, 
            { path: 'task-management/list', component: TaskListComponent, title: 'Task List| Task Management - EduNationPro'}, 
            { path: 'task/edit/:id', component: EditTaskComponent, title: 'Edit Task| Task Management - EduNationPro'}, 
            { path: 'attendance/student/list', component: StudentAttendanceComponent, title: ' Student Attendance | Attendance - EduNationPro'},
            { path: 'attendance/student/search/:class/:section/:date', component: StudentAttendanceComponent, title: ' Student Attendance | Attendance - EduNationPro'},
            { path: 'attendance/staff/search/:dept/:des/:date', component: StaffAttendanceComponent, title: ' Staff Attendance | Attendance - EduNationPro'},
            { path: 'attendance/staff/list', component: StaffAttendanceComponent, title: ' Staff Attendance | Staff - EduNationPro'},
            { path: 'certificate/add', component: CertificateTemplateComponent, title: 'Certificate | Certificate - EduNationPro'},
            { path: 'certificate/add/:id', component: CertificateTemplateComponent, title: 'Certificate | Certificate - EduNationPro'},
            { path: 'certificate/certificate-template/:id', component: CertificateTemplateComponent, title: 'Certificate | Certificate - EduNationPro'},
            { path: 'certificate/list', component: CertificateListComponent, title: 'Certificate List | Certificate - EduNationPro'},
            { path: 'certificate/certificate-list/:id', component: EditCertificateComponent, title: 'Edit Certificate | Certificate - EduNationPro'},
            { path: 'certificate/generate-certificate', component: GenerateCertificateComponent, title: 'Generate Certificate | Certificate - EduNationPro'},
            { path: 'id/generate-id', component: GenerateIdComponent, title: 'Generate Id | Id - EduNationPro'},
            { path: 'staffid/generate-id', component: GenerateStaffIdComponent , title: 'Generate Staff Id | Id - EduNationPro'},
            { path: 'examination/exam/add', component: ExamComponent, title: 'Exam | Examination - EduNationPro'},
            { path: 'examination/marks-grade/add', component: MarksGradeComponent, title: 'Marks Grade | Examination - EduNationPro'},
            { path: 'examination/exam-timetable', component: ExamTimeTableComponent, title: 'Exam TimeTable | Examination - EduNationPro'},
            { path: 'examination/exam-schedule/list', component: ExamScheduleComponent, title: 'Exam Schedule | Exam Time table - EduNationPro'},
            { path: 'examination/exam-schedule/list/:exam/:class/:section', component: ExamScheduleComponent, title: 'Exam Schedule | Exam Time table - EduNationPro'},
            { path: 'examination/exam/:class/:section/:exam', component: ExamTimeTableComponent, title: 'Exam TimeTable | Exam Time table - EduNationPro'},
            { path: 'examination/marks-register/add', component: MarkRegisterComponent, title: 'Mark Register | Examination - EduNationPro'},
            { path: 'examination/marks-register/add/:exam/:class/:section', component: MarkRegisterComponent, title: 'Mark Register | Examination - EduNationPro'},
            { path: 'examination/exam-result/list', component: ExamResultComponent, title: 'Exam Result | Examination - EduNationPro'},
            { path: 'examination/exam-result/list/:exam/:class/:section', component: ExamResultComponent, title: 'Exam Result | Examination - EduNationPro'},
            { path: 'finance/income-head/add', component: IncomeHeadComponent, title: 'Income Head | Finance - EduNationPro'},
            { path: 'finance/income/add', component: IncomeComponent, title: 'Income  | Finance - EduNationPro'},
            { path: 'finance/expense-head/add', component: ExpenseHeadComponent, title: 'Expense Head | Finance - EduNationPro'},
            { path: 'finance/expense/add', component: ExpenseComponent, title: 'Expense  | Finance - EduNationPro'},
            { path: 'homework/add', component: HomeworkComponent, title: 'Add  | Homework - EduNationPro'},
            { path: 'homework/edit/:editID', component: EditHomeworkComponent, title: 'Edit  | Homework - EduNationPro'},
            { path: 'homework/list', component: HomeworkListComponent, title: 'List  | Homework - EduNationPro'},
            { path: 'cms/pages/add', component: PagesComponent, title: 'Add Page | ENP CMS - EduNationPro'},
            { path: 'cms/pages', component: PageListComponent, title: 'Page List  | ENP CMS - EduNationPro'},
            { path: 'cms/edit/pages/:id', component: EditPageComponent, title: ' Edit Page | ENP CMS - EduNationPro'},
            { path: 'cms/media', component: MediaComponent, title: 'Add Media  | ENP CMS - EduNationPro'},
            { path: 'cms/settings', component: SettingsComponent, title: 'Settings  | ENP CMS - EduNationPro'},
            { path: 'cms/menu', component: MenuComponent, title: 'Menu  | ENP CMS - EduNationPro'},
            { path: 'cms/menu/menuItem/:id', component: MenuItemsComponent, title: 'Add Menu Items  | ENP CMS - EduNationPro'},
            { path: 'holidays', component: HolidayComponent, title: 'Add  | Media - EduNationPro'},
            { path: 'event/event-type/add', component: EventComponent, title: 'Event  | Event - EduNationPro'},
            { path: 'event/add', component: AddEventComponent, title: 'Add Event  | Event - EduNationPro'},
            { path: 'communication/circular/add', component: CircularComponent, title: 'Add Circular  | Communication - EduNationPro'},
            { path: 'communication/circular/list', component: CircularListComponent, title: 'List Circular  | Communication - EduNationPro'},
            { path: 'communication/circular/edit/:circularid', component: EditCircularComponent, title: 'Edit Circular  | Communication - EduNationPro'},
            { path: 'communication/email/add', component: EmailComponent, title: 'Email  | Communication - EduNationPro'},
            { path: 'reports/student/view', component: studentReportComponent, title: 'Student Reports  | Report - EduNationPro'},
            { path: 'reports/finance/balance-fees-statement/view', component: financeReportComponent, title: 'Finance Reports  | Report - EduNationPro'},
            { path: 'reports/attendance/student-attendance/view', component: AttendanceReportComponent, title: 'Attendance Reports  | Report - EduNationPro'},
            { path: 'reports/staff/view', component: StaffReportComponent, title: 'Staff Reports  | Report - EduNationPro'},
            { path: 'reports/inventory/issue-item/view', component: InventoryReportComponent, title: 'Inventory Reports  | Report - EduNationPro'},
            { path: 'reports/library/book-issue/view', component: LibraryReportComponent, title: 'Library Reports  | Report - EduNationPro'},
            { path: 'reports/transport/view', component: TransportReportComponent, title: 'Transport Reports  | Report - EduNationPro'},
            { path: 'reports/hostel/view', component: HostelReportsComponent, title: 'Hostel Reports  | Report - EduNationPro'},
            { path: 'reports/user-log/view', component: UserLogReportComponent, title: 'User Log Reports  | Report - EduNationPro'},
            { path: 'reports/activity-log/view', component: ActivityLogReportComponent, title: 'Activity Log Reports  | Report - EduNationPro'},
            { path: 'online-admission/settings', component: OnlineAdmissionSettingsComponent, title: 'Settings  | Online Admission - EduNationPro'},
            { path: 'online-admission/applications', component: ApplicationsComponent, title: 'Application list  | Online Admission - EduNationPro'},
            { path: 'online-admission/enroll/:id', component: EnrollPageComponent, title: 'Enroll Student  | Online Admission - EduNationPro'},
            { path: 'user/profile', component: UserProfileComponent, title: 'Edit Profile  | User Profile - EduNationPro'},
            { path: 'notifications', component: NotificationsComponent, title: 'Notifications  | Notifications - EduNationPro'},
            { path: 'behaviour/incidents', component: IncidentsComponent, title: 'Incidents | Behaviour - EduNationPro'},
            { path: 'behaviour/assign-incident', component: AssignIncidentsComponent, title: 'Assign Incidents | Behaviour - EduNationPro'},
            { path: 'behaviour/assign-incident/:class/:section', component: AssignIncidentsComponent, title: 'Assign Incidents | Behaviour - EduNationPro'},
        ]
}
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class UserPanelRoutingModule {}

export const UserPanelRoutes = [
    UserPanelComponent,
    HeaderComponent,
    SidebarComponent,
    DashboardComponent,
    BranchesAddComponent,
    BranchesEditComponent,
    BranchesListComponent,
    BranchModalComponent,
    StudentListComponent,
    AcademicYearsComponent,
    AcademicYearsEditComponent,
    AcademicYearModalComponent,
    sectionComponent,
    SectionModalComponent,
    classComponent,
    ClassModalComponent,
    mediumComponent,
    MediumModalComponent,
    subjectsComponent,
    SubjectModalComponent,
    assignComponent,
    AssignsubjectModalComponent,
    assignTeacherComponent,
    AssignteacherModalComponent,
    departmentComponent,    
    designationComponent,
    DepartmentModalComponent,
    DesignationModalComponent,
    leaveComponent,
    LeaveModalComponent,
    hostelDetailsComponent,
    HostelDetailsmodalComponent,
    hostelRoomsComponent,
    HostelRoomsmodalComponent,
    hostelRoomTypeComponent,
    HostelRoomTypeModalComponent,
    vehicleComponent,
    VehiclemodalComponent,
    driverComponent,
    DrivermodalComponent,
    vehicleTypeComponent,
    VehicleTypeModalComponent,
    routeComponent,
    RoutemodalComponent,
    destinationComponent,
    DestinationModalComponent,
    transportAllocationComponent,
    TransportAllocationmodalComponent,
    addStudentComponent,
    editStudentComponent,
    addStaffComponent,
    StaffListComponent,
    editStaffComponent,
    payHeadComponent,
    PayHeadModalComponent,
    feesTypeComponent,
    FeesTypemodalComponent,
    feesCollectionComponent,
    feesMasterComponent,
    FeesMastermodalComponent,
    feeGroupComponent,
    FeeGroupmodalModalComponent,
    feesDiscountComponent,
    FeesDiscountmodalComponent,
    bookCategoryComponent,
    BookCategorymodalComponent,
    addbookComponent,
    AddbookmodalComponent,
    addMemberComponent,
    AddMemberModalComponent,
    issueBookComponent,
    applyLeaveComponent,
    ApplyLeavemodalModalComponent,
    approveLeaveComponent,
    AddLeavemodalModalComponent,
    ViewStudentComponent,
    InventoryItemComponent,
    InventoryCategoryComponent,
    InventoryIssueComponent,
    InventoryPurchaseComponent,
    InventoryCategoryModalComponent,
    InventoryIssueModalComponent,
    InventoryItemModalComponent,
    InventoryPurchaseModalComponent,
    ViewStaffComponent,
    VendorComponent,
    AddVendorsComponent,
    EditVendorsComponent,
    AddTaskComponent,
    TaskListComponent,
    EditTaskComponent,
    BirthdayComponent,
    bulkImportComponent,
    StaffbulkImportComponent,
    StudentAttendanceComponent,
    StaffAttendanceComponent,
    CertificateTemplateComponent,
    ExamComponent,
    ExamModalComponent,
    MarksGradeComponent,
    MarksGradeModalComponent,
    IncomeHeadComponent,
    IncomeHeadModalComponent,
    CertificateListComponent,
    EditCertificateComponent,
    IncomeComponent,
    IncomeModalComponent,
    ExpenseHeadComponent,
    ExpenseHeadModalComponent,
    ExpenseComponent,
    ExpenseModalComponent,
    IdCardTemplateComponent,
    StaffIdCardTemplateComponent,
    EditIdCardComponent,
    HomeworkComponent,
    EditHomeworkComponent,
    HomeworkListComponent,
    AssignmentModalComponent,
    PageListComponent,
    PagesComponent,
    EditPageComponent,
    SelectModalComponent,
    MediaComponent,
    MediaModalComponent,
    HolidayComponent,
    HolidayModalComponent,
    ClassTimeTableComponent,
    CreateTimeTableComponent,
    teachertimetableComponent,
    CollectFeeComponent,
    PayModalComponent,
    FeeReminderComponent,
    DueFeeComponent,
    SearchFeePayComponent,
    EventComponent,
    EventModalComponent,
    CircularComponent,
    CircularListComponent,
    EditCircularComponent,
    CircularmodalComponent,
    AcademicyearsModalComponent,
    modulesAccessComponent,
    ExamTimeTableComponent,
    ExamScheduleComponent,
    MarkRegisterComponent,
    ExamResultComponent,
    printReceiptModalComponent,
    GenerateComponent,
    GeneratePageComponent,
    EditGeneratePageComponent,
    PaymentModalComponent,
    PrintPaymodalModalComponent,
    studentReportComponent,
    financeReportComponent,
    dailyCollectionModalComponent,
    AttendanceReportComponent,
    SettingsComponent,
    StaffReportComponent,
    InventoryReportComponent,
    LibraryReportComponent,
    TransportReportComponent,
    HostelReportsComponent,
    UserLogReportComponent,
    ActivityLogReportComponent,
    ReturnbookmodalModalComponent,
    AddEventComponent,
    AddEventModalComponent,
    PromoteStudentComponent,
    GeneralComponent,
    PrintHeaderFooterComponent,
    MenuComponent,
    MenuModalComponent,
    MenuItemsComponent,
    MenuItemModalComponent,
    OnlineAdmissionSettingsComponent,
    PickUpPointComponent,
    PickUpModalComponent,
    PickUpRouteListComponent,
    AddRouteModalComponent,
    ApplicationsComponent,
    EnrollPageComponent,
    EmailComponent,
    GenerateCertificateComponent,
    CertificateModalComponent,
    ViewCertificatemodalModalComponent,
    GenerateIdComponent,
    IdModalComponent,
    IdListComponent,
    ViewIdModalComponent,
    StaffIdListComponent,
    EditStaffIdCardComponent,
    GenerateStaffIdComponent,
    UserProfileComponent,
    ChangePasswordModalComponent,
    markRegistermodalComponent,
    SyllabusComponent,
    EditSyllabusModalComponent,
    AddSyllabusModalComponent,
    SyllabusStatusComponent,
    SyllabusStatusDateModalComponent,
    MultiplePayModalComponent,
    MessageModalComponent,
    NotificationsComponent,
    IncidentsComponent,
    EditIncidentModal,
    AssignIncidentsComponent,
    AssignIncidentModal,
]