import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { LibraryService } from 'src/app/services/library.service';
  
@Component({
    selector: 'app-addMembermodal',
    templateUrl: './addMembermodal.component.html',
    styleUrls: [],
})

export class AddMemberModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public memberID: number;
    public userType: any;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    constructor(
        private _gService: GlobalService,
        private _aService: LibraryService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        libraryCard_no: ['', Validators.required],
    })

    ngOnInit(): void {
        // this._gService.showLoader(true);

        // this._gService.getDataByID(this.memberID, 'medium').subscribe(dataRes => {
        //     console.log(dataRes);
        //     if (dataRes.status === true) {
        //         let sData = JSON.parse(dataRes.result);
        //         this.rObj.patchValue({
        //             Medium: sData[0].name,
        //         });
        //     }
        //     this._gService.showLoader(false);
        // })
    }

    addMember() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                user_id:this.memberID,
                user_type: this.userType,
                card_no: rObj.libraryCard_no,
            }
            this._aService.addMemberData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate(['library/member/add/'+this.userType])
                        this._gService.triggerSnackbar('Member Added Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}