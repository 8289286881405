import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { StaffService } from 'src/app/services/staff.service';
import { NotificationService } from 'src/app/services/notification.service';
  
@Component({
    selector: 'app-addLeavemodal',
    templateUrl: './addLeavemodal.component.html',
    styleUrls: [],
})

export class AddLeavemodalModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public userrole = this.userData.UserRole;
    public leavesID: any;
    public staffID: any;
    public action: string;
    public imageSrc:any = '';
    public status:boolean = false;
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public imgURL = this._gService.getImgPath();

    
    public leaveData: any[] = [];
    public staffListData: any[] = [];
    public addleaveData: any[] = [];
    public userRoleData: any[] = [];
    public ids: any[] = [];
    
    constructor(
        private _gService: GlobalService,
        private _nService: NotificationService,
        private _aService: StaffService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    private disabledStatus : boolean;
    public rObj : any;
    

    ngOnInit(): void {
        this._gService.showLoader(true);

        this.disabledStatus = this.action == 'add' ? false : true;

        this.rObj = this._fBuilder.group({
            Name: [{value: '', disabled: this.disabledStatus}],
            Reason: [{value: '', disabled: this.disabledStatus}, Validators.required,],
            Apply_date: [{value: '', disabled: this.disabledStatus}, Validators.required],
            Leave_from_date: [{value: '', disabled: this.disabledStatus}, Validators.required],
            Leave_to_date: [{value: '', disabled: this.disabledStatus}, Validators.required],
            Leave_type: [{value: '', disabled: this.disabledStatus},Validators.required],
            Leave_document: [{value: '', disabled: this.disabledStatus}],
            Status: ['pending', Validators.required],
            Note: [''],
            Role: [{value: '', disabled: this.disabledStatus}]
        })
        
        this._gService.getUserRoleData().subscribe(res =>{
            if (res.status === true) {
                this.userRoleData = JSON.parse(res.result)
                console.log(this.userRoleData)
            }
        })
       

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'leave_type').subscribe(lRes => {
            console.log(lRes)
            if (lRes.status === true) {
                this.leaveData = JSON.parse(lRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'staff').subscribe(lRes => {
            console.log(lRes)
            if (lRes.status === true) {
                this.staffListData = JSON.parse(lRes.result);
                console.log(this.staffListData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getDataByID(this.leavesID, 'leaves').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                console.log(sData);
                this.rObj.patchValue({     
                    Role :sData[0].role,
                    Name :sData[0].staff_id,
                    Reason: sData[0].reason,
                    Apply_date: sData[0].apply_date,
                    Leave_from_date: sData[0].leave_from_date,
                    Leave_to_date: sData[0].leave_to_date,
                    Leave_type: sData[0].leave_type,
                    Status: sData[0].status,
                    Note: sData[0].note,
                });
                this.staffID = sData[0].staff_id
                this.imageSrc = sData[0].leave_document ? this.imgURL+sData[0].leave_document : '';
            }
            this._gService.showLoader(false);
        })
    }

    approve() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'approve',
                id: this.leavesID,
                status:rObj.Status,
                note:rObj.Note
            }
            this._aService.addLeaveData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar(rObj.Status == '1' ? 'Leave Approved Successfully' : 'Leave Rejected Successfully', 'success', true)
                        this.ids.push(JSON.parse(this.staffID))
                        if (rObj.Status == '1' || rObj.Status == '0') {
                            let ndata = {
                                inst_id: this.instID,
                                academic_id: this.activeAcademicID,
                                user_role: '3',
                                user_ids: JSON.stringify(this.ids),
                                title: rObj.Status == '1' ? 'Leave Request Approved' : 'Leave Request Rejected',
                                msg: 'optional',
                                link: 'staff/leave/apply/add',
                                screen: 'ApproveLeave'
                            }
                            console.log(ndata)
                            this._nService.sendNotificationData(ndata).subscribe(res => {
                                console.log(res)
                                if (res.status === true) {
                                    this._reRoute.shouldReuseRoute = () => false;
                                    this._router.navigate([this._router.url]);
                                    // this._gService.triggerSnackbar('Notification send Successfully', 'success', true);
                                }
                            }) 
                        }
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }   
    onFileChange(event:any) {
        this.status = false
        const file = event.target.files[0];
        this.status = event.target.files.length > 0 ? true : false;
        if (this.status == true) {
           const reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = () => {
              this.imageSrc = reader.result;          
           }
           console.log(this.imageSrc)
        }
    }

    removeImg() {
        this.imageSrc = '';
    }
       

    saveLeave() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'save',
                inst_id: this.instID,
                academic_id : this.activeAcademicID,
                role : rObj.Role,
                staff_id: this.userID,
                reason: rObj.Reason,
                apply_date: rObj.Apply_date,
                leave_from_date: rObj.Leave_from_date,
                leave_to_date:rObj.Leave_to_date,
                leave_type:rObj.Leave_type,
                status:rObj.Status,
                note:rObj.Note,
                leave_document: this.status ? this.imageSrc : '',
            }
            console.log(mData)
            this._aService.addLeaveData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar(' Leave details Save Successfully', 'success', true) 
                        
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}