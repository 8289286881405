<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Fees Reminder</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-9">
            <!--<a href="../../hostel-detail/add/" class="content-header-link">Add Hostel</a>
            <a href="../../../hostel-rooms/add/" class="content-header-link">Add Hostel Room </a>-->
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel"> 
                <div class="panel-header">
                    <div class="panel-title">
                        Fees Reminder Email
                    </div>
                </div>

                <div class="panel-body">
                    <form [formGroup]="rObj">
                       
                        <div class="form-group">
                            <label>Class <small class="req"> *</small></label>
                            <select formControlName="Class" class="form-select" data-next="getSection" data-type="options" required="">
                                <option value="">Please Select</option>
                                <option *ngFor="let c of classData" value="{{c.ID}}">{{c.name}}</option>                          
                            </select>
                            <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                <p class="input-error-msg">Please enter Class</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Section <small class="req"> *</small></label>
                            <select formControlName="Section" class="form-select" required="" >
                                <option value="">Please Select</option>
                                <ng-container *ngFor="let section of classSectionData" >
                                    <option [value]="section.id">{{section.name}}</option>
                                </ng-container>
                             </select>
                             <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                <p class="input-error-msg">Please enter Section</p>
                             </div>
                        </div>
                        <div class="form-group">
                            <label>Fees Type</label>
                            <small class="req"> *</small>
                            <select formControlName="Fees_type" class="form-select" data-next="getSection" data-type="options" required="">
                                <option value="">Please Select</option>
                                <option *ngFor="let f of feesData" value="{{f.ID}}">{{f.name}}</option>                   
                            </select>
                            <div *ngIf="rObj.controls.Fees_type.invalid && (rObj.controls.Fees_type.dirty || rObj.controls.Fees_type.touched)">
                                <p class="input-error-msg">Please enter Fees type</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Subject</label>
                            <small class="req"> *</small>
                            <select formControlName="Subject" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let s of subjectData" value="{{s.ID}}">{{s.name}}</option>                   
                            </select>
                            <div *ngIf="rObj.controls.Subject.invalid && (rObj.controls.Subject.dirty || rObj.controls.Subject.touched)">
                                <p class="input-error-msg">Please enter Subject</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Reminder Start Before</label>
                            <small class="req"> *</small>
                            <select formControlName="Reminder_start_day" class="form-select start_before" required="">
                                <option value="">Please Select</option>
                                <option value="1"> 1 Day</option>
                                <option value="2"> 2 Days</option>
                                <option value="3"> 3 Days</option>
                                <option value="4"> 4 Days</option>
                                <option value="5"> 5 Days</option>
                                <option value="6"> 6 Days</option>
                                <option value="7"> 7 Days</option>
                                <option value="8"> 8 Days</option>
                                <option value="9"> 9 Days</option>
                                <option value="10"> 10 Days</option>
                                <option value="11"> 11 Days</option>
                                <option value="12"> 12 Days</option>
                                <option value="13"> 13 Days</option>
                                <option value="14"> 14 Days</option>
                                <option value="15"> 15 Days</option>
                                <option value="16"> 16 Days</option>
                                <option value="17"> 17 Days</option>
                                <option value="18"> 18 Days</option>
                                <option value="19"> 19 Days</option>
                                <option value="20"> 20 Days</option>
                                <option value="21"> 21 Days</option>
                                <option value="22"> 22 Days</option>
                                <option value="23"> 23 Days</option>
                                <option value="24"> 24 Days</option>
                                <option value="25"> 25 Days</option>
                                <option value="26"> 26 Days</option>
                                <option value="27"> 27 Days</option>
                                <option value="28"> 28 Days</option>
                                <option value="29"> 29 Days</option>
                                <option value="30"> 30 Days</option>
                            </select>
                            <div *ngIf="rObj.controls.Reminder_start_day.invalid && (rObj.controls.Reminder_start_day.dirty || rObj.controls.Reminder_start_day.touched)">
                                <p class="input-error-msg">Please enter Reminder start day</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Reminder Frequency</label>
                            <small class="req"> *</small>
                            <select formControlName="Reminder_frequency" class="form-select reminder_frequency" required="">
                                <option value="">Please Select</option>
                                <option value="Daily">Daily</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Alternate Days">Alternate Days</option>  
                            </select>
                            <div *ngIf="rObj.controls.Reminder_frequency.invalid && (rObj.controls.Reminder_frequency.dirty || rObj.controls.Reminder_frequency.touched)">
                                <p class="input-error-msg">Please enter Reminder frequency</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Reminder Type</label>
                            <small class="req"> *</small>
                            <div class="radio required">
                                <label class="radio-inline">
                                    <input type="radio" value="email" formControlName="Reminder_type" autocomplete="off">Email
                                </label>
                                <label class="radio-inline">
                                    <input type="radio" value="sms" formControlName="Reminder_type" autocomplete="off">Sms
                                </label>
                                <label class="radio-inline">
                                    <input type="radio" value="both" formControlName="Reminder_type" autocomplete="off">Both
                                </label>
                            </div>
                            <div *ngIf="rObj.controls.Reminder_type.invalid && (rObj.controls.Reminder_type.dirty || rObj.controls.Reminder_type.touched)">
                                <p class="input-error-msg">Please enter Reminder type</p>
                            </div>
                        </div>
                       
                        <div class="form-group">
                            <label>Message</label>
                            <textarea type="text" class="form-control" formControlName="msg" value=""></textarea>
                        </div>
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveReminder()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Fees Reminder List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="feeReminderData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Id</th>
                                <th>Subject</th>
                                <th>Class</th>
                                <th>Section</th>
                                <th>Fees Type</th>
                                <th>Reminder Frequency</th>
                                <th class="start_before">Reminder Start Before</th>
                                <th class="remindertype">Reminder Type</th>
                                <th class="description">Msg</th>
                                <th>Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let r of feeReminderData">
                                <td class="align-left">{{r.ID}}</td>
                                <td>{{r.subject}}</td>
                                <td>{{r.class}}</td>
                                <td>{{r.section}}</td>
                                <td>{{r.fee_type}}</td>
                                <td>{{r.reminder_frequency}}</td>
                                <td>{{r.reminder_start_before}}</td>
                                <td>{{r.reminder_type}}</td>
                                <td><p>{{r.msg}}</p></td>
                                <td class="action-btn">
                                    <a tooltip="Edit" class="edit-btn" (click)="openReminderEditModal(r.ID)"><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(r.ID, 'leaves', 'apply leaves')"><i class="far fa-trash-alt"></i></a>
                                </td>
                               
                            </tr>
                        </tbody>
                    </table>   
                    <div *ngIf="!feeReminderData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>   
                </div>
            </div>
        </div>
    </div>
        
</div>