
import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder, FormControl, FormArray, FormGroup } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { Router, RouteReuseStrategy, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { PayModalComponent } from 'src/app/components/modal/paymodal/paymodal.component';
import { PayrollService } from 'src/app/services/payroll.service';
import { printReceiptModalComponent } from 'src/app/components/modal/printReceiptmodal/printReceiptmodal.component';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
    selector: 'app-payroll-generatePage',
    templateUrl: './generatePage.component.html',

})

export class GeneratePageComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public departmentData: any[] = [];
    public designationData: any[] = [];
    public earningsData: any[] = [];
    public deductionsData: any[] = [];
    public staffData: any[] = [];
    public feesMasterData: any[] = [];
    public statusData: any[] = [];
    public selectedIds: any[] = [];
    public userRoleData: any[] = [];
    public studentId: any;
    public classId: any;
    public sectionId: any;
    public feeGroupId: any;
    public earningHeads: any[] = [];
    public earningAmts: any[] = [];
    public earningPrice: number = 0;
    public deductionHeads: any[] = [];
    public deductionAmts: any[] = [];
    public ids: any[] = [];
    public deductionPrice: number = 0;
    public isDue: boolean = false;
    public imgURL = this._gService.getImgPath();

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    imageSrc: any = '';
    status: boolean = false;
    dtOptions: any = {};
    modalRef: BsModalRef;
    staffId: any;
    monthId: any;
    yearId: any;
    gross: number;
    basic: number;
    tax: number;

    constructor(
        private _gService: GlobalService,
        private _aService: PayrollService,
        private _nService: NotificationService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute,
        private modalService: BsModalService,
    ) { }

    public rObj = this._fBuilder.group({
        Basic_salary: [''],
        Earning: [''],
        Deduction: [''],
        Gross_salary: [''],
        Tax: [''],
        Net_salary: [''],
        eData: this._fBuilder.array([]),
        dData: this._fBuilder.array([]),
    })
    eData(): FormArray {
        return this.rObj.get("eData") as FormArray
    }
    newEData(): FormGroup {
        return this._fBuilder.group({
            Earning_name: ['', Validators.required],
            Earning_price: '',
        })
    }
    addEData() {
        this.eData().push(this.newEData())
    }
    removeEData(i: number) {
        this.eData().removeAt(i);
    }
    removeDData(i: number) {
        this.dData().removeAt(i);
    }

    dData(): FormArray {
        return this.rObj.get("dData") as FormArray
    }

    newDData(): FormGroup {
        return this._fBuilder.group({
            Deduction_name: ['', Validators.required],
            Deduction_price: '',
        })
    }

    addDData() {
        this.dData().push(this.newDData())
    }



    ngOnInit(): void {
        this.staffId = this._aRoute.snapshot.paramMap.get('id');
        this.monthId = this._aRoute.snapshot.paramMap.get('month');
        this.yearId = this._aRoute.snapshot.paramMap.get('year');


        this._gService.getDataByID(this.staffId, 'staff').subscribe(cRes => {
            console.log(cRes)
            this.addDData();
            this.addEData();
            if (cRes.status === true) {
                this.staffData = JSON.parse(cRes.result);
                this._gService.getUserRoleData().subscribe(res => {
                    if (res.status === true) {
                        this.userRoleData = JSON.parse(res.result)
                        console.log(this.userRoleData)
                    }
                })
                this.rObj.patchValue({
                    Basic_salary: this.staffData[0].basic_salary,
                })
                this.basic = parseInt(this.staffData[0].basic_salary)
                console.log(this.staffData)

                // this.classId = this.studentData[0].class;
                // this.sectionId = this.studentData[0].section
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'department').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.departmentData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'payhead').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                let payheadData = JSON.parse(cRes.result);
                this.earningsData = payheadData.filter((p: any) => p.pay_head_type == 'addition')
                this.deductionsData = payheadData.filter((p: any) => p.pay_head_type == 'deduction')
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'designation').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.designationData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    calculatePayroll() {
        let earningData = this.rObj.controls.eData.value;
        let deductionData = this.rObj.controls.dData.value;
        // this.earningPrice = 0
        // this.deductionPrice = 0
        earningData.forEach((e: any) => {
            this.earningHeads.push(e.Earning_name)
            this.earningAmts.push(e.Earning_price)
            console.log(this.earningHeads)
            console.log(this.earningAmts)
            let earningAmtsTotal = this.earningAmts.reduce((a, b) => a + b)
            console.log(earningAmtsTotal)
            console.log(this.earningPrice)
            if (earningAmtsTotal != this.earningPrice) {
                this.earningPrice = earningAmtsTotal
            } else {
                this.earningPrice = this.earningPrice
            }
            // this.earningPrice = this.earningAmts.reduce((a,b) => a + b)
            console.log(this.earningPrice)
        });
        deductionData.forEach((e: any) => {
            this.deductionHeads.push(e.Deduction_name)
            this.deductionAmts.push(e.Deduction_price)
            console.log(this.deductionHeads)
            console.log(this.deductionAmts)
            this.deductionPrice = this.deductionAmts.reduce((x, y) => x + y)
            console.log(this.deductionPrice)
        })

        this.gross = (this.basic + this.earningPrice) - this.deductionPrice
        console.log(this.gross)

        if (this.rObj.value.Tax) {
            this.calculateTax();
        }

    }

    calculateTax() {
        let t: any = this.rObj.value.Tax;
        this.tax = this.gross - t;
        console.log(this.tax)
    }

    checkUncheckAll(evt: Event) {
        this.selectedIds.splice(0, this.selectedIds.length);
        this.feesMasterData.forEach((s) => {
            s.isSelected = (<HTMLInputElement>evt.target).checked
            if ((<HTMLInputElement>evt.target).checked) {
                this.selectedIds.push(s.ID);
            } else {
                let index = this.selectedIds.indexOf(s.ID);
                this.selectedIds.splice(index, 1);
            }
        })
        console.log(this.selectedIds)
    }

    onChecked(rowId: number, e: Event) {
        if ((<HTMLInputElement>e.target).checked) {
            this.selectedIds.push(rowId);
        } else {
            let index = this.selectedIds.indexOf(rowId);
            this.selectedIds.splice(index, 1);
        }
        console.log(this.selectedIds)
        if (this.feesMasterData.length != this.selectedIds.length) {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = false
        } else {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = true
        }
    }

    delMultiData(moduleName: string, moduleTitle: string) {
        this._gService.openDeleteMultiModal(this.selectedIds, this.selectedIds.length, moduleName, moduleTitle)
    }

    getUserRoleName(roleID: any) {
        return this.userRoleData.filter(r => r.ID == roleID).map(r => r.name)
    }

    dueCheck(date: any) {
        let now = new Date()
        let today = moment(now).format('YYYY-MM-DD')
        if (today > date) {
            this.isDue = true

        } else {
            this.isDue = false
        }
    }
    totalAmount(amt: any, f_amt: any) {
        if (this.isDue == true) {
            return parseInt(amt) + parseInt(f_amt);
        }
    }

    getTxnId(feesId: number) {
        return this.statusData.filter(s => s.fees_master_id == feesId).map(s => s.txn_id)
    }
    save() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                staff_id: this.staffId,
                month: this.monthId,
                year: this.yearId,
                basic: rObj.Basic_salary,
                earning: this.earningPrice,
                earning_heads: JSON.stringify(this.earningHeads),
                earning_amts: JSON.stringify(this.earningAmts),
                deduction: this.deductionPrice,
                deduction_heads: JSON.stringify(this.deductionHeads),
                deduction_amts: JSON.stringify(this.deductionAmts),
                gross: this.gross,
                tax: rObj.Tax,
                net: this.tax,
            }


            console.log(mData)
            this._aService.payrollData(mData).subscribe(phRes => {
                console.log(phRes)
                if (phRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate(['/payroll/list']);
                    // this._router.navigate(['/payroll/list/'+this.department+'/'+this.designation+'/'+this.monthId+'/'+this.yearId])
                    this._gService.triggerSnackbar('Payroll Saved Successfully', 'success', true);
                    this.ids.push(this.staffData[0].user_id)
                    console.log(this.ids.toString())
                    let ndata = {
                        inst_id: this.instID,
                        academic_id: this.activeAcademicID,
                        user_role: '3',
                        user_ids: JSON.stringify(this.ids),
                        title: 'Payroll Generated',
                        msg: 'optional',
                        link: '',
                        screen: ''
                    }
                    console.log(ndata)
                    this._nService.sendNotificationData(ndata).subscribe(res => {
                        console.log(res)
                        if (res.status === true) {
                            this._reRoute.shouldReuseRoute = () => false;
                            this._router.navigate([this._router.url]);
                            this._gService.triggerSnackbar('Notification send Successfully', 'success', true);
                        }
                    })
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}