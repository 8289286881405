import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, CheckboxControlValueAccessor } from '@angular/forms';
import { PayrollService } from 'src/app/services/payroll.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FeesService } from 'src/app/services/fees.service';
import { FeesTypemodalComponent } from 'src/app/components/modal/feesTypemodal/feesTypemodal.component';
import { FeesMastermodalComponent } from 'src/app/components/modal/feesMastermodal/feesMastermodal.component';


@Component({
  selector: 'app-fees-feesMaster',
  templateUrl: './feesMaster.component.html',
})

export class feesMasterComponent implements OnInit {
// stringToArray(arg0: any): any {
// throw new Error('Method not implemented.');
// }
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public isVernacular = this.userData.isVernacular;
    public activeAcademicID = this.userData.AcademicID;
    public mediumID = this.userData.activeMedium;

    public feesMasterData: any[] = [];
    public feesGroupData: any[] = [];
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public feesTypeData: any[] = [];
    public mediumData: any[] = [];
    isChecked: boolean = true;
    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: FeesService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Class: ['', Validators.required],
        Section: ['',Validators.required],
        Fees_group: ['',Validators.required],
        Fees_type: ['',Validators.required],
        Amount: ['',Validators.required],
    
    })

    ngOnInit(): void {

        this._gService.showLoader(true);
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.mediumData = JSON.parse(mRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.classData = JSON.parse(pRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.sectionData = JSON.parse(pRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'fees_type').subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.feesTypeData = JSON.parse(pRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.mediumData = JSON.parse(pRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'fees_master').subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.feesMasterData = JSON.parse(pRes.result);
                console.log(this.feesMasterData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'fees_group').subscribe(pRes => {
            console.log(pRes)
            if (pRes.status === true) {
                this.feesGroupData = JSON.parse(pRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
        this._gService.showLoader(true);
        this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
            // console.log(dataRes)
            if(dataRes.status === true) {
                let result = JSON.parse(dataRes.result)
                // console.log(result)
                let sectionsArray = JSON.parse(result[0].sections)
                let sectionNamesArray = result[0].section_names.split(',')
                this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                    return {'id': section_id, 'name': sectionNamesArray[index]}
                })
                // console.log(this.classSectionData)
            }
        })
        this._gService.showLoader(false);
    })

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }
    

    openFeesMasterEditModal(feesMasterID: any) {
        this.modalRef = this.modalService.show(FeesMastermodalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                feesMasterID : feesMasterID,
            } 
        });
    }

    deleteData(dataID: any, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }
    stringToArray(text: string) {
        if(text.indexOf(',') > -1) {
            return text.split(',')
        } else {
            return [text]
        }
        
    }

    saveData() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                class: rObj.Class,
                section: rObj.Section,
                medium: this.mediumID ? this.mediumID :0,
                fees_group :rObj.Fees_group,
                fees_type: rObj.Fees_type,
                amount: rObj.Amount,
                
            }
            console.log(mData)
            this._aService.feesMasterData(mData).subscribe(phRes => {
                console.log(phRes)
                if (phRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Fees Group Details Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}