import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { Router, RouteReuseStrategy, ActivatedRoute } from '@angular/router';
@Component({
    selector: 'app-student-list',
    templateUrl: './list.component.html'
})

export class StudentListComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public studentListData: any[] = [];
    public studentByName: any[] = [];
    public mediumData: any[] = [];
    public classId: any;
    public sectionId: any;
    public mediumId: any;
    public selectedIds: any[] = [];

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    imageSrc: any;
    public imgURL = this._gService.getImgPath();

    dtOptions: any = {};

    constructor(
        private _gService: GlobalService,
        private _aService: StudentService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute,
    ) { }

    public rObj = this._fBuilder.group({
        Class: ['', Validators.required],
        Section: [0],
    })

    public sObj = this._fBuilder.group({
        Input: ['', Validators.required]
    })

    ngOnInit(): void {

        this.classId = this._aRoute.snapshot.paramMap.get('class');
        this.sectionId = this._aRoute.snapshot.paramMap.get('section');
        // alert(this.sectionId)
        if (this.classId && this.sectionId) {
            this._gService.getDataByID(this.classId, 'class').subscribe(dataRes => {
                console.log(dataRes)
                if (dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    console.log(result)
                    let sectionsArray = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    console.log(sectionsArray, sectionNamesArray)

                    this.classSectionData = JSON.parse(result[0].sections)

                    this.classSectionData = sectionsArray.map((section_id: any, index: any) => {
                        return { 'id': section_id, 'name': sectionNamesArray[index] }
                    })
                    console.log(this.classSectionData)
                }
            })
            this.rObj.patchValue({
                Class: this.classId,
                Section: this.sectionId,
            })
            this.search()
        }

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.mediumData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })


        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {
                // console.log(dataRes)
                if (dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    // console.log(result)
                    let sectionsArray = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    this.classSectionData = sectionsArray.map((section_id: any, index: any) => {
                        return { 'id': section_id, 'name': sectionNamesArray[index] }
                    })
                    // console.log(this.classSectionData)
                }
            })
            this._gService.showLoader(false);
        })


        // this.dtOptions = {
        //     pagingType: 'numbers',
        //     order: [],
        //     columnDefs: [{
        //         targets: 'no-sort',
        //         orderable: false,
        //     }],
        //     dom: 'fBrtip',
        //     buttons: [
        //         'copy', 'excel', 'csv', 'pdf', 'print',
        //     ],
        //     language: { search: '', searchPlaceholder: "Search..." },
        //     // select : { 
        //     //     selector : 'td:first-child input[type="checkbox"]'
        //     // }

        // };

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };
    }

    navigate() {
        if (this.rObj.valid) {
            let robj = this.rObj.value;
            // alert('yes1')
            this._router.navigate(['student/list/' + this.rObj.value.Class + '/' + this.rObj.value.Section]);
        }
    }

    checkUncheckAll(evt: Event) {
        this.selectedIds.splice(0, this.selectedIds.length);
        this.studentListData.forEach((s) => {
            s.isSelected = (<HTMLInputElement>evt.target).checked
            if ((<HTMLInputElement>evt.target).checked) {
                this.selectedIds.push(s.ID);
            } else {
                let index = this.selectedIds.indexOf(s.ID);
                this.selectedIds.splice(index, 1);
            }
        })
        console.log(this.selectedIds)
    }

    onChecked(rowId: number, e: Event) {
        if ((<HTMLInputElement>e.target).checked) {
            this.selectedIds.push(rowId);
        } else {
            let index = this.selectedIds.indexOf(rowId);
            this.selectedIds.splice(index, 1);
        }
        console.log(this.selectedIds)
        if (this.studentListData.length != this.selectedIds.length) {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = false
        } else {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = true
        }
    }

    delMultiData(moduleName: string, moduleTitle: string) {
        this._gService.openDeleteMultiModal(this.selectedIds, this.selectedIds.length, moduleName, moduleTitle)
    }

    gotoViewLink(dataID: number) {
        this._router.navigate(['/student/view/' + dataID])
    }

    gotoEditLink(dataID: number) {
        this._router.navigate(['/student/edit/' + dataID])
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    search() {

        if (this.rObj.valid) {
            let robj = this.rObj.value;
            let sData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                medium: this.mediumId ? this.mediumId : 0,
                class: this.classId,
                section: this.sectionId,
                // section: robj.Section
            }
            console.log(sData)
            this._aService.studentListData(this.instID, this.activeAcademicID, this.classId, this.sectionId).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    // this._router.navigate([this._router.url]);
                    this.studentListData = JSON.parse(sRes.result)
                } else {
                    this.studentListData = [];
                    this._gService.triggerSnackbar('No Student Data Found!', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }

    }
    searchName() {
        if (this.sObj.valid) {
            this._aService.studentByName(this.instID, this.activeAcademicID, this.sObj.value.Input).subscribe(nRes => {
                console.log(nRes)
                if (nRes.status === true) {
                    this.studentListData = JSON.parse(nRes.result)
                } else {
                    this.studentListData = []
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error', 'error', true)
            })
        }
    }

}