import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StaffService } from 'src/app/services/staff.service';
import { PayrollService } from 'src/app/services/payroll.service';
import { Router,RouteReuseStrategy, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { generate } from 'rxjs';
import { PaymentModalComponent } from 'src/app/components/modal/paymentmodal/paymentmodal.component';
import { printReceiptModalComponent } from 'src/app/components/modal/printReceiptmodal/printReceiptmodal.component';
import { PrintPaymodalModalComponent } from 'src/app/components/modal/printPaymodal/printPaymodal.component';
@Component({
  selector: 'app-payroll-generate',
  templateUrl: './generate.component.html'
})

export class GenerateComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public DepartmentData: any[] = [];
    public DesignationData: any[] = [];
    public staffData: any[] = [];
    public staffPayrollData: any[] = [];
    public payrollData : any[] = [];
    public departmentId: any;
    public designationId: any;
    public monthId: any;
    public yearId: any;
    public Pageid: any
    public openpanel: boolean = false;
    public payment_amt : number

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public MonthData: any[] = [{month:'1', monthName:'January'}, {month:'2', monthName:'February'}, {month:'3', monthName:'March'}, {month:'4', monthName:'April'}, {month:'5', monthName:'May'}, {month:'6', monthName:'June'}, {month:'7', monthName:'July'}, {month:'8', monthName:'August'}, {month:'9', monthName:'September'}, {month:'10', monthName:'October'}, {month:'11', monthName:'November'}, {month:'12', monthName:'December'}];

    dtOptions: any = {};
    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: StaffService,
        private _pService: PayrollService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _aRoute: ActivatedRoute,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Department: ['', Validators.required], 
        Designation: [0],
        Month: [''],
        Year: [''],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this.departmentId = this._aRoute.snapshot.paramMap.get('dept');
        this.designationId = this._aRoute.snapshot.paramMap.get('des');
        this.monthId = this._aRoute.snapshot.paramMap.get('month');
        this.yearId = this._aRoute.snapshot.paramMap.get('year');

        if(this.departmentId && this.designationId && this.monthId && this.yearId) {
            this._gService.getDataByID(this.designationId, 'des').subscribe(dataRes => {  
                console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    let designationData = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    this.DesignationData = designationData.map((section_id : any, index : any) => {
                        return {'id': section_id, 'name': sectionNamesArray[index]}
                    })
                }
            })
            this.rObj.patchValue({
                Department : this.departmentId,
                Designation : this.designationId,
                Month :this.monthId,
                Year :this.yearId
            })
            this._gService.showLoader(false);

            this.search()        
        }

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'department').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.DepartmentData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'designation').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.DesignationData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'payroll').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.payrollData = JSON.parse(cRes.result);
                console.log(this.payrollData)
                this.payment_amt = this.payrollData[0].net;
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

        this._gService.showLoader(false);

    }

    openPayModal(payId: any, staff_name: string, month: number, year: number, payment_amt: number, department: any, designation: any) {
        this.modalRef = this.modalService.show(PaymentModalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                department: department,
                designation: designation,
                payId:(this.getPayId(payId)),
                staff_name: staff_name,
                month: month,
                year : year,
                payment_amt : payment_amt
            } 
        });
    }
// this.MonthData[this.month].monthName+'-'+this.year
    openPaymentReceiptModal(payId:any,staff_name: string, month: any, year:any, departmentId: any, designationId: any){
        this.modalRef = this.modalService.show(PrintPaymodalModalComponent, {
            class: 'modal-dialog-centered payreceipt-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                payId:(this.getPayId(payId)),
                staff_name: staff_name,
                month: month,
                year : year,
                department : departmentId,
                designation : designationId,
            } 
        });
    }

    getPayrollStatus(staff_id: number){
        let data = this.staffPayrollData.filter(r => r.staff_id == staff_id).map(r => ({'generated' : r.is_generated, 'paid' : r.paid}))
        if (data.length) {
            return data[0].paid == 1 ? 'Paid' : data[0].generated == 1 ? 'Generated' : 'Not Generated';
        }else {
            return;
        }
    }

    getPayId(staff_id : number) {
        let id =this.staffPayrollData.filter(r => r.staff_id == staff_id).map(r => r.ID)
        // alert(id)
        return id;
    }

    goToGeneratePage(staff_id: any){
        this._router.navigate(['/payroll/generatePage/'+staff_id+'/'+this.monthId+'/'+this.yearId])
    }

    openEditPage(generatedID: number){
        let pageId:any = this.payrollData.filter( r => r.staff_id == generatedID).map(r => r.ID)
        console.log(pageId)
        // this.Pageid = 
        this._router.navigate(['/payroll/editgeneratePage/'+pageId])
    }
    RevertPage(payId : any, moduleName: string, moduleTitle: string){
        let pId:any = this.payrollData.filter( r => r.staff_id == payId).map(r => r.ID)
        console.log(pId.toString())
        // this._pService.revertPayData(pId).subscribe(res => {
        //     console.log(res)
        //     alert('reverted')
        // })
        this._gService.openRevertModal(pId.toString(), moduleName, moduleTitle)
    }

    deleteData(dataID: any, moduleName: string, moduleTitle: string) {
        let delID : any = this.payrollData.filter(r => dataID == r.staff_id).map(r => r.ID)
        this._gService.openDeleteModal(delID, moduleName, moduleTitle)
        console.log(delID)
    }

    navigate() {
        if(this.rObj.valid) {
            let robj = this.rObj.value;
            // alert('yes1')
            this._router.navigate(['/payroll/list/'+robj.Department+'/'+robj.Designation+'/'+robj.Month+'/'+robj.Year]);
        }
    }

    search() {
        if(this.rObj.valid) {
            let robj = this.rObj.value;
            // this._router.navigate(['/payroll/list/'+robj.Department+'/'+robj.Designation+'/'+robj.Month+'/'+robj.Year]);
            this._aService.staffListData(this.instID, this.activeAcademicID, this.rObj.value.Department, this.rObj.value.Designation).subscribe(sRes => {
                console.log(sRes)  
                if (sRes.status === true) {  
                    this.staffData = JSON.parse(sRes.result)
                    console.log(this.staffData)
                    this._pService.getStaffpayrollData(this.instID, this.activeAcademicID, this.rObj.value.Month, this.rObj.value.Year).subscribe(cRes => {
                        if (cRes.status === true) { 
                            this._reRoute.shouldReuseRoute = () => false;   
                            this.staffPayrollData = JSON.parse(cRes.result);
                            console.log(this.staffPayrollData)
                        }
                    })
                } else {
                    this.staffData = [];
                    this._gService.triggerSnackbar('No Staff Data Found!', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }   
    }
}