import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder } from '@angular/forms';
import { AcademicService } from 'src/app/services/academic.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MediumModalComponent } from 'src/app/components/modal/mediummodal/mediummodal.component';
import { EventService } from 'src/app/services/event.service';
import { EventModalComponent } from 'src/app/components/modal/eventmodal/eventmodal.component';
import { AddEventModalComponent } from 'src/app/components/modal/addEventmodal/addEventmodal.component';
import * as moment from 'moment';
@Component({
  selector: 'app-event-addType',
  templateUrl: './addEvent.component.html',
})

export class AddEventComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public departmentData: any[] = [];
    public designationData: any[] = [];
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public eventData: any[] = [];
    public eventsData: any[] = [];

    dtOptions: any = {};

    modalRef: BsModalRef;
    invalidDate: boolean = false;

    constructor(
        private _gService: GlobalService,
        private _aService: EventService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
       Event: ['', Validators.required],
       EventType: ['',Validators.required],
       UserType: ['',Validators.required],
       Class: [''],
       Section: [''],
       Department:[''],
       Designation:[''],
       Start_date:['',Validators.required],
       End_date:['',Validators.required],
       Description:['']
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.classData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.sectionData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'department').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.departmentData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'designation').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.designationData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'event_type').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.eventData = JSON.parse(mRes.result);
                console.log(this.eventData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'events').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.eventsData = JSON.parse(mRes.result);
                console.log(this.eventsData)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
                console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    this.classSectionData = JSON.parse(result[0].sections)
                    console.log(this.classSectionData)
                }
            })
            this._gService.showLoader(false);
        })
       


        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    openEventEditModal(eventID: number) {
        this.modalRef = this.modalService.show(AddEventModalComponent, {
            class: 'modal-dialog-centered medium-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                eventID: eventID,
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    getDate(date : any) {
        return moment(date).format('DD-MMMM-YYYY')
    }

    onDateChange() {
        const start_date = new Date(this.rObj.get('Start_date')?.value).getTime();
        const end_date = new Date(this.rObj.get('End_date')?.value).getTime();
        console.log(start_date, end_date)
        if (start_date > end_date) {
          this.invalidDate = true;
        } else {
          this.invalidDate = false;
        }
        console.log(this.invalidDate)
    }   


    saveEvent() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                event_type_id: rObj.EventType,
                event_name: rObj.Event,
                user_type: rObj.UserType,
                class_id: rObj.Class,
                section_id: rObj.Section,
                department_id: rObj.Department,
                designation_id: rObj.Designation,
                description: rObj.Description,
                start_date: rObj.Start_date,
                end_date: rObj.End_date
            }
            console.log(mData)
            this._aService.addEventData(mData).subscribe(eRes => {
                console.log(eRes)
                if (eRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Event Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}