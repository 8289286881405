import { Component, OnInit } from '@angular/core';
import { Route, Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TransportService } from 'src/app/services/transport.service';
import { VehiclemodalComponent } from 'src/app/components/modal/vehiclemodal/vehiclemodal.component';
import { RoutemodalComponent } from 'src/app/components/modal/routemodal/routemodal.component';
import { TransportAllocationmodalComponent } from 'src/app/components/modal/transportAllocationmodal/transportAllocationmodal.component';

@Component({
    selector: 'app-transport-transportAllocation',
    templateUrl: './transportAllocation.component.html',
    styles: [
        '.section-list label {width: 100%; margin: 5px 0 !important; cursor: pointer;}', 
        '.section-list label input {margin-right: 5px;}',
        '.td-section-list {margin-bottom: 5px;}'
    ]
})

export class transportAllocationComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;

    public vehicleData: any[] = [];
    public routeData: any[] = [];
    public allocationData: any[] = [];

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: TransportService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Vehicle_no: ['', Validators.required],
        Route_name: ['', Validators.required],    
    })

    ngOnInit(): void {

        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'vehicle').subscribe(vRes => {
            console.log(vRes)
            if (vRes.status === true) {
                this.vehicleData = JSON.parse(vRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'route').subscribe(rRes => {
            console.log(rRes)
            if (rRes.status === true) {
                this.routeData = JSON.parse(rRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'transport_allocation').subscribe(tRes => {
            console.log(tRes)
            if (tRes.status === true) {
                this.allocationData = JSON.parse(tRes.result);
            } else {
                
            }
        })

        this._gService.showLoader(false);

        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };

    }

    stringToArray(data: any) {
        return JSON.parse(data);
    }

    openAllocationEditModal(trackingID: number ) {
        this.modalRef = this.modalService.show(TransportAllocationmodalComponent, {
            class: 'modal-dialog-centered route-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                transportAllocationID: trackingID
            } 
        });
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    save() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let ayData = {
                action: 'save',
                inst_id: this.instID,
                academic_id: this.activeAcademicID,
                vehicle_no: rObj.Vehicle_no,
                route: rObj.Route_name,
                
            }
            this._aService.allocationData(ayData).subscribe(rRes => {
                console.log(rRes)
                if (rRes.status === true) {
                    this._reRoute.shouldReuseRoute = () => false;
                    this._router.navigate([this._router.url]);
                    this._gService.triggerSnackbar('Transport Allocation details Saved Successfully', 'success', true);
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }

}