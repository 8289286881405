import { Component } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, FormBuilder } from '@angular/forms';
import { StaffService } from 'src/app/services/staff.service';
import { StudentService } from 'src/app/services/student.service';
import { VendorService } from 'src/app/services/vendor.service';  
@Component({
    selector: 'app-inventoryIssuemodal',
    templateUrl: './inventoryIssuemodal.component.html',
    styleUrls: [],
})

export class InventoryIssueModalComponent {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userID = this.userData.UserID;
    public issueID: number;

    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;
    public staffData: any[] = [];
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public studentListData: any[] = [];
    public deparmentData: any[] = [];
    public designationData:any[] = [];
    public staffListData: any[] = [];
    public inventoryIssueData: any[] = [];
    public inventoryItemData: any[] = [];
    public studentData: any[] = [];
    public issuetoData: any[] = [];
    public mediumData: any[] = [];

    invalidDate: boolean = false;
    
    constructor(
        private _gService: GlobalService,
        private _aService: VendorService,
        private _sService: StaffService,
        private _stService: StudentService,
        private _fBuilder: FormBuilder,
        public modalRef: BsModalRef,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,

    ) {}

    public rObj = this._fBuilder.group({
        User_type: ['', Validators.required],
        Class: [''],
        Section: [''],
        Department: [''],
        Designation:[''],
        Issue_to: ['', Validators.required],
        Issue_by: ['', Validators.required],
        Date: ['', Validators.required],
        Return_date: ['', Validators.required],
        Item_name: ['', Validators.required],
        Qty: ['', Validators.required],
        Note: [''],
        Medium: [''],
    })

    ngOnInit(): void {
        this._gService.showLoader(true);

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.mediumData = JSON.parse(mRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'inventory_item').subscribe(mRes => {
            console.log(mRes)
            if (mRes.status === true) {
                this.inventoryItemData = JSON.parse(mRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
            this._gService.showLoader(false);
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'department').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.deparmentData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'designation').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.designationData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })      

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'staff').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.staffData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        }) 

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'students').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.studentData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        }) 

       
        this.rObj.controls['Section'].valueChanges.subscribe((value : any) =>{
            this._gService.showLoader(true);
            this._stService.studentListData(this.instID, this.activeAcademicID, this.rObj.value.Class, value).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
                    this.studentListData = JSON.parse(sRes.result)
                } else {
                    this.studentListData = [];
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
            this._gService.showLoader(false);
        })

        this.rObj.controls['Designation'].valueChanges.subscribe((value : any)=>{
            this._gService.showLoader(true); 
            this._sService.staffListData(this.instID, this.activeAcademicID, this.rObj.value.Department, value).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
                    this.staffListData = JSON.parse(sRes.result)
                } else {
                    this.staffListData = [];
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
            this._gService.showLoader(false); 
        })

        

        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
            this._gService.showLoader(true);
            this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
                console.log(dataRes)
                if(dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    this.classSectionData = JSON.parse(result[0].sections)
                    console.log(this.classSectionData)
                }
            })
            this._gService.showLoader(false);
        })

        this._gService.getDataByID(this.issueID, 'inventory_issue').subscribe(dataRes => {
            console.log(dataRes);
            if (dataRes.status === true) {
                let sData = JSON.parse(dataRes.result);
                if (sData[0].user_type == 'staff') {
                    this._sService.staffListData(this.instID, this.activeAcademicID, this.rObj.value.Department,this.rObj.value.Designation).subscribe(sRes => {
                        console.log(sRes)
                        if (sRes.status === true) {
                            this.staffListData = JSON.parse(sRes.result)
                        }
                    }, err => {
                        console.log(err);
                        this._gService.triggerSnackbar('Server Error!', 'error', true);
                    })
                } else {
                    this._stService.studentListData(this.instID, this.activeAcademicID, sData[0].class, sData[0].section).subscribe(sRes => {
                        console.log(sRes)
                        if (sRes.status === true) {
                            this.studentListData = JSON.parse(sRes.result)
                            console.log(this.studentListData)
                        }
                    }, err => {
                        console.log(err);
                        this._gService.triggerSnackbar('Server Error!', 'error', true);
                    })
                }
                this.rObj.patchValue({
                    User_type: sData[0].user_type,
                    Class: sData[0].class,
                    Section: sData[0].section,
                    Department: sData[0].department,
                    Designation: sData[0].designation, 
                    Issue_to: sData[0].issue_to,
                    Issue_by: sData[0].issue_by,
                    Date: sData[0].date,
                    Return_date: sData[0].return_date,
                    Item_name: sData[0].item_id,
                    Qty: sData[0].qty,
                    Note: sData[0].note,
                   
                });
            }
            this._gService.showLoader(false);
        })
    }

    onDateChange() {
        const date = new Date(this.rObj.get('Date')?.value).getTime();
        const return_date = new Date(this.rObj.get('Return_date')?.value).getTime();
        console.log(date, return_date)
        if (date > return_date) {
          this.invalidDate = true;
        } else {
          this.invalidDate = false;
        }
        console.log(this.invalidDate)
    }   


    updateIssue() {
        if (this.rObj.valid) {
            let rObj = this.rObj.value;
            let mData = {
                action: 'update',
                id: this.issueID,
                user_type: rObj.User_type,
                class: rObj.Class,
                section: rObj.Section,
                department: rObj.Department,
                designation: rObj.Designation,
                issue_to: rObj.Issue_to,
                issue_by: rObj.Issue_by,
                item_id: rObj.Item_name,
                qty: rObj.Qty,
                date: rObj.Date,
                note: rObj.Note,
                return_date: rObj.Return_date,
        
            }
            console.log(mData);
            this._aService.inventoryIssueData(mData).subscribe(mRes => {
                console.log(mRes)
                this.modalRef.hide();
                if (mRes.status === true) {
                    this.modalRef.onHidden?.subscribe(() => {
                        this._reRoute.shouldReuseRoute = () => false;
                        this._router.navigate([this._router.url])
                        this._gService.triggerSnackbar('Inventory Issue Updated Successfully', 'success', true) 
                    });
                } else {
                    this._gService.triggerSnackbar('Something went wrong', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }
    }
    
}