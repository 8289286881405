<div class="console-content">
        
    <div class="console-content-header row">
        <h1 class="title col-md-3">Incidents</h1>
        <div class="header-msg col-md-6">
                      </div>
        <div class="link-container col-md-3">
            <a href="/behaviour/assign-incident" class="content-header-link">Assign Incident</a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-5">
            <div class="panel">
                <div class="panel-header">
                    <div class="panel-title">
                        Add Incident
                    </div>
                </div>
                <div class="panel-body">
                    <form [formGroup]="rObj">
                        <div class="form-group">
                            <label>Title</label>
                            <small class="req"> *</small>
                            <input type="text" class="form-control" formControlName="Title">
                            <div *ngIf="rObj.controls.Title.invalid && (rObj.controls.Title.dirty || rObj.controls.Title.touched)">
                                <p class="input-error-msg">Please enter title</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Description</label>
                            <small class="req"> *</small>
                            <textarea type="text" class="form-control" formControlName="Description"></textarea>
                            <div *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                                <p class="input-error-msg">Please enter description</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="points">Points</label>
                            <small class="req"> *</small>
                            <input id="points" class="form-control" type="number" formControlName="Points" min="0" required>
                            <div *ngIf="rObj.controls.Points.invalid && (rObj.controls.Points.dirty || rObj.controls.Points.touched)" class="text-danger">
                            <p class="input-error-msg">Points are required and cannot be a negative number.</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="negativeIncident">
                                <input id="negativeIncident" type="checkbox" formControlName="isNegative">
                                Is this a negative incident?
                            </label>
                        </div>                                                
                        <div class="form-btn-wrapper">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="saveIncident()">Save</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="panel">
                <div class="panel-header">
                    <div id="panel_title" class="panel-title">
                        Incident List
                    </div>
                </div>
                <div class="panel-body">
                    <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="incidentsData.length">
                        <thead>
                            <tr>
                                <th class="align-left">Title</th>
                                <th>Description</th>
                                <th>Points</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let incident of incidentsData.slice().reverse()">
                            <td class="align-left">{{incident.title}}</td>
                            <td>{{incident.description}}</td>
                            <td>{{incident.is_negative == 1 ? '-' + incident.point : incident.point }}</td>
                            <td class="action-btn">
                                <a tooltip="Edit" class="edit-btn" (click)="openIncidentEditModal(incident.ID)"><i class="far fa-edit"></i></a>
                                <a tooltip="Delete" class="del-btn" (click)="deleteData(incident.ID, 'incidents','incident')"><i class="far fa-trash-alt"></i></a>
                            </td>
                        </tr>
                        </tbody>
                    </table> 
                    <div *ngIf="!incidentsData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>   
                </div>
            </div>
        </div>
    </div>
        
</div>