<div class="modal-header" #FeeReceiptWrapper1>
    <h5 class="modal-title">Fee Receipt</h5>
    <button type="submit" class="btn primary-btn small-btn" style="margin-right: 0px !important;" (click)="print()">Print</button>
    <button type="button" class="btn-close" style="margin-left: 20px;" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper" #FeeReceiptWrapper>
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=Freeman&family=Poetsen+One&display=swap" rel="stylesheet">
            <title>Document</title>
        </head>
        <body>
            <div class="main" id="FeeReceiptWrapper"  *ngFor="let s of feeCollectData">
                <div class="headers">
                    <div class="head">
                    <img class="head" [src]="imgURL+FeeHeader">
                       
                    </div>
                    <!-- <div class="address">
                        <h4 id="address" data-name="Above Bank of Baroda, Bhagyalata Bus Stop, Mayasagar, R.R Dist, Hyderabad - 5012540">Above Bank of Baroda, Bhagyalata Bus Stop, Mayasagar, R.R Dist, Hyderabad - 5012540</h4>
                    </div> -->
                </div>
                <div class="center">
                    <div class="text4 freeman-regular"><h1>FEE RECEIPT</h1></div>
                    <div class="info">
                        <ng-container>
                            <h3>No.</h3>:<h4>{{s.txn_id}}</h4>
                        </ng-container>
                        <h3 style="margin-left: 30%;">Date</h3>:<h4>{{s.payment_date}}</h4>
                    </div>
                    <div class="details">
                        <div class="text5">
                            <h3>Name</h3>
                            <div class="dotLine1">
                                <h3 id="student_name" data-name="">{{s.student_name}}</h3>
                            </div>
                        </div>
                        <div class="class">
                            <div class="text6">
                                <h3>Class</h3>
                                <div class="dotLine2">
                                    <h4 id="student_class_section" data-name="">
                                        {{s.class_name}} / {{s.section_name}}
                                    </h4>
                                </div>
                            </div>
                            <div class="text7">
                                <h3>Term / Month</h3>
                                <div class="dotLine2">
                                    <h4 id="term_month" data-name=""> 
                                        {{s.fees_group_name}}
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <table>
                            <thead >   
                                <tr class="line">   
                                    <th>Particulars</th>
                                    <th colspan="2">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- <tr *ngFor="let ftName of feeTypeNames;let i = index">
                                    <td >{{ftName}}</td>
                                    <td>{{feeTypeAmts[i]}}</td>
                                </tr> -->
                                <tr>
                                    <td>
                                        <ng-container *ngFor="let type_name of stringToArray(s.fees_type_names); let i = index">
                                        <!-- <ng-container *ngFor="let amt of stringToArray(s.amounts); let j = index">
                                            <ng-container *ngIf="i == j"> -->
                                             {{type_name}}<br>
                                            <!-- </ng-container>
                                        </ng-container> -->
                                        </ng-container>
                                    </td>
                                    <td>
                                        <ng-container *ngFor="let amt of stringToArray(s.amounts); let j = index">
                                            <ng-container >
                                             {{amt}}<br>
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                </tr>
                                <!-- <tr>
                                    <td>2.Exam Fee</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>3.Special Fee</td>
                                    <td></td>
                                </tr> -->
                                <tr class="line">
                                    <td>Fine Amount</td>
                                    <td>{{s.fine_amount}}</td>
                                </tr>
                                <tr class="line" *ngIf="s.discount_amount">
                                    <td>Sub Amount</td>
                                    <td>{{stringToInt(s.total_amount) + stringToInt(s.fine_amount)}}</td>
                                </tr>
                                <tr class="line">
                                    <td>Discount Amount</td>
                                    <td>{{s.discount_amount}}</td>
                                </tr>
                                <tr class="line">
                                    <td>Total</td>
                                    <td>{{stringToInt(s.total_amount) + stringToInt(s.fine_amount) - stringToInt(s.discount_amount)}}</td>
                                </tr>
                            </tbody>
                            
                        </table>
                        <div class="text8"> Please pay fee before 10th of every month</div>
                        <div class="sign">
                            <h4>{{FeeFooter}}</h4>
                        </div>
                    </div>
                </div>
            </div>
            
        </body>
        </html>
    </div>
</div>
