import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StudentService } from 'src/app/services/student.service';
import { StaffService } from 'src/app/services/staff.service';
import { Router,RouteReuseStrategy, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddMemberModalComponent } from 'src/app/components/modal/addMembermodal/addMembermodal.component';
@Component({
  selector: 'app-library-addMember',
  templateUrl: './addMember.component.html'
})

export class addMemberComponent implements OnInit {
    public userData = JSON.parse(localStorage.getItem('currentUser')!);
    public userName = this.userData.Name;
    public classData: any[] = [];
    public sectionData: any[] = [];
    public classSectionData: any[] = [];
    public studentListData: any[] = [];
    public studentMembers: any[] = [];
    public deparmentData: any[] = [];
    public designationData:any[] = [];
    public staffListData: any[] = [];
    public staffMembers: any[] = [];
    public memberData: any[] = [];
    public selectedIds : any[] = [];
    public mediumData : any[] = [];
    public usertype : any
    public instID = this.userData.InstID;
    public activeAcademicID = this.userData.AcademicID;
    public isVernacular = this.userData.isVernacular;

    public userType : any;
    public classId : any;
    public sectionId : any;
    public deptId : any;
    public desId : any;

    dtOptions: any = {};

    modalRef: BsModalRef;

    constructor(
        private _gService: GlobalService,
        private _aService: StudentService,
        private _sService: StaffService,
        private _fBuilder: FormBuilder,
        private _router: Router,
        private _reRoute: RouteReuseStrategy,
        private _aRoute: ActivatedRoute,
        private modalService: BsModalService,
    ) {}

    public rObj = this._fBuilder.group({
        Usertype: ['', Validators.required], 
        Class: [''],
        Section: [0],
        Department: [''],
        Designation:[0],
        Medium:[0]
    })

    ngOnInit(): void {

        this.userType = this._aRoute.snapshot.paramMap.get('usertype');

        if (this.userType == 'student') {

        this.classId = this._aRoute.snapshot.paramMap.get('class');
        this.sectionId = this._aRoute.snapshot.paramMap.get('section');

        if (this.userType && this.classId && this.sectionId) {
            this._gService.getDataByID(this.classId, 'class').subscribe(dataRes => {
                console.log(dataRes)
                if (dataRes.status === true) {
                    let result = JSON.parse(dataRes.result)
                    let sectionsArray = JSON.parse(result[0].sections)
                    let sectionNamesArray = result[0].section_names.split(',')
                    this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                        return {'id': section_id, 'name': sectionNamesArray[index]}
                    })
                }
            })
            this.rObj.patchValue({
                Class: this.classId,
                Section: this.sectionId,
                Usertype : this.userType
            })
            this.search()
        }
    } else {

        this.deptId = this._aRoute.snapshot.paramMap.get('dept');
        this.desId = this._aRoute.snapshot.paramMap.get('des');

        console.log(this.deptId, this.userType, this.desId)

        if (this.userType && this.deptId && this.desId) {
            // this._gService.getDataByID(this.classId, 'class').subscribe(dataRes => {
            //     console.log(dataRes)
            //     if (dataRes.status === true) {
            //         let result = JSON.parse(dataRes.result)
            //         let sectionsArray = JSON.parse(result[0].sections)
            //         let sectionNamesArray = result[0].section_names.split(',')
            //         this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
            //             return {'id': section_id, 'name': sectionNamesArray[index]}
            //         })
            //     }
            // })
            this.rObj.patchValue({
                Department: this.deptId,
                Designation: this.desId,
                Usertype : this.userType
            })
            this.search()
        }

    }
        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(cRes => {
            // console.log(cRes)
            if (cRes.status === true) {
                this.mediumData = JSON.parse(cRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'class').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.classData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })


        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'medium').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.mediumData = JSON.parse(cRes.result);
            } else {
                // this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'section').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.sectionData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'department').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.deparmentData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'designation').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.designationData = JSON.parse(cRes.result);
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })

        this._gService.getAllDataByInstID(this.instID, this.activeAcademicID, 'library_members').subscribe(cRes => {
            console.log(cRes)
            if (cRes.status === true) {
                this.memberData = JSON.parse(cRes.result);
                this.studentMembers = this.memberData.filter(function(m) {
                    return m.user_type == 'student'
                }).map(function(m){
                    return m.user_id 
                }) 
                this.staffMembers = this.memberData.filter(function(m) {
                    return m.user_type == 'staff'
                }).map(function(m){
                    return m.user_id
                }) 
                console.log(this.studentMembers)
                console.log(this.staffMembers)
            } else {
                this._gService.triggerSnackbar('No Data Found!', 'error', true);
            }
        })


        this.rObj.controls['Class'].valueChanges.subscribe((value: any) => {
        this._gService.showLoader(true);
        this._gService.getDataByID(value, 'class').subscribe(dataRes => {  
            // console.log(dataRes)
            if(dataRes.status === true) {
                let result = JSON.parse(dataRes.result)
                // console.log(result)
                let sectionsArray = JSON.parse(result[0].sections)
                let sectionNamesArray = result[0].section_names.split(',')
                this.classSectionData = sectionsArray.map((section_id : any, index : any) => {
                    return {'id': section_id, 'name': sectionNamesArray[index]}
                })
                // console.log(this.classSectionData)
            }
        })
        this._gService.showLoader(false);
    })
        
        this.dtOptions = {
            pagingType: 'numbers',
            order: [],
            columnDefs: [{
                targets: 'no-sort',
                orderable: false,
            }],
            dom: 'fBrtip',
            buttons: [
                'copy', 'excel', 'csv', 'pdf', 'print',
            ],
            language: { search: '', searchPlaceholder: "Search..." },
        };


    }

    checkUncheckAll(evt: Event) {
        this.selectedIds.splice(0, this.selectedIds.length);
        this.staffListData.forEach((s) => {
            s.isSelected = (<HTMLInputElement>evt.target).checked
            if((<HTMLInputElement>evt.target).checked) {
                this.selectedIds.push(s.ID);
            } else {
                let index = this.selectedIds.indexOf(s.ID);
                this.selectedIds.splice(index,1);
            }
        })
        console.log(this.selectedIds)
    }

    onChecked(rowId : number, e: Event) {
        if((<HTMLInputElement>e.target).checked) {
            this.selectedIds.push(rowId);
        } else {
            let index = this.selectedIds.indexOf(rowId);
            this.selectedIds.splice(index,1);
        }
        console.log(this.selectedIds)
        if (this.staffListData.length != this.selectedIds.length) {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = false
        } else {
            (<HTMLInputElement>document.querySelector("#select_all")).checked = true
        }
    }

    delMultiData(moduleName: string, moduleTitle: string) {
        this._gService.openDeleteMultiModal(this.selectedIds, this.selectedIds.length, moduleName, moduleTitle)
    }


    getCardNumber(sID : Number) {
       let mData = this.memberData.filter(function(m) {
            return m.user_id == sID
        }).map(function(m){
            return m.card_no
        })
        return mData
    }

    getMemberId(mID : Number) {
        let IData = this.memberData.filter(function(i) {
            return i.user_id == mID
        }).map(function(i){
            return i.ID
        })
        return IData
    }
    

    gotoEditLink(dataID: number){
        this._router.navigate(['/student/edit/'+dataID])
    }

    deleteData(dataID: number, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }
    deletemember(dataID: any, moduleName: string, moduleTitle: string) {
        this._gService.openDeleteModal(dataID, moduleName, moduleTitle)
    }

    addMember(memberID : number){
        this.modalRef = this.modalService.show(AddMemberModalComponent, {
            class: 'modal-dialog-centered addMember-edit-modal-dialog form-modal-dialog',
            backdrop : 'static',
            keyboard : false,
            initialState : {
                userType: this.rObj.value.Usertype,
                memberID: memberID,
            } 
        });
    }

    gotoSearch() {
        let robj = this.rObj.value;
        this._reRoute.shouldReuseRoute = () => false;
        if (robj.Usertype == 'student') {
            this._router.navigate(['/library/member/addStudent/'+robj.Usertype+'/'+robj.Class+'/'+robj.Section]);
        } else {
            this._router.navigate(['/library/member/addStaff/'+robj.Usertype+'/'+robj.Department+'/'+robj.Designation]);
        }
    }

    search() {

        if(this.rObj.valid){
        if (this.userType == 'student') {
            this._aService.studentListData(this.instID, this.activeAcademicID, this.rObj.value.Class, this.rObj.value.Section).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
                    // this._router.navigate(['library/member/add'+robj.Usertype]); 
                    this.studentListData = JSON.parse(sRes.result)
                    console.log(this.studentListData)
                } else {
                    this.studentListData = [];
                    this._gService.triggerSnackbar('No Student Data Found!', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        } else {
            this._sService.staffListData(this.instID, this.activeAcademicID, this.rObj.value.Department, this.rObj.value.Designation).subscribe(sRes => {
                console.log(sRes)
                if (sRes.status === true) {
                    
                    this.staffListData = JSON.parse(sRes.result)
                } else {
                    this.staffListData = [];
                    this._gService.triggerSnackbar('No Staff Data Found!', 'error', true);
                }
            }, err => {
                console.log(err);
                this._gService.triggerSnackbar('Server Error!', 'error', true);
            })
        }

        }
       
    }
}