<div class="modal-header">
    <h5 class="modal-title">Edit Incident</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Title</label>
                <small class="req"> *</small>
                <input type="text" class="form-control" formControlName="Title">
                <div *ngIf="rObj.controls.Title.invalid && (rObj.controls.Title.dirty || rObj.controls.Title.touched)">
                    <p class="input-error-msg">Please enter title</p>
                </div>
            </div>
            <div class="form-group">
                <label>Description</label>
                <small class="req"> *</small>
                <textarea type="text" class="form-control" formControlName="Description"></textarea>
                <div
                    *ngIf="rObj.controls.Description.invalid && (rObj.controls.Description.dirty || rObj.controls.Description.touched)">
                    <p class="input-error-msg">Please enter description</p>
                </div>
            </div>
            <div class="form-group">
                <label for="points">Points</label>
                <small class="req"> *</small>
                <input id="points" class="form-control" type="number" formControlName="Points" min="0" required>
                <div *ngIf="rObj.controls.Points.invalid && (rObj.controls.Points.dirty || rObj.controls.Points.touched)" class="text-danger">
                <p class="input-error-msg">Points are required</p>
                </div>
            </div>
            <div class="form-group">
                <label for="negativeIncident">
                    <input id="negativeIncident" type="checkbox" formControlName="isNegative">
                    Is this a negative incident?
                </label>
            </div> 
            <div class="modal-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid || !rObj.dirty"
                    (click)="updateIncident()">Update</button>
            </div>
        </form>
    </div>
</div>