<div class="modal-header">
    <h5 class="modal-title">Edit Leave</h5>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body modal-branch-container">
    <div class="modal-content-wrapper">
        <form [formGroup]="rObj">
            <div class="form-group">
                <label>Reason</label>
                <small class="req"> *</small>
                <textarea class="form-control" formControlName="Reason" ></textarea>
                <div *ngIf="rObj.controls.Reason.invalid && (rObj.controls.Reason.dirty || rObj.controls.Reason.touched)">
                    <p class="input-error-msg">Please enter reason</p>
                </div>
            </div>
            <div class="form-group">
                <label>Apply Date</label>
                <small class="req"> *</small>
                <input type="date" class="form-control date-input" formControlName="Apply_date" required="" value="">
                <div *ngIf="rObj.controls.Apply_date.invalid && (rObj.controls.Apply_date.dirty || rObj.controls.Apply_date.touched)">
                    <p class="input-error-msg">Please enter date</p>
                </div>
            </div>
            <div class="form-group">
                <label>Leave From Date</label>
                <small class="req"> *</small>
                <input type="date" class="form-control date-input" formControlName="Leave_from_date" required="" value="">
                <div *ngIf="rObj.controls.Leave_from_date.invalid && (rObj.controls.Leave_from_date.dirty || rObj.controls.Leave_from_date.touched)">
                    <p class="input-error-msg">Please enter leave from date</p>
                </div>
            </div>
            <div class="form-group">
                <label>Leave To Date</label>
                <small class="req"> *</small>
                <input type="date" class="form-control date-input" formControlName="Leave_to_date" (change)="onDateChange()" required="" value="">
                <div *ngIf="invalidDate">
                    <p class="input-error-msg">Leave to date must be after leave from date.</p>
                </div>
            </div>
            <div class="form-group">
                <label>Attach Document</label>
                <a href="{{imageSrc}}" target="_blank" style="display: block; margin-bottom: 5px;" *ngIf="imageSrc">View Document</a>
                <!-- <input type="file" class="form-control" formControlName="Leave_document"> -->
                <div class="imgUpload-wrapper">
                    <div class="removeImgOverlay" (click)="removeImg()" *ngIf="imageSrc">Remove Document</div>  
                    <input type="file" formControlName="Leave_document" class="form-control" (change)="onFileChange($event)">
                </div>
                <!-- <div *ngIf="rObj.controls.Leave_document.invalid && (rObj.controls.Leave_document.dirty || rObj.controls.Leave_document.touched)">
                    <p class="input-error-msg">Please enter document</p>
                </div> -->
            </div>
            <div class="form-group">
            <label>Leave Type</label>
            <small class="req"> *</small>
                <select class="form-control" formControlName="Leave_type">
                    <option>Select Leave Type</option>
                    <option *ngFor="let type of leaveData" value="{{type.ID}}">{{type.name}}</option>                     
                 </select>
                 <div *ngIf="rObj.controls.Leave_type.invalid && (rObj.controls.Leave_type.dirty || rObj.controls.Leave_type.touched)">
                    <p class="input-error-msg">Please enter leave type</p>
                </div>  
            </div>
           
           <div class="form-btn-wrapper">
                <button class="btn primary-btn small-btn" [disabled]="!rObj.valid || invalidDate" (click)="updateApplyLeave()">Update</button>
            </div>
        </form> 
    </div>
</div>
