<div class="console-content">
        
    <div class="console-content-header row">
        <h1 id="panel_title" class="title col-md-6">Time Table List</h1>
        <!-- <div class="header-msg col-md-5">
                      </div> -->
        <div class="link-container col-md-6">
            <a href="./academic/createTimeTable" class="content-header-link">Create New Time Table</a>
        </div>
    </div>

     
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="filter-options">
                    <form [formGroup]="rObj">
                        
                        <div class="form-group">
                            <label>Class
                            <small class="req"> *</small></label>
                            <select name="class" formControlName="Class" class="form-select" >
                                <option value="">Please Select</option>
                                <option *ngFor="let class of classData" value="{{class.ID}}">{{class.name}}</option>               
                             </select>
                             <div *ngIf="rObj.controls.Class.invalid && (rObj.controls.Class.dirty || rObj.controls.Class.touched)">
                                <p class="input-error-msg">Please select class</p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Section
                                <small class="req"> *</small></label>
                                <select name="section" formControlName="Section" class="form-select">
                                    <option value="">Please Select</option>
                                    <ng-container *ngFor="let section of classSectionData">
                                        <option [value]="section.id">{{section.name}}</option>
                                    </ng-container>
                                </select>
                                <div *ngIf="rObj.controls.Section.invalid && (rObj.controls.Section.dirty || rObj.controls.Section.touched)">
                                    <p class="input-error-msg">Please select section</p>
                                </div>
                        </div>
                        <div class="form-group btn-container">
                            <button class="btn primary-btn small-btn" [disabled]="!rObj.valid" (click)="gotoSearch()">Search</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    
    <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-body">
                    <div class="timetable-wrapper" *ngIf="classtimetableData.length">
                        <div class="timetable-column" *ngFor="let week of weekData">
                            <h3>{{week.dayName}}</h3>
                            <ng-container *ngFor="let tt of classtimetableData">
                                <div class="timetable-row" *ngIf="week.day == tt.day">
                                    <div class="tt-text"> 
                                        <ng-container *ngFor="let teacher of teacherData">
                                            <ng-container *ngIf="teacher.ID == tt.teacher">
                                                <span>{{teacher.full_name}}</span>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="tt-text"> 
                                        <ng-container *ngFor="let sub of subjectsData">
                                            <ng-container *ngIf="sub.ID == tt.subject">
                                                <span>{{sub.name}}</span>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="tt-text">{{timeFormat(tt.time_from)}} - {{timeFormat(tt.time_to)}}</div>
                                    <div class="tt-text">Room No.<span>{{tt.room_no}}</span></div>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <!-- <table datatable [dtOptions]="dtOptions" id="list" class="list" *ngIf="timeTableData.length">
                        <thead>
                            <tr>
                                <th><input type="checkbox" id="select_all"></th>
		                        <th>Staff Id</th>
			                    <th class="align-left">Staff Name</th>
			                    <th>Mobile</th>
			                    <th>Email </th>
		                        <th>Role</th>
			                    <th>Gender</th>
			                    <th>Qualification</th>
                                <th class="no-sort">Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let t of timeTableData">
                                <td>
                                  <input type="checkbox" class ="list-checkbox" value="ID">
                                </td>
                                <td>{{s.staff_id}}</td>
                                <td class="align-left">
                                    <img [src]="s.photo_path ? imgURL+s.photo_path : '/assets/img/default-user-avatar.png'" style="width: 40px; border: 1px solid #dcdcdc;">
                                    {{s.full_name}}
                                </td>
                                <td>{{s.mobile}}</td>
                                <td>{{s.email}}</td>
                                <td>{{getUserRoleName(s.role)}}</td>
                                <td>{{s.gender}}</td>
                                <td>{{s.qualification}}</td>
                                <td class="action-btn">
                                    <a tooltip="View" class="edit-btn" (click)="gotoViewLink(s.ID)" ><i class="far fa-eye"></i></a>
                                    <a tooltip="Edit" class="edit-btn" (click)="gotoEditLink(s.ID)" ><i class="far fa-edit"></i></a>
                                    <a tooltip="Delete" class="del-btn" (click)="deleteData(s.ID, 'staff', 'delete staff')"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>    -->
                    <div *ngIf="!classtimetableData.length">
                        <div class="noDataWrapper">
                            <img src="/assets/img/No-data-found.png">
                        </div>
                        <p style="text-align: center;">Data Not Found</p>
                    </div>
                </div>            
                            
            </div>
        </div>
    </div>
</div>